import { Button, Flex, Typography } from 'antd';
import { FC } from 'react';

import { OnboardingCardHeader, OnboardingServicesBlock } from '@entities';
import { useOnboarding, useTranslate } from '@hooks';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingPayment: FC = () => {
  const { translate } = useTranslate();

  const { updateScreen } = useOnboarding();

  const handleNext = () => {
    updateScreen('PAYMENT_DETAILS', { active: true });
  };

  return (
    <S.Container>
      <S.Inner>
        <OnboardingCardHeader
          title={translate('onboarding.payment.title')}
          description={
            <>
              <Paragraph>
                {translate('onboarding.payment.description')}
              </Paragraph>

              <Paragraph strong>
                {t('onboarding.payment.descriptionImportant')()}
              </Paragraph>
            </>
          }
        />

        <OnboardingServicesBlock />
      </S.Inner>

      <Flex align="center" justify="flex-end" gap={24}>
        <Paragraph type="secondary">
          {t('onboarding.payment.footerText')()}
        </Paragraph>

        <Button type="primary" onClick={handleNext}>
          {t('onboarding.buttonProceedToPayment')()}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingPayment;
