import { Flex } from 'antd';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconClose } from '@assets';
import { PaymentDocumentUnit } from '@constants';
import { PaymentDocumentCard } from '@entities';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type PaymentDocumentInvoiceProcessingProps = {
  type: PaymentDocumentUnit;
  selectedId?: string;
  file: Schemas.Document;
  onDelete: () => void;
  onSelectedInvoice: (id: string) => void;
};

const PaymentDocumentInvoiceProcessing: FC<
  PaymentDocumentInvoiceProcessingProps
> = ({ type, selectedId, file, onDelete, onSelectedInvoice }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const handleSelectInvoice = (id: string) => {
    navigate(`/${type}/${id}${search}`);
    onSelectedInvoice(id);
  };

  return (
    <PaymentDocumentCard
      isSelected={selectedId === file.id}
      onClick={() => handleSelectInvoice(file.id)}
    >
      <Flex vertical>
        <S.Name data-testid="file-name">{file?.name}</S.Name>

        <S.Processing>
          {type === 'income'
            ? t('income.statuses.processing')()
            : t('expenses.statuses.processing')()}
        </S.Processing>
      </Flex>

      <Button
        type="link"
        variant="link"
        icon={<IconClose />}
        size="middle"
        onClick={onDelete}
        data-testid="button-delete"
      />
    </PaymentDocumentCard>
  );
};

export default PaymentDocumentInvoiceProcessing;
