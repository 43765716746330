import type { ThemeConfig } from 'antd';

import { backgroundColors } from './colors/backgroundColors';
import { baseColors } from './colors/baseColors';
import { colorScheme } from './colors/colorScheme';
import { strokeColors } from './colors/strokeColors';

export const token = {
  fontFamily: 'Inter',
  borderRadius: 8,

  colorPrimary: baseColors.primary,
  colorLink: baseColors.primary,
  colorTextBase: colorScheme.black,
  colorText: colorScheme.black,
  colorError: baseColors.error,
  colorErrorBg: backgroundColors.bgWarning,
  colorSuccess: baseColors.success,
  colorSuccessBg: backgroundColors.bgSuccess,
  colorBgContainer: colorScheme.white,
  colorBgLayout: colorScheme.white,
  colorBorderSecondary: strokeColors.strokeGrey,

  fontSizeHeading1: 36,
} satisfies ThemeConfig['token'];