import { Flex, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconArrowDown, IconAttention, IconCheck, IconInfo } from '@assets';
import { useTransactionsImport, useTranslate } from '@hooks';

import * as S from '../../styled';

type TransactionImportSidebarSecondStep = {
  validatingResult: Schemas.ValidationResult | undefined;
};

const TransactionImportSidebarSecondStep: FC<
  TransactionImportSidebarSecondStep
> = ({ validatingResult }) => {
  const { translate } = useTranslate();
  const { mapping } = useTransactionsImport();

  const [isDone, setIsDone] = useState(false);

  const { debit, credit, debitCredit } = mapping;

  useEffect(
    () => {
      if (
        mapping?.bookingDate?.isSkipped === false &&
        (debitCredit?.isSkipped === false ||
          (debit?.isSkipped === false && credit?.isSkipped === false))
      ) {
        setIsDone(true);
      } else setIsDone(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapping]
  );

  const amountErrorCondition =
    validatingResult?.error?.field === 'debitCredit' ||
    validatingResult?.error?.field === 'debit' ||
    validatingResult?.error?.field === 'credit';

  return (
    <S.StepCard gap={10} vertical>
      <S.StepCardHeader justify="flex-start" gap={8}>
        <S.Count
          isError={validatingResult ? !validatingResult?.success : false}
          isDone={isDone}
          justify="center"
          align="center"
        >
          {validatingResult && !validatingResult?.success ? (
            <IconAttention />
          ) : isDone && !validatingResult?.error?.field ? (
            <IconCheck />
          ) : (
            2
          )}
        </S.Count>
        <S.StepCardHeaderTitle>
          {translate('transactionsImport.sidebar.secondStep.title')}
        </S.StepCardHeaderTitle>
      </S.StepCardHeader>

      <S.StepCardContent gap={5} vertical>
        <S.Text>
          {translate('transactionsImport.sidebar.secondStep.description')}
        </S.Text>
      </S.StepCardContent>

      <S.StepCardFooter gap={8} vertical>
        <Flex align="center" gap={4}>
          <S.Text>
            {translate('transactionsImport.sidebar.secondStep.use')}
          </S.Text>
          <S.Tag gap={4} align="center">
            {translate('transactionsImport.sidebar.secondStep.columnType')}
            <S.IconArrowWrapper>
              <IconArrowDown />
            </S.IconArrowWrapper>
          </S.Tag>
          <S.Text>
            {translate('transactionsImport.sidebar.secondStep.inner')}
          </S.Text>
        </Flex>

        <S.ColumnsWrapper gap={8} vertical>
          <S.Columns gap={8} vertical>
            <S.ColumnText>
              <S.Bold>
                {translate(
                  'transactionsImport.sidebar.secondStep.requireColumns'
                )}
              </S.Bold>
            </S.ColumnText>

            <S.ColumnInput align="center" gap={8}>
              <S.Circle
                isDone={mapping?.bookingDate?.isSkipped === false}
                isError={
                  validatingResult &&
                  validatingResult?.error?.field === 'bookingDate'
                }
              />
              <S.ColumnText>
                <S.Bold>
                  {translate('transactionsImport.tableResult.columns.date')}
                </S.Bold>
                {validatingResult &&
                validatingResult?.error?.field === 'bookingDate' ? (
                  <S.ErrorText>
                    <li>
                      {translate(
                        'transactionsImport.tableResult.columns.wrong'
                      )}
                    </li>
                  </S.ErrorText>
                ) : null}
              </S.ColumnText>
              <S.InfoIconWrapper>
                <Tooltip
                  title={translate(
                    'transactionsImport.tableResult.tooltips.date'
                  )}
                >
                  <IconInfo />
                </Tooltip>
              </S.InfoIconWrapper>
            </S.ColumnInput>

            <S.ColumnInput align="center" gap={8}>
              <S.Circle
                isDone={
                  debitCredit?.isSkipped === false ||
                  (debit?.isSkipped === false && credit?.isSkipped === false)
                }
                isError={validatingResult && amountErrorCondition}
              />
              <S.ColumnText>
                <S.Bold>
                  {translate('transactionsImport.tableResult.columns.amount')}
                </S.Bold>
                {validatingResult && amountErrorCondition ? (
                  <S.ErrorText>
                    <li>
                      {translate(
                        'transactionsImport.tableResult.columns.wrong'
                      )}
                    </li>
                  </S.ErrorText>
                ) : null}
              </S.ColumnText>
              <S.InfoIconWrapper>
                <Tooltip
                  title={translate(
                    'transactionsImport.tableResult.tooltips.amount'
                  )}
                >
                  <IconInfo />
                </Tooltip>
              </S.InfoIconWrapper>
            </S.ColumnInput>
          </S.Columns>

          <S.Columns gap={8} vertical>
            <S.ColumnText>
              <S.Bold>
                {translate(
                  'transactionsImport.sidebar.secondStep.optionalColumns'
                )}
              </S.Bold>
            </S.ColumnText>

            <S.ColumnInput align="center" gap={8}>
              <S.Circle
                isDone={mapping?.currencyCode?.isSkipped === false}
                isError={
                  validatingResult &&
                  validatingResult?.error?.field === 'currencyCode'
                }
              />
              <S.ColumnText>
                <S.Bold>
                  {translate(
                    'transactionsImport.tableResult.columns.currencyCode'
                  )}
                  {validatingResult &&
                  validatingResult?.error?.field === 'currencyCode' ? (
                    <S.ErrorText>
                      <li>
                        {translate(
                          'transactionsImport.tableResult.columns.wrong'
                        )}
                      </li>
                    </S.ErrorText>
                  ) : null}
                </S.Bold>
              </S.ColumnText>
              <S.InfoIconWrapper>
                <Tooltip
                  title={translate(
                    'transactionsImport.tableResult.tooltips.currencyCode'
                  )}
                >
                  <IconInfo />
                </Tooltip>
              </S.InfoIconWrapper>
            </S.ColumnInput>

            <S.ColumnInput align="center" gap={8}>
              <S.Circle isDone={mapping?.details?.isSkipped === false} />
              <S.ColumnText>
                <S.Bold>
                  {translate('transactionsImport.tableResult.columns.details')}
                </S.Bold>
              </S.ColumnText>
              <S.InfoIconWrapper>
                <Tooltip
                  title={translate(
                    'transactionsImport.tableResult.tooltips.details'
                  )}
                >
                  <IconInfo />
                </Tooltip>
              </S.InfoIconWrapper>
            </S.ColumnInput>

            <S.ColumnInput align="center" gap={8}>
              <S.Circle isDone={mapping?.contactName?.isSkipped === false} />
              <S.ColumnText>
                <S.Bold>
                  {translate(
                    'transactionsImport.tableResult.columns.contactName'
                  )}
                </S.Bold>
              </S.ColumnText>
              <S.InfoIconWrapper>
                <Tooltip
                  title={translate(
                    'transactionsImport.tableResult.tooltips.contactName'
                  )}
                >
                  <IconInfo />
                </Tooltip>
              </S.InfoIconWrapper>
            </S.ColumnInput>
          </S.Columns>
        </S.ColumnsWrapper>
      </S.StepCardFooter>
    </S.StepCard>
  );
};

export default TransactionImportSidebarSecondStep;
