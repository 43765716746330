import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Root = styled(Flex)`
  text-align: center;
  align-items: center;
`;

export const Content = styled(Flex)`
  width: 567px;
`;

export const Delimiter = styled.hr`
  height: 1px;
  width: 457px;
  background-color: ${p => p.theme.colors.bgDarkGrey};
  border: none;
`;

