import { Select, type SelectProps } from '@components';
import { useTypesList } from '@hooks';

const FilterTypes = ({ visualType, ...rest }: SelectProps) => {
  const types = useTypesList();

  return (
    <Select
      options={types.map((type) => ({
        label: type.label,
        value: type.value,
      }))}
      size="large"
      visualType={visualType || 'default'}
      {...rest}
    />
  );
};

export default FilterTypes;
