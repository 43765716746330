import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconUpload } from '@assets';
import { PaymentDocumentUnit } from '@constants';
import { PaymentDocumentImportInvoice } from '@entities';
import { colors } from '@theme';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type PaymentDocumentDropFile = {
  type: PaymentDocumentUnit;
  onUpdate: (document: Schemas.Document) => void;
};

const getTranslatesByType = (type: PaymentDocumentUnit) =>
  type === 'income'
    ? {
        title: t('income.dropFile.title')(),
        description: t('income.dropFile.description')(),
        buttonSelect: t('income.dropFile.buttonSelect')(),
      }
    : {
        title: t('expenses.dropFile.title')(),
        description: t('expenses.dropFile.description')(),
        buttonSelect: t('expenses.dropFile.buttonSelect')(),
      };

const PaymentDocumentDropFile: FC<PaymentDocumentDropFile> = ({
  type,
  onUpdate,
}) => {
  const translates = getTranslatesByType(type);

  return (
    <PaymentDocumentImportInvoice documentType={type} onUpdate={onUpdate}>
      <S.Container>
        <IconUpload color={colors.textBlue400} />

        <S.Title>{translates.title}</S.Title>
        <S.Description>{translates.description}</S.Description>

        <Button size="small">{translates.buttonSelect}</Button>
      </S.Container>
    </PaymentDocumentImportInvoice>
  );
};

export default PaymentDocumentDropFile;
