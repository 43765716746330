import { Typography } from 'antd';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaWidth } from '@ui-kit/theme/mediaWidth';

type ContainerProps = {
  shorted?: boolean;
};

export const Container = styled.div<ContainerProps>`
  border-bottom: ${({ shorted }) => (shorted ? 0 : '1px')} solid
    ${({ theme }) => theme.colors.strokeGrey};
  margin-bottom: 28px;
  padding-bottom: ${({ shorted }) => (shorted ? 0 : '20px')};
`;

export const Title = styled(Typography.Title)`
  &.ui-typography {
    font-size: 28px;
    margin-bottom: 8px;

    ${mediaWidth.tablet(css`
      font-size: 24px;
      margin-bottom: 6px;
    `)};
  }
`;

export const Description = styled.div`
  .ui-typography {
    margin-bottom: 8px;
  }
`;
