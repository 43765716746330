import { FC, type PropsWithChildren, createContext, useState } from 'react';

type AppStateContextProps = {
  isVisibleContentAfterImport: boolean;
  updateVisibilityContentAfterImport: (status: boolean) => void;
};

export const AppStateContext = createContext<AppStateContextProps>(
  {} as AppStateContextProps
);

export const AppStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isVisibleContentAfterImport, setIsVisibleContentAfterImport] =
    useState(false);

  const updateVisibilityContentAfterImport = (status: boolean) =>
    setIsVisibleContentAfterImport(status);

  return (
    <AppStateContext.Provider
      value={{
        isVisibleContentAfterImport,
        updateVisibilityContentAfterImport,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
