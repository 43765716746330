import { Button, Typography } from 'antd';

import styled from '@emotion/styled';

const { Text } = Typography;

export const DownloadButton = styled(Button)`
  && {
    width: 48px;
    height: 48px;
    padding: 2px;
  }
`;

export const Title = styled(Text)`
  font-size: 16px;
`;

export const Subtitle = styled(Text)`
  font-size: 12px;
`;

export const Icon = styled.span`
  display: inline-flex;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.white};
  background-color: ${p => p.theme.colors.grey400};
`;
