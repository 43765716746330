import { Table } from 'antd';
import { FC, Ref } from 'react';

import { Schemas } from '@api-client/generated/types';

import { columnsConfig } from './columnsConfig';
import * as S from './styled';

type MatchingTransactionsModalProps = {
  paginationConfig: {
    data: Schemas.Transaction[];
    hasNextPage: boolean;
    isLoading: boolean;
    infiniteScrollRef: Ref<HTMLDivElement>;
  };
};

const MatchingTransactionsModal: FC<MatchingTransactionsModalProps> = ({
  paginationConfig,
}) => (
  <S.Wrapper gap={24} align="flex-end" vertical>
    <Table
      rowKey={({ id }) => id}
      dataSource={paginationConfig.data}
      columns={columnsConfig}
      loading={paginationConfig.isLoading}
      pagination={false}
      scroll={{ y: 406, scrollToFirstRowOnChange: false }}
      summary={() => (
        <>
          {paginationConfig.hasNextPage && (
            <div ref={paginationConfig.infiniteScrollRef} />
          )}
        </>
      )}
    />
  </S.Wrapper>
);

export default MatchingTransactionsModal;
