import { Typography } from 'antd';
import { TextProps } from 'antd/lib/typography/Text';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { Language } from '@constants';
import { useAccount } from '@hooks';

const { Text } = Typography;

type DateFormat = 'time' | 'date' | 'dateTime';

type CommonDateTextProps = {
  date: Date | dayjs.Dayjs | string;
  className?: string;
};

type FormatDateTextProps = {
  format: DateFormat | string;
  relative?: false;
} & CommonDateTextProps;

type RelativeDateTextProps = {
  relative: true;
  format?: never;
} & CommonDateTextProps;

const formats: Record<DateFormat, string> = {
  time: 'HH:mm',
  date: 'DD.MM.YYYY',
  dateTime: 'DD.MM.YYYY HH:mm',
};

const DateText = ({
  date,
  relative,
  format = 'date',
  className,
  ...rest
}: (FormatDateTextProps | RelativeDateTextProps) & TextProps) => {
  const { account } = useAccount();

  const formattedDate = useMemo(() => {
    if (relative) {
      return dayjs(date)
        .locale(account?.locale ?? Language.En)
        .fromNow();
    }

    switch (format) {
      case 'date':
      case 'time':
      case 'dateTime':
        return dayjs(date).format(formats[format]);
      default:
        return dayjs(date).format(format);
    }
  }, [date, format, relative, account?.locale]);

  return (
    <Text className={className} {...rest}>
      {formattedDate}
    </Text>
  );
};

export default DateText;
