import { Button, Typography } from 'antd';

import styled from '@emotion/styled';

const { Text } = Typography;

export const Email = styled(Text)`
  font-size: 16px;
`;

export const EditButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    padding: 4px;
    box-sizing: border-box;
  }
`;

export const Title = styled(Text)`
  font-size: 16px;
  color: ${p => p.theme.colors.text500};
`;

export const Description = styled(Text)`
  font-size: 14px;
  color: ${p => p.theme.colors.textBlue400};
  font-weight: 400;
`
