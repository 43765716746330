import { Currency, Language } from '@constants';

export const formatNumber = (
  value: number,
  options: Intl.NumberFormatOptions = {},
  lang: string = Language.En
) => {
  const formatter = new Intl.NumberFormat(lang, options);

  return formatter.format(value);
};

export const formatAmount = (
  value: number,
  lng?: Language,
  currency?: Currency
) => {
  switch (lng) {
    default: {
      const formatter = new Intl.NumberFormat(Language.En, {
        style: currency ? 'currency' : 'decimal',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
      });

      const [first, second] = formatter.format(value).split('.');

      if (Math.abs(value) < 10000) {
        return `${first},${second}`.replace('US', '').trim();
      }

      return `${first.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')},${second}`
        .replace('US', '')
        .trim();
    }
  }
};

export const getCurrencySymbol = (
  currency: string,
  locale: string = Language.En
) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .replace('US', '')
    .trim();

export const formatFileSize = (size: number): string => {
  const units = ['b', 'kb', 'mb', 'gb', 'tb'];

  if (size === 0) {
    return '0 b';
  }

  const unitIndex = Math.floor(Math.log(size) / Math.log(1024));
  const adjustedSize = size / Math.pow(1024, unitIndex);

  return `${adjustedSize.toFixed(2)} ${units[unitIndex]}`;
};
