import { useContext } from 'react';

import { AccountContext } from '@context';

const useAccount = () => {
  const account = useContext(AccountContext);

  if (!account) {
    throw new Error('useAccount must be used within an AccountProvider');
  }

  return account;
};

export default useAccount;
