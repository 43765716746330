import styled from '@emotion/styled';

export const Logo = styled.img`
  width: 40px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.textDisabled};
  border-radius: 50%;
`;

export const Name = styled.div`
  font-weight: 600;
`;

export const Details = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
`;

export const Amount = styled.div`
  font-weight: 600;
  font-size: 16px;
`;
