import { Flex, Table } from 'antd';
import { FC } from 'react';

import { IconExclamation } from '@assets';
import { Scrollbar } from '@components';
import { useTranslate } from '@hooks';

import * as config from './config';
import * as S from './styled';

type TransactionSplitProps = {
  result: config.ValidationResult | undefined;
};

const TransactionsImportValidatedTable: FC<TransactionSplitProps> = ({
  result,
}) => {
  const { translate } = useTranslate();

  const error = result?.error;
  const rows = result?.rows;

  return (
    <S.Container>
      <Scrollbar>
        <Table
          dataSource={rows}
          columns={config.createColumns(rows, translate).map((column) => ({
            ...column,
            className: error?.field === column.key ? 'cell-error' : '',
            title:
              error?.field === column.key ? (
                <Flex gap={12}>
                  <S.ErrorIcon>
                    <IconExclamation />
                  </S.ErrorIcon>
                  {column.title}
                </Flex>
              ) : (
                column.title
              ),
          }))}
          loading={false}
          pagination={false}
        />
      </Scrollbar>
    </S.Container>
  );
};

export default TransactionsImportValidatedTable;
