import { Typography } from 'antd';

import countriesData from '@data/countries.json';

import { Image, Option } from './CountryOption';

export const countries = countriesData.map((country) => ({
  key: country.code,
  value: country.code,
  name: country.name,
  label: (
    <Option>
      <Image src={country.image} alt={country.name} />
      <Typography>{country.name}</Typography>
    </Option>
  ),
  image: country.image,
}));
