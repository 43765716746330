import { Button, Flex, Form, Input, Typography } from 'antd';
import { FC, useEffect } from 'react';
import { Trans } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';
import { Schemas } from '@api-client/generated/types';
import { IconTrash } from '@assets';
import { OnboardingCardHeader } from '@entities';
import { useFormValidateFields, useOnboarding } from '@hooks';

import * as S from './styled';

type OnboardingCompanyNameProps = {
  onSubmit?: (values: Partial<Schemas.Incorporation>) => void;
};

const { Paragraph } = Typography;

const OnboardingCompanyName: FC<OnboardingCompanyNameProps> = ({
  onSubmit,
}) => {
  const { form, disabledForm } = useFormValidateFields();

  const {
    incorporationDetails,
    updateScreen,
    updateIncorporation,
    isIncorporation,
  } = useOnboarding();

  useEffect(() => {
    const companyNames = incorporationDetails?.companyNames;

    if (companyNames) {
      form.setFieldValue('companyNames', companyNames);
    }
  }, [form, incorporationDetails?.companyNames]);

  const handleSubmit = (values: Schemas.Incorporation) => {
    onSubmit?.(values);

    updateIncorporation(values);
    updateScreen('DESCRIBE', { active: true });
  };

  return (
    <S.Container>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
        initialValues={{
          companyNames: [''],
        }}
      >
        <S.Inner>
          <OnboardingCardHeader
            title={translateUntyped(
              `onboarding.companySelect.title_${incorporationDetails?.incorporationType}`
            )()}
            description={
              <Paragraph>
                <Trans
                  i18nKey={translateUntyped(
                    `onboarding.companySelect.description_${incorporationDetails?.incorporationType}`
                  )()}
                  components={[<br />, <strong />]}
                />
              </Paragraph>
            }
          />

          <Form.List name="companyNames">
            {(fields, { add, remove }) => (
              <>
                <S.FormInner>
                  <S.FormLabel>
                    {t('onboarding.companySelect.form.label')()}
                  </S.FormLabel>

                  {fields.map((field) => (
                    <S.FormItem key={field.key}>
                      <Form.Item required={false} noStyle>
                        <Flex align="center" gap={16}>
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[{ required: true }]}
                            noStyle
                          >
                            <Input
                              data-testid="company-name-input"
                              size="large"
                            />
                          </Form.Item>

                          {fields.length > 1 ? (
                            <S.ButtonRemove onClick={() => remove(field.name)}>
                              <IconTrash />
                            </S.ButtonRemove>
                          ) : null}
                        </Flex>
                      </Form.Item>
                    </S.FormItem>
                  ))}
                </S.FormInner>

                {isIncorporation && (
                  <Flex align="center" gap={28}>
                    <S.ButtonAdd onClick={() => add()}>
                      <PlusOutlined />
                      {t('onboarding.companySelect.form.buttonAdd')()}
                    </S.ButtonAdd>

                    <S.Total>
                      <Trans
                        i18nKey={t('onboarding.companySelect.form.total')()}
                        components={[<span>{fields.length}</span>]}
                      />
                    </S.Total>
                  </Flex>
                )}
              </>
            )}
          </Form.List>
        </S.Inner>

        <Flex justify="flex-end">
          <Button type="primary" htmlType="submit" disabled={disabledForm}>
            {t('onboarding.buttonNext')()}
          </Button>
        </Flex>
      </Form>
    </S.Container>
  );
};

export default OnboardingCompanyName;
