import dayjs from 'dayjs';

import styled from '@emotion/styled';
import { useTranslate } from '@hooks';
import { Button } from '@ui-kit/Button/Button';

const Wrapper = styled.div`
  min-width: 267px;
`;

const Title = styled.h5`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text500};
  margin-bottom: 5px;
`;

const List = styled.ul`
  margin-bottom: 5px;
`;

const Row = styled.li`
  display: flex;
  justify-content: space-between;
  gap: 6px;
`;

const Label = styled.p`
  font-size: 16px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text400};
  white-space: nowrap;
`;

const Value = styled.p`
  font-size: 16px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text400};
`;

export const RuleMetadata = ({
  createdBy,
  createdAt,
  archivedAt,
  archivedBy,
  onArchive,
  onRestore,
  id,
  entityType,
  affectedTransactionsCount,
  affectedDocumentsCount,
  lastAppliedAt,
}: {
  createdBy: string;
  createdAt: string;
  archivedAt: string | null;
  archivedBy?: string;
  onArchive: (id: string) => void;
  onRestore: (id: string) => void;
  id: string;
  entityType: 'transaction' | 'file';
  affectedDocumentsCount: number;
  affectedTransactionsCount: number;
  lastAppliedAt: string | null;
}) => {
  const { translate } = useTranslate();
  const details = [
    [translate('rules.metadata.createdBy'), createdBy],
    ...(archivedAt
      ? [
          [translate('rules.metadata.archivedBy'), archivedBy],
          [
            translate('rules.metadata.archivedOn'),
            dayjs(archivedAt).format('DD.MM.YYYY'),
          ],
        ]
      : [
          [
            translate('rules.metadata.addedOn'),
            dayjs(createdAt).format('DD.MM.YYYY'),
          ],
          [
            translate('rules.metadata.lastAppliedAt'),
            lastAppliedAt ? dayjs(lastAppliedAt).format('DD.MM.YYYY') : '-',
          ],
          entityType === 'transaction'
            ? [
                translate('rules.metadata.transactionsAffected'),
                affectedTransactionsCount,
              ]
            : [
                translate('rules.metadata.documentsAffected'),
                affectedDocumentsCount,
              ],
        ]),
  ];

  return (
    <Wrapper>
      <Title>
        {translate(
          archivedAt
            ? 'rules.metadata.archivedRule'
            : 'rules.metadata.activeRule'
        )}
      </Title>
      <List>
        {details.map(([label, value], index) => (
          <Row key={index}>
            <Label>{label}</Label>
            <Value>{value}</Value>
          </Row>
        ))}
      </List>
      {archivedAt ? (
        <Button type="link" onClick={() => onRestore(id)}>
          {translate('rules.metadata.restoreRule')}
        </Button>
      ) : (
        <Button type="link" danger onClick={() => onArchive(id)}>
          {translate('rules.metadata.archiveRule')}
        </Button>
      )}
    </Wrapper>
  );
};
