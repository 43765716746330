//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$IncorporationController_getInvoice,
  Response$IncorporationController_getInvoice$Status$200,
  ResponseContentType$IncorporationController_getInvoice,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useIncorporationController_getInvoice = (options: {
  params: Params$IncorporationController_getInvoice['parameter'];
  config?: QueryOptions<
    Response$IncorporationController_getInvoice$Status$200[ResponseContentType$IncorporationController_getInvoice]
  >;
}) =>
  useApiClient<
    Params$IncorporationController_getInvoice,
    Response$IncorporationController_getInvoice$Status$200[ResponseContentType$IncorporationController_getInvoice]
  >({
    method: 'IncorporationController_getInvoice',
    params: { parameter: options.params },
    config: options.config,
  });
