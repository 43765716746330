import { FC, type PropsWithChildren, createContext, useState } from 'react';

import { Schemas } from '@api-client/generated/types';

type Account = Schemas.Account;

type AccountsContextProps = {
  accounts: Account[];
  initialAccounts: (accounts: Account[]) => void;
  createAccount: (newAccount: Account) => void;
  updateAccount: (updatedAccount: Account) => void;
  deleteAccount: (id: string) => void;
};

export const AccountsContext = createContext<AccountsContextProps>(
  {} as AccountsContextProps
);

export const AccountsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [accountsList, setAccountsList] = useState<Account[]>([]);

  const createAccount = (newAccount: Account) =>
    setAccountsList((prevState) => [...prevState, newAccount]);

  const updateAccount = (updatedAccount: Account) =>
    setAccountsList((prevState) =>
      prevState.map((account) => {
        if (account.id === updatedAccount.id) {
          return {
            ...account,
            ...updatedAccount,
          };
        }

        return account;
      })
    );

  const deleteAccount = (id: string) => {
    const filteredAccounts = accountsList.filter(
      (account) => account.id !== id
    );

    setAccountsList(filteredAccounts);
  };

  return (
    <AccountsContext.Provider
      value={{
        accounts: accountsList,
        initialAccounts: setAccountsList,
        createAccount,
        updateAccount,
        deleteAccount,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
};
