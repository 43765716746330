import styled from '@emotion/styled';
import { Typography } from 'antd';

export const SmallText = styled(Typography.Text)`
  font-size: 12px;
`;

export const ErrorText = styled(SmallText)`
  color: ${p => p.theme.colors.error};
`;

export const WarningText = styled(SmallText)`
  color: ${p => p.theme.colors.iconLineYellow};
`;
