//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AccountsController_deleteAccount,
  Response$AccountsController_deleteAccount$Status$200,
  ResponseContentType$AccountsController_deleteAccount,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAccountsController_deleteAccount = () =>
  useApiClientMutation<
    Params$AccountsController_deleteAccount,
    Response$AccountsController_deleteAccount$Status$200[ResponseContentType$AccountsController_deleteAccount]
  >({ method: 'AccountsController_deleteAccount' });
