import { Button, Flex } from 'antd';

import styled from '@emotion/styled';

export const Aside = styled.div`
  width: 364px;
  height: 100%;
  padding: 24px;
  border-left: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
`;

export const FlexWrapper = styled(Flex)`
  height: 100%;
`;

export const InstructionSteps = styled(Flex)``;

export const StepTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 6px;
`;

export const StepCard = styled(Flex)`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
`;

export const StepCardHeader = styled(Flex)``;

export const Count = styled(Flex)<{ isError?: boolean; isDone: boolean }>`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  width: 20px;
  height: 20px;
  background-color: ${({ theme, isDone, isError }) =>
    isError
      ? theme.colors.error
      : isDone
        ? theme.colors.primary
        : theme.colors.textBlue400};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;

  svg {
    width: ${({ isDone }) => (isDone ? '12px' : '16px')};
    height: ${({ isDone }) => (isDone ? '12px' : '16px')};
    padding-bottom: ${({ isDone }) => (isDone ? 0 : '3px')};
  }
`;

export const StepCardHeaderTitle = styled.span`
  font-weight: 600;
  line-height: 20px;
`;

export const StepCardContent = styled(Flex)``;

export const List = styled.ul`
  color: ${({ theme }) => theme.colors.text400};
  padding-inline-start: 15px;
  margin-bottom: 0;
`;

export const Li = styled.li`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text400};
`;

export const Text = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text400};
`;

export const Bold = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`;

export const Tag = styled(Flex)`
  padding: 4px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bgLightYellow};
  color: ${({ theme }) => theme.colors.text400};

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

export const ColumnText = styled(Flex)`
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text400};
`;

export const ErrorText = styled.ul`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  line-height: 20px;
  padding-inline-start: 15px;
  margin-inline-start: 5px;
  margin-bottom: 0;
`;

export const StepCardFooter = styled(Flex)``;

export const Icon = styled(Flex)`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 8px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const ColumnsWrapper = styled(Flex)`
  margin-top: 8px;
`;

export const Columns = styled(Flex)`
  border-radius: 12px;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
`;

export const ColumnInput = styled(Flex)`
  color: ${({ theme }) => theme.colors.text400};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
`;

export const Circle = styled.div<{ isDone: boolean; isError?: boolean }>`
  width: 12px;
  height: 12px;
  border: 1px solid
    ${({ theme, isDone, isError }) =>
      isError
        ? theme.colors.error
        : isDone
          ? theme.colors.iconLineSuccess
          : theme.colors.textBlue400};
  background-color: ${({ theme, isDone, isError }) =>
    isError
      ? theme.colors.error
      : isDone
        ? theme.colors.iconLineSuccess
        : theme.colors.white};
  border-radius: 50%;
`;

export const InfoIconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.text200};
  width: 16px;
  height: 16px;
`;

export const IconArrowWrapper = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const PreviewButton = styled(Button)`
  width: 150px;
`;
