import { Flex, Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import { Schemas } from '@api-client/generated/types';
import { Scrollbar } from '@components';
import { PaymentDocumentUnit } from '@constants';
import {
  PaymentDocumentInvoice,
  PaymentDocumentInvoiceError,
  PaymentDocumentInvoiceProcessing,
} from '@entities';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';

type PaymentDocumentInvoicesProps = {
  type: PaymentDocumentUnit;
  isLoading: boolean;
  selectedId?: string;
  files: Schemas.Document[];
  hasNextPage: boolean;
  onLoadMore: () => void;
  onSelectedInvoice: (id: string) => void;
  onDelete: (id: string) => void;
};

const getTranslatesByType = (type: PaymentDocumentUnit) =>
  type === 'income'
    ? {
        possibleDuplicate: t('income.possibleDuplicate')(),
        corruptedFile: t('income.corruptedFile')(),
      }
    : {
        possibleDuplicate: t('expenses.possibleDuplicate')(),
        corruptedFile: t('expenses.corruptedFile')(),
      };

const PaymentDocumentInvoices = ({
  type,
  hasNextPage,
  isLoading,
  selectedId,
  files,
  onLoadMore,
  onSelectedInvoice,
  onDelete,
}: PaymentDocumentInvoicesProps) => {
  const translates = getTranslatesByType(type);

  const { sentryRef, rootRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore,
  });

  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined spin />}>
      <Scrollbar maxHeight={600} ref={rootRef}>
        <Flex gap={8} vertical>
          {files.map((file) =>
            file.hasError || file.potentialDuplicate ? (
              <PaymentDocumentInvoiceError
                key={file.id}
                type={type}
                selectedId={selectedId}
                file={file}
                details={
                  file.potentialDuplicate
                    ? translates.possibleDuplicate
                    : translates.corruptedFile
                }
                onSelectedInvoice={onSelectedInvoice}
                onDelete={() => onDelete(file.id)}
              />
            ) : !file.isRecognitionCompleted ? (
              <PaymentDocumentInvoiceProcessing
                key={file.id}
                type={type}
                file={file}
                selectedId={selectedId}
                onSelectedInvoice={onSelectedInvoice}
                onDelete={() => onDelete(file.id)}
              />
            ) : (
              <PaymentDocumentInvoice
                key={file.id}
                type={type}
                selectedId={selectedId}
                file={file}
                onSelectedInvoice={onSelectedInvoice}
              />
            )
          )}

          {hasNextPage && <div ref={sentryRef} />}
        </Flex>
      </Scrollbar>
    </Spin>
  );
};

export default PaymentDocumentInvoices;
