import { Button, Flex } from 'antd';

import styled from '@emotion/styled';

export const BlockTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const AttachButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  height: 20px;
  gap: 4px;
  align-items: center;
  padding: 0;

  && {
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const PaymentDocuments = styled(Flex)`
  padding: 20px 20px 32px 20px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.bgDarkGrey};
  background: ${({ theme }) => theme.colors.badgeGrey};
`;

export const Actions = styled.div`
  width: 233px;
`;

export const AttachNew = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text400};
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue};
  }
`;

export const UploadWrapper = styled.div`
  && .ui-upload {
    width: 100%;
  }
`;

export const UploadExisting = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text400};
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue};
  }
`;
