import { Col, Flex, Row } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { CopyButton, Loader } from '@components';
import { monthsList } from '@constants';
import {
  DetailsHeader,
  PageMeta,
  TaxPreview,
  TaxProvide,
  TaxReview,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useGetTaxById } from '@hooks-api';

import * as S from './styled';

const TaxesDetails: FC = () => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();
  const { id: taxId } = useParams();

  const { data: tax, isPending: loading } = useGetTaxById({
    params: {
      id: taxId!,
      companyId: companyId!,
    },
  });

  const getTaxPeriod = () => {
    const currentYear = dayjs().format('YYYY');

    if (tax) {
      if (tax.periodType === 'quarter') {
        return tax.period;
      }

      if (tax.periodType === 'month') {
        const quarterByMonth = dayjs().month(monthsList[tax.period]).quarter();

        return `Q${quarterByMonth} ${currentYear}`;
      }

      if (tax.periodType === 'year') {
        return `Q4 ${tax.period}`;
      }
    } else {
      return '';
    }
  };

  if (!tax || loading) {
    return <Loader />;
  }

  const period = getTaxPeriod();

  return (
    <Flex gap={32} vertical>
      <PageMeta title={translate('taxes.title')} />

      <DetailsHeader>
        <S.HeaderText>
          {translate('taxes.incomePayment', { date: period })}
        </S.HeaderText>

        <CopyButton
          tooltipPlacement="bottom"
          icon="chain"
          value={location.href}
          type="link"
        />
      </DetailsHeader>

      <Row gutter={[30, 0]}>
        <Col span={12}>
          <Flex gap={20} vertical>
            <TaxReview tax={tax} period={period} />
            <TaxProvide tax={tax} />
          </Flex>
        </Col>

        <Col span={12}>
          <TaxPreview file={tax.files && tax.files[0]} />
        </Col>
      </Row>
    </Flex>
  );
};

export default TaxesDetails;
