import { Flex, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useAccountsController_getAccounts } from '@api-client/generated/AccountsController/getAccounts';
import { useFileMappingController_findOneById } from '@api-client/generated/FileMappingController/findOneById';
import { useFileMappingController_import } from '@api-client/generated/FileMappingController/import';
import { Schemas } from '@api-client/generated/types';
import { Loader } from '@components';
import {
  AccountAddModal,
  GoBack,
  PageMeta,
  TransactionsImportSidebar,
  TransactionsImportTable,
  TransactionsImportValidatedTable,
} from '@entities';
import { useAccount, useAppState, useTransactionsImport } from '@hooks';
import {
  useUpdateFileMappingsById,
  useValidateFileMappingsById,
} from '@hooks-api';
import { setSearchParams } from '@utils';

import * as S from './styled';

const TransactionsImport: FC = () => {
  const navigate = useNavigate();
  const { id: accountId } = useParams();
  const { companyId } = useAccount();
  const { id } = useParams();

  const {
    isPreviewVisible,
    preview,
    reviewMapping,
    setMapping,
    setPreview,
    setPreviewVisible,
    setFileUploaded,
  } = useTransactionsImport();
  const [isAccountAddOpen, setIsAccountAddOpen] = useState(false);

  const { mutate: updateFileMappings, isPending: loadingUpdate } =
    useUpdateFileMappingsById();
  const {
    mutate: validateFileMappings,
    isPending: loadingValidate,
    data: validatedData,
  } = useValidateFileMappingsById();
  const { mutate: importFileMappings, isPending: loadingImport } =
    useFileMappingController_import();

  const { updateVisibilityContentAfterImport } = useAppState();

  const { refetch: refetchAccounts } = useAccountsController_getAccounts({
    params: {
      companyId: companyId!,
    },
  });

  const { data, isPending: getFileLoading } =
    useFileMappingController_findOneById({
      params: {
        companyId: companyId!,
        id: id!,
      },
      config: {
        refetchOnWindowFocus: false,
      },
    });

  useEffect(() => {
    if (data) {
      setFileUploaded(true);

      const normalizeDataTypes = (mapping: Schemas.CSVMapping) =>
        Object.fromEntries(
          Object.entries(mapping).map(([key, field]) => {
            if (field.isSkipped) {
              field.isSkipped = String(field.isSkipped) === 'true';
            }

            if (field.columnNumber) {
              field.columnNumber = +field.columnNumber;
            }

            return [key, field];
          })
        );

      setMapping(normalizeDataTypes(data.mapping));
      setPreview(data.preview);
    }
  }, [data, setFileUploaded, setMapping, setPreview]);

  const handleReview = () => {
    updateFileMappings(
      {
        parameter: {
          id: accountId!,
          companyId: companyId!,
        },
        requestBody: {
          accountId: accountId!,
          mapping: reviewMapping,
        },
      },
      {
        onSuccess: (response) => {
          validateFileMappings(
            {
              parameter: {
                id: response.id!,
                companyId: companyId!,
              },
              requestBody: {},
            },
            {
              onSuccess: () => {
                setPreviewVisible(true);
              },
            }
          );
        },
      }
    );
  };

  const handleImport = () =>
    importFileMappings(
      {
        parameter: {
          id: accountId!,
          companyId: companyId!,
        },
      },
      {
        onSuccess: (response) => {
          if (response.transactionsAmount === 0) {
            message.warning({
              type: 'success',
              content: (
                <Trans
                  i18nKey={t(
                    'transactionsImport.tableResult.successImportedButDublicate'
                  )()}
                />
              ),
            });
          } else {
            message.open({
              type: 'success',
              content: (
                <Trans
                  i18nKey={t(
                    'transactionsImport.tableResult.successImported'
                  )()}
                  components={[<strong>{response.transactionsAmount}</strong>]}
                />
              ),
            });
          }

          updateVisibilityContentAfterImport(true);

          navigate(
            `/transactions?${setSearchParams({
              // TODO: Need to sync with backend and make corrections to types
              // @ts-expect-error-next-line
              accountIds: [response.accountId],
            })}`
          );
        },
      }
    );

  const handleCancelAccountAdd = () => {
    refetchAccounts();
    setIsAccountAddOpen(false);
  };

  if (getFileLoading) {
    return (
      <S.LoaderWrapper>
        <Loader />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.Wrapper>
      <PageMeta title={t('transactionsImport.titleImport')()} />
      <S.Container gap={30} vertical>
        <AccountAddModal
          open={isAccountAddOpen}
          onCancel={handleCancelAccountAdd}
        />
        <Flex gap={16} vertical>
          {!isPreviewVisible ? <GoBack to="/accounts" /> : <div />}

          <S.Title>
            {!isPreviewVisible
              ? t('transactionsImport.titleImport')()
              : t('transactionsImport.titlePreview')()}
          </S.Title>
        </Flex>

        {preview && !isPreviewVisible && (
          <Flex gap={40}>
            <TransactionsImportTable result={validatedData} />
          </Flex>
        )}

        {isPreviewVisible && (
          <TransactionsImportValidatedTable result={validatedData} />
        )}
      </S.Container>

      <TransactionsImportSidebar
        onReview={handleReview}
        onImport={handleImport}
        onBack={() => setPreviewVisible(false)}
        validatingResult={validatedData}
        isPreviewVisible={isPreviewVisible}
        loading={loadingImport || loadingValidate || loadingUpdate}
      />
    </S.Wrapper>
  );
};

export default TransactionsImport;
