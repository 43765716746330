import { Pagination as AntdPagination, Flex, Modal, Typography } from 'antd';

import styled from '@emotion/styled';
import { SearchControl } from '@entities';

export const TableContainer = styled(Flex)`
  width: 708px;
  height: 508px;
  min-height: 504px;

  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
`;

export const Search = styled(SearchControl)`
  && {
    width: 358px;
  }
`;

export const List = styled(Flex)`
  height: 508px;
  align-items: center;
  justify-content: center;
`;

export const ModalPaper = styled(Modal)`
  && {
    .ui-modal-content {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .ui-modal-header {
        margin-bottom: 0;
      }

      .ui-modal-footer {
        margin-top: -4px;
      }
    }
  }
`;

export const Pagination = styled(AntdPagination)`
  .ui-pagination-item {
    border-radius: 50%;
  }

  .ui-pagination-item-active {
    background-color: ${({ theme }) => theme.colors.primary};

    & > a {
      color: ${({ theme }) => theme.colors.white};
    }

    & > a:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Divider = styled.div`
  height: 35px;
  border-right: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  margin: 0 10px 0 5px;
`;

export const Text = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.text400};
  font-weight: 500;
`;
