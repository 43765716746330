import { Button, Popover, Upload } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { Schemas } from '@api-client/generated/types';
import {
  IconClip,
  IconDown,
  IconMissedFile,
  IconUp,
  IconUpload,
} from '@assets';
import { CornerCard } from '@entities';
import { useTranslate } from '@hooks';
import { colors } from '@theme';

import * as S from './styled';

type File = Schemas.Document;

type MissingPaymentDocumentsProps = {
  transaction: Schemas.Transaction;
  selectedCard: File | null;
  isPotentialActionsOpen: boolean;
  disableUpload: boolean;
  onOpenPotentialActions: (value: boolean) => void;
  onCardClick: (file: File | null) => void;
  onAddExistingFile: (file: File) => void;
  onRefresh?: () => void;
  onListUpdate: (file: File) => void;
  onCancelUpload: (id: string) => void;
  onAttach?: () => void;
  onAttachExistingDocumentTemplate: (value: boolean) => void;
  onUploadRequest: (options: UploadRequestOption) => void;
  isModal: boolean;
};

const MissingPaymentDocuments: FC<MissingPaymentDocumentsProps> = ({
  transaction,
  selectedCard,
  isPotentialActionsOpen,
  disableUpload,
  onOpenPotentialActions,
  onCardClick,
  onAddExistingFile,
  onRefresh,
  onListUpdate,
  onCancelUpload,
  onAttach,
  onAttachExistingDocumentTemplate,
  onUploadRequest,
  isModal,
}) => {
  const { translate } = useTranslate();

  const handleAttachTemplate = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onAttachExistingDocumentTemplate(false);
  };
  const handleSelectFile = (file: File) => {
    if (selectedCard === null) onCardClick?.(file);

    onCardClick?.(file?.id === selectedCard?.id ? null : file);
  };

  const [{ canDrop }, dropRef] = useDrop(
    {
      accept: [NativeTypes.FILE],
      collect: (monitor: DropTargetMonitor) => ({
        canDrop: monitor.canDrop(),
      }),
    },
    []
  );

  return (
    <S.PaymentDocumentsErrorWrapper vertical>
      <S.PaymentDocumentsErrorContainer gap={12}>
        <IconMissedFile width={24} height={24} color={colors.error} />
        <S.MissingTextContainer
          ismodal={isModal ? 'true' : ''}
          gap={5}
          vertical
        >
          <S.MissingDocumentsTitle>
            {translate('transaction.documents.payment.missingDocumentTitle')}
          </S.MissingDocumentsTitle>
          <S.MissingDocumentsDescription ismodal={isModal ? 'true' : ''}>
            {translate(
              'transaction.documents.payment.missingDocumentDescription'
            )}
          </S.MissingDocumentsDescription>
        </S.MissingTextContainer>
      </S.PaymentDocumentsErrorContainer>

      {transaction?.potentialDocuments?.length ? (
        <S.PotentialFilesWrapper gap={28} align="center" vertical>
          <S.PotentialFilesContainer gap={16} vertical>
            <S.MissingDocumentInfo gap={6} vertical>
              <S.BlockTitle>
                {translate('transaction.documents.suggested.attach')}
              </S.BlockTitle>
              <span>{translate('transaction.documents.suggested.review')}</span>
            </S.MissingDocumentInfo>

            <S.FilesList ismodal={isModal ? 'true' : ''} gap={8} vertical>
              {transaction?.potentialDocuments?.map((potentialFile) => (
                <CornerCard
                  type="selectable"
                  transaction={transaction}
                  file={potentialFile}
                  selectedCard={selectedCard}
                  onClick={() => handleSelectFile(potentialFile)}
                  onAddExistingFile={onAddExistingFile}
                  onRefresh={onRefresh}
                  onListUpdate={onListUpdate}
                  onCancelUpload={onCancelUpload}
                />
              ))}
            </S.FilesList>
          </S.PotentialFilesContainer>

          <S.PotentialFilesFooter gap={12}>
            <Popover
              open={isPotentialActionsOpen}
              onOpenChange={onOpenPotentialActions}
              trigger="click"
              placement="bottomRight"
              arrow={false}
              overlayClassName="ui-popover-legal-actions"
              title={
                <S.Actions>
                  <S.AttachNew
                    onClick={() => onAttachExistingDocumentTemplate(false)}
                  >
                    <IconClip />
                    <span>
                      {translate(
                        'transaction.documents.attachExistingDocument'
                      )}
                    </span>
                  </S.AttachNew>

                  <S.UploadWrapper>
                    <Upload
                      customRequest={onUploadRequest}
                      showUploadList={false}
                      disabled={disableUpload}
                    >
                      <S.UploadExisting>
                        <IconUpload />
                        <span>
                          {translate('transaction.documents.uploadNewDocument')}
                        </span>
                      </S.UploadExisting>
                    </Upload>
                  </S.UploadWrapper>
                </S.Actions>
              }
            >
              <S.ButtonAddAnother
                type="default"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                block
              >
                {translate('transaction.documents.suggested.addAnother')}
                {isPotentialActionsOpen ? <IconUp /> : <IconDown />}
              </S.ButtonAddAnother>
            </Popover>

            <S.ButtonAttach
              type="primary"
              size="small"
              onClick={onAttach}
              disabled={!selectedCard}
              block
            >
              {translate('transaction.documents.attach')}
            </S.ButtonAttach>
          </S.PotentialFilesFooter>
        </S.PotentialFilesWrapper>
      ) : (
        <S.UploadContainer ref={dropRef}>
          <S.MissingPaymentDocumentsUpload
            customRequest={onUploadRequest}
            showUploadList={false}
            disabled={disableUpload}
          >
            {canDrop ? (
              <S.DropFileContainer
                justify="center"
                align="center"
                gap={6}
                vertical
              >
                <IconUpload />
                {translate('transaction.documents.dropFileHereUpload')}
              </S.DropFileContainer>
            ) : (
              <S.DropFilePreview
                ismodal={isModal ? 'true' : ''}
                align="center"
                gap={24}
                vertical
              >
                <S.DropFileBlock gap={5} align="center" vertical>
                  <S.AcceptFilesTextTitle>
                    {translate('transaction.documents.dropFileHereAdd')}
                  </S.AcceptFilesTextTitle>
                  <S.AcceptFilesText>
                    {translate('transaction.documents.filesAccepted')}
                  </S.AcceptFilesText>
                </S.DropFileBlock>
                <S.DropFilePreviewButtons gap={8}>
                  <Button
                    type="default"
                    size="small"
                    onClick={handleAttachTemplate}
                    block
                  >
                    {translate('transaction.documents.attachExistingFile')}
                  </Button>
                  <Button type="primary" size="small">
                    {translate('transaction.documents.uploadFile')}
                  </Button>
                </S.DropFilePreviewButtons>
              </S.DropFilePreview>
            )}
          </S.MissingPaymentDocumentsUpload>
        </S.UploadContainer>
      )}
    </S.PaymentDocumentsErrorWrapper>
  );
};

export default MissingPaymentDocuments;
