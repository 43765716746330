import { Schemas } from '@api-client/generated/types';
import { LocalePaths } from '@locales';

export type ValidationResult = Schemas.ValidationResult;
type ValidationRow = Schemas.ValidationRow;

export const createColumns = (
  rows: ValidationRow[] = [],
  translate: (key: LocalePaths) => string
): { key: string; dataIndex: string; title: string }[] => {
  const columns = rows.length ? Object.keys(rows[0]) : [];

  return columns.map((column) => ({
    key: column,
    dataIndex: column,
    title: translate(
      // @ts-expect-error-next-line
      `transactionsImport.tableResult.columns.${column}`
    ),
  }));
};
