//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$PaymentMethodController_delete,
  Response$PaymentMethodController_delete$Status$200,
  ResponseContentType$PaymentMethodController_delete,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const usePaymentMethodController_delete = () =>
  useApiClientMutation<
    Params$PaymentMethodController_delete,
    Response$PaymentMethodController_delete$Status$200[ResponseContentType$PaymentMethodController_delete]
  >({ method: 'PaymentMethodController_delete' });
