import { Schemas } from "@api-client/generated/types";

type PersonDto = Schemas.PersonDto;
type Person = Schemas.Person;

export const isLegalPersonWithoutChildren = (person: Partial<PersonDto | Person>): boolean => {
  if (!person.people?.length) {
    return true;
  }

  for (const child of person.people) {
    if (child.type === 'legal') {
      if (isLegalPersonWithoutChildren(child)) return true
    }
  }

  return false;
}

export const isAnyLegalPersonWithoutChildren = (people: Partial<PersonDto | Person>[]): boolean => {
  for (const person of people) {
    if (person.type === 'legal') {
      return isLegalPersonWithoutChildren(person);
    }
  }

  return false;
}
