import styled from '@emotion/styled';
import { Input, InputNumber, Row } from 'antd';
import { Select } from '@components';

export const Inner = styled.div`
  .ui-form-item {
    margin-bottom: 20px;
  }
`;

export const BalanceRow = styled(Row)`
  background-color: ${({ theme }) => theme.colors.badgeGrey};
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
`

export const Hint = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
`;

export const FormInput = styled(Input)`
  height: 48px;
  font-size: 16px;
`;

export const FormSelect = styled(Select)`
  && {
    height: 48px;
  }
  
  & span {
    font-size: 16px;
  }
`;

export const FormInputNumber = styled(InputNumber)`
  height: 48px;
  
  && input {
    font-size: 16px;
    height: 48px;
  }
`;
