import { Params$IncorporationController_updateEmail } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useUpdateEmailIncorporation = () => useApiClientMutation<
    Params$IncorporationController_updateEmail,
    Schemas.UpdateEmailIncorporationDto
  >({
    method: 'IncorporationController_updateEmail',
  });

export default useUpdateEmailIncorporation;
