import { Button, Flex, Grid, Typography } from 'antd';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { MessageCompletedCompany, MessageCompletedCompany2 } from '@assets';
import { OnboardingMessage } from '@entities';
import { useOnboarding, useTranslate } from '@hooks';

import * as S from './styled';

const { useBreakpoint } = Grid;
const { Paragraph, Text } = Typography;

const OnboardingMessageCompletedCompany: FC = () => {
  const { translate } = useTranslate();

  const breakpoint = useBreakpoint();

  const { incorporationDetails } = useOnboarding();

  const handleContinue = () => {
    // TODO: should update state and layout instead
    window.location.reload();
  };

  return (
    <S.Container>
      <OnboardingMessage
        picture={
          breakpoint.md ? (
            <MessageCompletedCompany />
          ) : (
            <MessageCompletedCompany2 />
          )
        }
        title={translateUntyped(
          `onboarding.messages.completedCompany.title_${incorporationDetails?.incorporationType}`
        )()}
        description={
          <Trans
            i18nKey={translateUntyped(
              breakpoint.md
                ? `onboarding.messages.completedCompany.description_${incorporationDetails?.incorporationType}`
                : `onboarding.messages.completedCompany.description_${incorporationDetails?.incorporationType}_mobile`
            )()}
            components={[<Paragraph />, <Text strong />]}
          />
        }
      />

      {breakpoint.md && (
        <Flex justify="flex-end">
          <Button type="primary" onClick={handleContinue}>
            {translate('onboarding.buttonContinue')}
          </Button>
        </Flex>
      )}
    </S.Container>
  );
};

export default OnboardingMessageCompletedCompany;
