import { Params$IncorporationController_confirmEmail } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useConfirmEmailIncorporation = () => useApiClientMutation<
    Params$IncorporationController_confirmEmail,
    Schemas.ConfirmEmailIncorporationDto
  >({
    method: 'IncorporationController_confirmEmail',
  });

export default useConfirmEmailIncorporation;
