import { Input } from 'antd';
import { useId } from 'react';

import { Select } from '@components';
import { useModalManager } from '@context/ModalManager/useModalManager';
import styled from '@emotion/styled';
import { ContactsPopupList } from '@entities';
import { LocalePaths } from '@locales';

import {
  Control,
  FormValues,
  Operator,
  SelectContactOperator,
  SelectOperator,
  SelectOptions,
} from './utils';

const TextField = styled(Input)`
  width: 390px;
  height: 40px;
`;

const SelectField = <TFieldName extends string>({
  props,
  onChange,
  value,
  disabledValues,
}: {
  props: SelectOperator<TFieldName>;
  value?: string;
  onChange: (value: string) => void;
  disabledValues?: SelectOptions[number]['value'][];
}) => {
  const translateOption = (
    option: SelectOptions[number] & {
      label: LocalePaths;
    }
  ): SelectOptions[number] => ({
    ...option,
    label: t(option.label)(),
    disabled:
      option.disabled ||
      (!!option.value && disabledValues?.includes(option.value)),
  });

  const selectProps: Parameters<typeof Select>[0] = {
    ...props.props,
    placeholder: t(props.props.placeholder)(),
    options: props.translateOptions
      ? props.props.options.map<SelectOptions[number]>(translateOption)
      : props.props.options,
    disabled:
      props.props.disabled ||
      disabledValues?.length === props.props.options.length,
  };

  return (
    <Select size="large" {...selectProps} onChange={onChange} value={value} />
  );
};

const SelectContactField = <TFieldName extends string>({
  props,
  onChange,
  value,
}: {
  props: SelectContactOperator<TFieldName>;
  value?: string;
  onChange: (value: string) => void;
}) => {
  const contactSelector = useId();

  const modalManager = useModalManager();
  const isContactSelectorVisible = modalManager.isOpen(contactSelector);

  const selectProps: Parameters<typeof Select>[0] = {
    ...props.props,
    placeholder: t(props.props.placeholder)(),
    dropdownRender: () => <></>,
    optionRender: () => <></>,
    dropdownStyle: { display: 'none' },
  };

  return (
    <ContactsPopupList
      onSelect={(contact) => {
        onChange(contact.id);
        modalManager.pop(contactSelector);
      }}
      open={isContactSelectorVisible}
      onOpenChange={modalManager.createSetModalVisibility(contactSelector)}
      placement="bottomLeft"
      width={props.props.width && props.props.width - 24}
    >
      <Select size="large" {...selectProps} value={value} />
    </ContactsPopupList>
  );
};

const RulesFormControlsList = <
  TOptionValue extends string,
  TSelectorFieldName extends string,
  TOperatorFieldName extends string,
>({
  controls,
  values,
  onChange,
  disabledSelectorValues,
}: {
  controls: Control<TOptionValue, TSelectorFieldName, TOperatorFieldName>;
  values: FormValues<TOptionValue, TSelectorFieldName, TOperatorFieldName>;
  onChange: (field: string, value: string) => void;
  disabledSelectorValues: TOptionValue[];
}) => {
  const operator: Operator<TOperatorFieldName> | undefined =
    controls.operator[values[controls.selector.fieldName]];

  const selectorValue: TOptionValue | undefined =
    values[controls.selector.fieldName];

  return (
    <>
      <SelectField<TSelectorFieldName>
        props={{ ...controls.selector, translateOptions: true }}
        onChange={(value) => onChange(controls.selector.fieldName, value)}
        value={selectorValue}
        disabledValues={disabledSelectorValues}
      />
      {operator?.map((control, index) => {
        const value: string | undefined = values[control.fieldName];

        switch (control.type) {
          case 'input':
            return (
              <TextField
                key={index}
                onChange={(event) =>
                  onChange(control.fieldName, event.target.value)
                }
                value={value}
              />
            );
          case 'select': {
            return (
              <SelectField
                props={control}
                key={index}
                onChange={(value) => onChange(control.fieldName, value)}
                value={value}
              />
            );
          }
          case 'select_contact': {
            return (
              <SelectContactField
                props={control}
                key={index}
                onChange={(value) => onChange(control.fieldName, value)}
                value={value}
              />
            );
          }
        }
      })}
    </>
  );
};

export default RulesFormControlsList;
