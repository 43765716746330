import { Flex, Form, Input, Typography, message } from 'antd';
import { FC, PropsWithChildren } from 'react';

import { Schemas } from '@api-client/generated/types';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

type KYCActivityDetailsProps = {
  person: Schemas.Person;
};

const { Paragraph, Title } = Typography;

const KYCActivityDetails: FC<PropsWithChildren<KYCActivityDetailsProps>> = ({
  person,
}) => {
  const { translate } = useTranslate();

  const { companyId } = useAccount();

  const { incorporationDetails, updateIncorporation } = useOnboarding();

  const [form] = Form.useForm();

  const { mutate: update } = useUpdateIncorporation();

  const onFinish = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      ...form.getFieldsValue(),
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>
          {translate('onboarding.kyc.activityDetails.title')}
        </Title>

        <Paragraph type="secondary">
          {translate('onboarding.kyc.activityDetails.description')}
        </Paragraph>
      </div>

      <Form
        form={form}
        layout="vertical"
        colon={false}
        initialValues={person.kycData}
      >
        <Form.Item
          name="purposeOfEstablishment"
          label={translate(
            'onboarding.kyc.legalKycForm.purposeOfEstablishment.label'
          )}
        >
          <Input.TextArea size="large" onBlur={onFinish} />
        </Form.Item>

        <Form.Item
          name="assessmentOfIncomingFunds"
          label={translate(
            'onboarding.kyc.legalKycForm.assessmentOfIncomingFunds.label'
          )}
        >
          <Input.TextArea size="large" onBlur={onFinish} />
        </Form.Item>

        <Form.Item
          name="assessmentOfOutgoingFunds"
          label={translate(
            'onboarding.kyc.legalKycForm.assessmentOfOutgoingFunds.label'
          )}
        >
          <Input.TextArea size="large" onBlur={onFinish} />
        </Form.Item>

        <Form.Item
          name="clientsOfCompany"
          label={translate(
            'onboarding.kyc.legalKycForm.clientsOfCompany.label'
          )}
        >
          <Input.TextArea size="large" onBlur={onFinish} />
        </Form.Item>

        <Form.Item
          name="countriesInvolved"
          label={translate(
            'onboarding.kyc.legalKycForm.countriesInvolved.label'
          )}
        >
          <Input.TextArea size="large" onBlur={onFinish} />
        </Form.Item>

        <Form.Item
          name="longTermGoals"
          label={translate('onboarding.kyc.legalKycForm.longTermGoals.label')}
        >
          <Input.TextArea size="large" onBlur={onFinish} />
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default KYCActivityDetails;
