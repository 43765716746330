import { useEffect, useState } from 'react';

import { useBankController_getBanks } from '@api-client/generated/BankController/getBanks';
import { Schemas } from '@api-client/generated/types';
import { BankItem } from '@entities';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';

import * as S from './styled';

type BankListProps = {
  term: string;
  onBankConnect: (bank: Schemas.Bank) => void;
};

const usePaginatedBanksList = (term: string) => {
  const { metadata, incrementPage, hasNextPage, plainData, appendData } =
    usePagination<Schemas.Bank>(term);

  const { isFetching: isLoading, data } = useBankController_getBanks({
    params: {
      page: metadata.currentPage,
      term,
    },
  });

  useEffect(() => {
    if (data) {
      appendData(data);
    }
  }, [appendData, data]);

  const { sentryRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  return {
    plainData,
    sentryRef,
    hasNextPage,
  };
};

const BanksList = ({ term, onBankConnect }: BankListProps) => {
  const [selectedBank, setSelectedBank] = useState<Schemas.Bank | null>(null);

  const { plainData, hasNextPage, sentryRef } = usePaginatedBanksList(term);

  const handleBankConnect = (bank: Schemas.Bank) => {
    setSelectedBank(bank);

    onBankConnect(bank);
  };

  return (
    <S.Grid>
      {plainData.map((bank) => (
        <BankItem
          isLoading={false}
          selectedBank={selectedBank}
          key={bank.id}
          logo={`./${bank.bic}.png`}
          bank={bank}
          onConnect={handleBankConnect}
        />
      ))}
      {hasNextPage && <div ref={sentryRef} />}
    </S.Grid>
  );
};

export default BanksList;
