import { FC } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { useTranslate } from '@hooks';

import * as S from './styled';

type CategoryBlockHeaderType = {
  isIn: boolean;
  onPress: () => void;
};

const MainCategoryBlockHeader: FC<CategoryBlockHeaderType> = ({
  isIn,
  onPress,
}) => {
  const { translate } = useTranslate();
  return (
    <S.Wrapper>
      <S.Container isin={isIn}>
        <S.Title>
          {translate(
            `categoriesPage.mainCategories.${isIn ? 'moneyIn' : 'moneyOut'}`
          )}
        </S.Title>
        <S.HeaderButton
          icon={<PlusOutlined width={20} height={20} />}
          onClick={onPress}
          type="text"
        >
          {translate('categoriesPage.mainCategories.addGroup')}
        </S.HeaderButton>
      </S.Container>
    </S.Wrapper>
  );
};

export default MainCategoryBlockHeader;
