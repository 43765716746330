import { useCallback, useState } from 'react';

import { useRuleController_delete } from '@api-client/generated/RuleController/delete';
import { useRuleController_findAll } from '@api-client/generated/RuleController/findAll';
import { useRuleController_restore } from '@api-client/generated/RuleController/restore';

export const useRulesList = ({ companyId }: { companyId: string }) => {
  const [isOnlyArchivedRules, setIsOnlyArchivedRules] = useState(false);

  const { mutate: archiveRule } = useRuleController_delete();
  const { mutate: restoreRule } = useRuleController_restore();

  const {
    data: rules,
    isPending: isLoading,
    refetch,
  } = useRuleController_findAll({
    params: {
      companyId: companyId!,
      onlyArchived: isOnlyArchivedRules,
    },
  });

  const handleArchiveRule = useCallback(
    (id: string) =>
      archiveRule(
        { parameter: { id, companyId } },
        { onSuccess: () => refetch() }
      ),
    [archiveRule, companyId, refetch]
  );

  const handleRestoreRule = useCallback(
    (id: string) =>
      restoreRule(
        { parameter: { id, companyId: companyId! } },
        { onSuccess: () => refetch() }
      ),
    [restoreRule, companyId, refetch]
  );

  return {
    handleArchiveRule,
    handleRestoreRule,
    rules,
    isLoading,
    refetch,
    toggleArchivedRules: () => setIsOnlyArchivedRules((prev) => !prev),
    isOnlyArchivedRules,
  };
};
