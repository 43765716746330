import { useCallback, useEffect, useMemo, useState } from 'react';
import useInfiniteScrollHook from 'react-infinite-scroll-hook';

import { Schemas } from '@api-client/generated/types';

const initialMetadata = {
  currentPage: 1,
  perPage: 0,
  totalPages: 0,
  totalRecords: 0,
};

// TODO add error handling and retries
export const usePagination = <T,>(hash?: unknown) => {
  const [metadata, setMetadata] = useState(initialMetadata);

  const [data, setData] = useState<Record<number, T[]>>({ 1: [] });

  const appendData = useCallback(
    (response: { metadata: Schemas.PaginationMetadata; records: T[] }) => {
      setData((prev) => ({
        ...prev,
        [response.metadata.currentPage]: response.records,
      }));

      setMetadata(response.metadata);
    },
    []
  );

  const reset = useCallback((data: T[] = []) => {
    setMetadata((prev) => ({ ...prev, currentPage: 1 }));
    setData({ 1: data });
  }, []);

  const incrementPage = useCallback(
    () =>
      setMetadata((prev) => ({
        ...prev,
        currentPage: prev.currentPage + 1,
      })),
    []
  );

  const plainData = useMemo(
    () =>
      Object.keys(data)
        .sort((a, b) => Number(a) - Number(b))
        .map((key) => data[Number(key)])
        .flat(),
    [data]
  );

  const setPageWithFlush = useCallback((page: number, flushTail?: boolean) => {
    if (flushTail) {
      setData((prev) =>
        Object.fromEntries(
          Object.entries(prev).filter(([key]) => Number(key) <= page)
        )
      );
    }

    setMetadata((prev) => ({
      ...prev,
      currentPage: page,
    }));
  }, []);

  useEffect(() => {
    reset();
  }, [reset, hash]);

  return {
    metadata,
    incrementPage,
    hasNextPage: metadata.currentPage < metadata.totalPages,
    plainData,
    appendData,
    setPage: setPageWithFlush,
    reset,
    UNSAFE_setData: setData,
  };
};

export const useInfiniteScroll = (
  params: Omit<
    Parameters<typeof useInfiniteScrollHook>[0],
    'loading' | 'rootMargin'
  > & {
    isLoading: boolean;
    threshold?: number;
  }
) => {
  const { isLoading, threshold = 300, ...restParams } = params;

  const [sentryRef, { rootRef }] = useInfiniteScrollHook({
    ...restParams,
    rootMargin: `0px 0px ${threshold}px 0px`,
    loading: isLoading,
  });

  return {
    rootRef,
    sentryRef,
  };
};
