import { Schemas } from '@api-client/generated/types';
import { useTranslate } from '@hooks';
import { Flex, Typography } from 'antd';
import { FC, PropsWithChildren } from 'react';
import { KYCFiles } from '@entities';
import { Trans } from 'react-i18next';

type KYCOwnershipChartProps = {
  person: Schemas.Person;
  selectedType: string | null;
  selectedStepValue: string;
};

const { Paragraph, Title } = Typography;

const KYCOwnershipChart: FC<PropsWithChildren<KYCOwnershipChartProps>> = ({
  person,
  selectedType,
  selectedStepValue,
}) => {
  const { translate } = useTranslate();

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>
          {translate('onboarding.kyc.legal.ownership_chart')}
        </Title>

        <Paragraph type="secondary">
          <Trans
            i18nKey={translate('onboarding.kyc.legalDetails.ownership_chart')}
            components={[
              <br />,
              <strong />,
            ]}
          />
        </Paragraph>
      </div>

      <KYCFiles
        person={person}
        selectedType={selectedType}
        selectedStepValue={selectedStepValue}
      />
    </Flex>
  );
};

export default KYCOwnershipChart;
