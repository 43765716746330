import { Flex } from 'antd';
import { FC, ReactNode, useState } from 'react';

import * as S from './styled';

type RadioControl = {
  value: string;
  title: string;
  content?: ReactNode;
};

export type RadioGroupProps = {
  controls: RadioControl[];
  defaultValue?: RadioControl['value'];
  vertical?: boolean;
  size?: 'middle' | 'large';
  onChange?: (value: string) => void;
};

const RadioGroup: FC<RadioGroupProps> = ({
  defaultValue = null,
  vertical = false,
  size = 'middle',
  controls,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(
    defaultValue
  );

  const handleSelect = (value: string) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  return (
    <Flex align="center" gap={size === 'large' ? 16 : 12} vertical={vertical}>
      {controls.map(({ value, content, ...control }) => (
        <S.Control
          key={value}
          onClick={() => handleSelect(value)}
          selected={selectedValue === value}
          size={size}
        >
          <S.ControlIcon selected={selectedValue === value} size={size} />

          <Flex vertical>
            <S.Title size={size}>{control.title}</S.Title>

            {content && <S.Content>{content}</S.Content>}
          </Flex>
        </S.Control>
      ))}
    </Flex>
  );
};

export default RadioGroup;
