import { FC } from 'react';

import { PictureCsvImportsDelete } from '@assets';
import { ModalDelete } from '@entities';

type TransactionIngestPopupDeleteProps = {
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
};

const TransactionIngestPopupDelete: FC<TransactionIngestPopupDeleteProps> = ({
  open,
  onCancel,
  onDelete,
}) => (
  <ModalDelete
    open={open}
    title={t('csvImports.deleteConfirm.title')()}
    description={t('csvImports.deleteConfirm.description')()}
    picture={<PictureCsvImportsDelete />}
    confirmParams={{
      text: t('csvImports.deleteConfirm.buttonDelete')(),
    }}
    onRemove={onDelete}
    onCancel={onCancel}
  />
);

export default TransactionIngestPopupDelete;
