import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Card = styled(Flex)<FlexProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 20px;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.textBlue400};
  font-size: 12px;
`;

export const Value = styled(Flex)<FlexProps>`
  font-weight: 500;
`;

export const Copy = styled.div`
  min-width: 28px;

  .ui-btn {
    .ui-btn-icon {
      svg {
        color: ${({ theme }) => theme.colors.textDisabled};
        transition: color 0.2s;
      }
    }

    &:hover {
      .ui-btn-icon {
        svg {
          color: ${({ theme }) => theme.colors.text400};
        }
      }
    }
  }
`;
