import { Schemas } from '@api-client/generated/types';

export type StatusUnit =
  | 'isProcessing'
  | 'isBooked'
  | 'isWrongDocument'
  | 'isCorruptedFile'
  | 'isToBeBooked'
  | 'isToReview';

export type VariantUnit =
  | 'default'
  | 'warning'
  | 'success'
  | 'error'
  | 'process';

type DocumentStatusProps = {
  isRecognitionCompleted: boolean;
  documentMetadata: Schemas.DocumentMetadata;
  adminStatus: Schemas.Document['adminStatus'];
  potentialDuplicate: Schemas.Document | null;
};

type DocumentStatus = {
  status: StatusUnit;
  variant: VariantUnit;
};

const getDocumentStatus = ({
  isRecognitionCompleted,
  documentMetadata,
  adminStatus,
  potentialDuplicate,
}: DocumentStatusProps): DocumentStatus => {
  if (!isRecognitionCompleted) {
    return { status: 'isProcessing', variant: 'default' };
  } else if (adminStatus === 'processed') {
    return { status: 'isBooked', variant: 'success' };
  } else if (adminStatus === 'requested_changes') {
    return { status: 'isWrongDocument', variant: 'error' };
  } else if (potentialDuplicate !== null) {
    return { status: 'isCorruptedFile', variant: 'error' };
  } else if (
    isRecognitionCompleted &&
    documentMetadata.isReviewed &&
    (adminStatus === 'ready' || adminStatus === 'processing')
  ) {
    return { status: 'isToBeBooked', variant: 'process' };
  } else if (isRecognitionCompleted && !documentMetadata.isReviewed) {
    return { status: 'isToReview', variant: 'warning' };
  }

  return { status: 'isProcessing', variant: 'default' };
};

export default getDocumentStatus;
