import styled from '@emotion/styled';
import { PageEmptyState as PageEmptyStateBase } from '@entities';

export const SuccessText = styled.span`
  color: ${({ theme }) => theme.colors.success};
`;

export const DangerText = styled.span`
  color: ${({ theme }) => theme.colors.red500};
`;

export const PageEmptyState = styled(PageEmptyStateBase, {
  shouldForwardProp: (prop) => prop !== 'type',
})<{ type: 'income' | 'expenses' }>`
  position: relative;

  &:after {
    position: absolute;
    border-radius: 0 0 5px 5px;
    top: 0;
    content: '';
    width: 201px;
    height: 8px;
    background-color: ${({ theme, type }) =>
      type === 'income' ? theme.colors.green500 : theme.colors.red500};
  }
`;

export const Dropzone = styled.div`
  position: absolute;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.grey100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inset: 0;
  z-index: 1;
`;

export const DropzoneInner = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.primary};
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;
`;

export const DropzoneText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
`;
