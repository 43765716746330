import { FC } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

import { BrandLogotype } from '@assets';
import styled from '@emotion/styled';
import { useAccount } from '@hooks';

const Wrapper = styled(Link)<LinkProps>`
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 22px;
  }
`;

export const Logotype: FC = () => {
  const { isLogged } = useAccount();

  return (
    <Wrapper to={isLogged ? '/transactions' : '/'}>
      <BrandLogotype />
    </Wrapper>
  );
};
