import { Flex } from 'antd';

import { IconCheckThin } from '@assets';
import styled from '@emotion/styled';

export const Users = styled.div`
  .ui-avatar {
    .ui-avatar-string {
      text-transform: uppercase;
    }
  }

  && table {
    thead {
      tr {
        height: 24px;

        .ui-table-cell {
          padding-bottom: 10px;

          &:first-of-type {
            padding-left: 0;
          }

          &:not(:first-of-type) {
            display: table-cell;
            text-align: center;
            vertical-align: middle;

            & span {
              display: inline-block;
            }
          }
        }
      }
    }

    tbody {
      tr {
        .ui-table-cell {
          &:first-of-type {
            padding-left: 0;
          }
        }

        &:last-child {
          .ui-table-cell {
            border-bottom: none;
          }
        }
      }
    }
  }
`;

export const UserName = styled(Flex)<{ isinvite?: string }>`
  padding: 12px 0;
  color: ${({ isinvite, theme }) =>
    isinvite?.length ? theme.colors.black : theme.colors.text200};
`;

export const Name = styled.span`
  font-weight: 600;
  line-height: 20px;
`;

export const Email = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text200};
`;

export const Boldest = styled.span`
  font-weight: 800;
`;

export const ColumnTitle = styled.span<{
  isbordered?: string;
  isname?: string;
}>`
  display: flex;
  width: ${({ isname }) =>
    isname?.length ? '447px' : 'fit-content'} !important;
  border-bottom: ${({ isbordered, theme }) =>
    isbordered?.length ? `1px dashed ${theme.colors.textBlue400}` : 'none'};
  padding-bottom: 4px;
  cursor: default;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textBlue400};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const CheckWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
`;

export const Check = styled(IconCheckThin)<{ isdone?: string }>`
  color: ${({ theme }) => theme.colors.primary};
  display: ${({ isdone }) => (isdone?.length ? 'flex' : 'none')};

  && svg {
    width: 24px;
    height: 24px;
  }
`;

export const More = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const DropdownItem = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
`;
