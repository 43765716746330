import styled from '@emotion/styled';

export const Option = styled.div`
  min-width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
`;
