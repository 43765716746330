import { rgba } from 'emotion-rgba';

import { colorScheme } from './colorScheme';

export const backgroundColors = {
  bgDarkBlue: '#022C7E',
  bgYellow: '#FFE48A',
  bgLightYellow: '#FFF3CC',
  bgLightBlue: '#EAEFFF',
  bgBrightBlue: '#E5EBFF',
  bgLightBlue200: '#F7F9FF',
  bgGrey: '#F2F5F9',
  bgDarkGrey: '#E2E7EE',
  bgWarning: '#FFD5D5',
  bgSuccess: rgba('#009206', 0.08), // is not present in Figma
  bgLightSuccess: '#E1FFE6',
  bgWhite: colorScheme.white,
  bgDev: '#610F5E',
  bgDevSelected: '#3E0A3C',
};