import { Flex } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconCreditCardRounded } from '@assets';

import * as S from './styled';

type TransactionIngest = Schemas.TransactionIngest;

type TransactionIngestItemProps = {
  selectedId?: string;
  details: TransactionIngest;
  onSelected: (details: TransactionIngest) => void;
};

const TransactionIngestItem: FC<TransactionIngestItemProps> = ({
  selectedId,
  details,
  onSelected,
}) => (
  <S.Item
    gap={8}
    selected={selectedId === details.id}
    onClick={() => onSelected(details)}
    vertical
  >
    <S.Title ellipsis={{ rows: 1 }}>{details.account.name}</S.Title>

    <Flex align="center" justify="space-between">
      <S.Date>{dayjs(details.createdAt).format('DD.MM.YYYY, HH:mm')}</S.Date>

      <S.Stat align="center" gap={6}>
        <IconCreditCardRounded width={18} height={18} />
        {details.transactionsAmount || 0}
      </S.Stat>
    </Flex>
  </S.Item>
);

export default TransactionIngestItem;
