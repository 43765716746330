import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

import { type VariantUnit } from './helpers/getDocumentStatus';

export const Container = styled(Flex)<FlexProps & { variant: VariantUnit }>`
  width: 100%;
  height: 54px;
  background-color: ${({ theme, variant }) => {
    if (variant === 'warning') {
      return theme.colors.bgLightYellow;
    }

    if (variant === 'error') {
      return theme.colors.red300;
    }

    if (variant === 'success') {
      return theme.colors.green300;
    }

    return theme.colors.darkgrey;
  }};
  border-bottom: 2px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 10px 10px 0 0;
  padding: 0 30px;
`;

export const Count = styled.div`
  font-weight: 600;
`;

export const Divider = styled.div`
  width: 2px;
  height: 22px;
  background-color: ${({ theme }) => theme.colors.grey300};
`;
