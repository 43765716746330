import { Button, Flex, Form, Input, Typography } from 'antd';
import { FC, useState } from 'react';
import { Trans } from 'react-i18next';

import { useUserController_getCurrentUser } from '@api-client/generated/UserController/getCurrentUser';
import { Schemas } from '@api-client/generated/types';
import { Loader } from '@components';
import { OnboardingCardHeader } from '@entities';
import { useAccount, useOnboarding } from '@hooks';
import {
  useConfirmEmailIncorporation,
  useUpdateEmailIncorporation,
} from '@hooks-api';
import { sendGTMEvent } from '@utils';

import * as S from './styled';

const { Text } = Typography;

const OnboardingVerifyAccount: FC = () => {
  const { companyId } = useAccount();
  const { data: user, isPending: loading } = useUserController_getCurrentUser();
  const { incorporationDetails, updateScreen, updateIncorporation } =
    useOnboarding();
  const [isEmailFormShown, setIsEmailFormShown] = useState(false);

  const [emailError, setEmailError] = useState<string | null>();

  const [isCodeWrong, setIsCodeWrong] = useState(false);

  const { mutate: confirmEmail } = useConfirmEmailIncorporation();
  const { mutate: updateEmail } = useUpdateEmailIncorporation();

  const handleConfirmEmail = (code: string) => {
    confirmEmail(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          code,
        },
      },
      {
        onSuccess: () => {
          updateIncorporation({
            isConfirmedEmail: true,
          });

          updateScreen('MESSAGE_COMPLETED_COMPANY', { active: true });
          sendGTMEvent('accountCreated', {});
        },
        onError: () => {
          setIsCodeWrong(true);
        },
      }
    );
  };

  const onFinish = (value: Schemas.User) => {
    updateEmail(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: value,
      },
      {
        onError: (error) => {
          for (const message of error.response?.data?.message || []) {
            if (message.startsWith('email.')) {
              setEmailError(message);
            }
          }
        },
      }
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <S.Container>
      <S.Inner>
        <OnboardingCardHeader
          title={t('onboarding.verifyAccount.title')()}
          description={
            <Trans
              i18nKey={t('onboarding.verifyAccount.description')()}
              components={[
                <Text strong>
                  {
                    (incorporationDetails as Schemas.Incorporation)?.jwtData
                      ?.user.email
                  }
                </Text>,
              ]}
            />
          }
          shorted
        />

        <Input.OTP
          onChange={(code) => handleConfirmEmail(code)}
          status={isCodeWrong ? 'error' : ''}
        />
      </S.Inner>

      {!isEmailFormShown && (
        <S.Link onClick={() => setIsEmailFormShown(true)}>
          {t('onboarding.verifyAccount.linkAnotherEmail')()}
        </S.Link>
      )}

      {isEmailFormShown && (
        <Form
          initialValues={{
            email: user?.email,
          }}
          onFinish={onFinish}
        >
          <Flex gap={24}>
            <Form.Item
              label={false}
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: t('onboarding.createAccount.email.placeholder')(),
                },
              ]}
              validateStatus={emailError ? 'error' : ''}
              help={
                emailError &&
                translateUntyped(`onboarding.createAccount.${emailError}`)()
              }
            >
              <Input
                placeholder={t('onboarding.createAccount.email.placeholder')()}
                size="large"
              />
            </Form.Item>

            <Button type="primary" htmlType="submit" size="small">
              {t('onboarding.buttonChangeEmail')()}
            </Button>
          </Flex>
        </Form>
      )}
    </S.Container>
  );
};

export default OnboardingVerifyAccount;
