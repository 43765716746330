import { Button, Flex, Modal, type ModalProps } from 'antd';
import { FC, useState } from 'react';

import { RadioGroup } from '@components';
import { vatTypes } from '@constants';

import * as S from './styled';

type SelectVatTypeProps = {
  value?: string;
  onCancel: () => void;
  onChange: (type: string) => void;
} & ModalProps;

const SelectVatType: FC<SelectVatTypeProps> = ({
  value,
  open,
  onChange,
  onCancel,
  ...rest
}) => {
  const [selectedType, setSelectedType] = useState(value || vatTypes[0]);

  const handleCancel = () => {
    setSelectedType(vatTypes[0]);
    onCancel();
  };

  const handleSubmit = () => {
    onChange(selectedType);
    onCancel();
  };

  return (
    <Modal
      width={575}
      title={
        <Flex gap={8} vertical>
          {t('common.vatType.title')()}

          <S.Description>{t('common.vatType.description')()}</S.Description>
        </Flex>
      }
      open={open}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <Flex gap={40} vertical>
        <RadioGroup
          defaultValue={selectedType}
          controls={vatTypes.map((type) => ({
            title: t(`common.vatType.${type}.title`)(),
            content: t(`common.vatType.${type}.description`)(),
            value: type,
          }))}
          size="large"
          onChange={setSelectedType}
          vertical
        />

        <Flex justify="flex-end" gap={12}>
          <Button onClick={handleCancel}>
            {t('common.vatType.buttonCancel')()}
          </Button>

          <Button type="primary" onClick={handleSubmit}>
            {t('common.vatType.buttonSelect')()}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default SelectVatType;
