import { Dropdown } from 'antd';
import { rgba } from 'emotion-rgba';
import { memo, useCallback, useId } from 'react';

import { Schemas } from '@api-client/generated/types';
import { useModalManager } from '@context/ModalManager/useModalManager';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ContactsPopupList } from '@entities';
import { IconChevronDown, IconChevronUp } from '@ui-kit/Icon';

import { useGetRules } from '../../rules/useGetRules';

const HoverableButton = styled.div();

const Invisible = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`;

const Wrapper = styled.div`
  position: relative;
  margin-left: -12px;
`;
const Contact = styled('div', {
  shouldForwardProp: (prop) =>
    !['isDropdownOpen', 'isContactSelectorOpen', 'hasContact'].includes(prop),
})<{
  isDropdownOpen: boolean;
  isContactSelectorOpen: boolean;
  hasContact: boolean;
}>`
  display: inline-flex;
  padding: 4px 8px 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  position: relative;

  ${HoverableButton} {
    height: 24px;
    color: ${({ theme }) => theme.colors.grey500};
    visibility: ${({ isDropdownOpen, isContactSelectorOpen }) =>
      isDropdownOpen || isContactSelectorOpen ? 'visible' : 'hidden'};

    & > svg {
      height: 24px;
      min-width: 24px;
      max-width: 24px;
    }
  }

  background-color: ${({ hasContact, theme }) =>
    !hasContact && theme.colors.yellow200};
  background-color: ${({ isDropdownOpen, theme }) =>
    isDropdownOpen && theme.colors.grey300};
  background-color: ${({ isContactSelectorOpen, theme }) =>
    isContactSelectorOpen && theme.colors.grey100};
  color: ${({ hasContact, theme }) =>
    hasContact ? theme.colors.black : theme.colors.grey400};

  ${({ isContactSelectorOpen, theme }) =>
    isContactSelectorOpen &&
    css`
      border-radius: 8px;
      outline: 2px solid ${theme.colors.grey300};
      color: ${theme.colors.grey400};

      box-shadow: 0px 4px 21px 0px ${rgba(theme.colors.dark, 0.14)};
    `}

  &:hover {
    background-color: ${({ isContactSelectorOpen, theme }) =>
      !isContactSelectorOpen && theme.colors.grey300};

    ${HoverableButton} {
      visibility: visible;
    }
  }
`;

export const ContactName = memo(
  ({
    contact,
    rawContactName,
    assignContact,
    onRulesSubmit,
  }: {
    contact: Schemas.Contact | null;
    rawContactName: string | null;
    assignContact: (contact: Schemas.Contact) => void;
    onRulesSubmit: () => void;
  }) => {
    const dropdown = useId();
    const contactSelector = useId();

    const modalManager = useModalManager();

    const isDropdownVisible = modalManager.isOpen(dropdown);
    const isContactSelectorVisible = modalManager.isOpen(contactSelector);

    const { setStep, setTransactionsState, setConfig } = useGetRules();

    const openRulesModal = useCallback(() => {
      setConfig({
        onSuccess: onRulesSubmit,
        tabs: ['transaction'],
      });

      setTransactionsState({
        conditions: [
          {
            attribute: 'contact_name',
            operator: 'contains',
            value: contact?.name || rawContactName || '',
          },
        ],
        actions: [{ name: 'assign_contact' }],
      });

      setStep('form');
    }, [
      contact?.name,
      rawContactName,
      onRulesSubmit,
      setConfig,
      setStep,
      setTransactionsState,
    ]);

    const items = {
      items: [
        {
          key: 'assignContact',
          label: t(
            contact
              ? 'transactionsPage.contactActions.replaceContact'
              : 'transactionsPage.contactActions.assignContact'
          )(),
          onClick: () => modalManager.replace(contactSelector),
        },
        {
          key: 'createRule',
          label: t('transactionsPage.contactActions.createRule')(),
          onClick: () => {
            modalManager.pop(dropdown);
            openRulesModal();
          },
        },
      ],
    } satisfies Parameters<typeof Dropdown>[0]['menu'];

    const handleAssignContact: typeof assignContact = useCallback(
      (contact) => {
        assignContact(contact);
        modalManager.pop(contactSelector, dropdown);
      },
      [assignContact, contactSelector, dropdown, modalManager]
    );

    return (
      <Wrapper>
        <Dropdown
          menu={items}
          open={isDropdownVisible}
          onOpenChange={modalManager.createSetModalVisibility(dropdown)}
          trigger={['click']}
          placement="bottomLeft"
        >
          <Contact
            isDropdownOpen={isDropdownVisible}
            isContactSelectorOpen={isContactSelectorVisible}
            hasContact={!!contact}
          >
            {contact?.name ||
              rawContactName ||
              t('transactionsPage.noContact')()}

            <HoverableButton>
              {isDropdownVisible || isContactSelectorVisible ? (
                <IconChevronUp />
              ) : (
                <IconChevronDown />
              )}
            </HoverableButton>
          </Contact>
        </Dropdown>
        <ContactsPopupList
          onSelect={handleAssignContact}
          open={isContactSelectorVisible}
          onOpenChange={modalManager.createSetModalVisibility(contactSelector)}
          placement="bottomLeft"
        >
          <Invisible />
        </ContactsPopupList>
      </Wrapper>
    );
  }
);
