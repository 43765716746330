import { AccountsProvider } from '@context';

import Accounts from './Accounts';

const AccountsPage = () => (
  <AccountsProvider>
    <Accounts />
  </AccountsProvider>
);

export default AccountsPage;
