import { ProjectsProvider } from '@context';

import Projects from './Projects';

const ProjectsPage = () => (
  <ProjectsProvider>
    <Projects />
  </ProjectsProvider>
);

export default ProjectsPage;
