import { Button, Col, Flex, Input, Row, Table, message } from 'antd';
import days from 'dayjs';
import _ from 'lodash';
import { FC, KeyboardEvent, useEffect, useState } from 'react';

import { useDocumentController_updateOneById } from '@api-client/generated/DocumentController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { IconCalendar, IconCheck, IconEditUnderline, IconPlus } from '@assets';
import {
  ButtonDashed,
  DatePickerWithAction,
  InputBorderless,
} from '@components';
import { VAT_DEFAULT_PERCENT, VAT_MIDDLE_PERCENT } from '@constants';
import {
  InvoiceActionItem,
  InvoiceBankAdd,
  InvoiceDocumentDetailsTo,
  InvoiceDocumentTotal,
  InvoiceEdit,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';

import * as S from './styled';
import { getTableColumns } from './tableColumns';

type InvoiceItem = Schemas.InvoiceItem;

type InvoiceDocumentProps = {
  document: Schemas.Document;
  onAfterUpdate: () => void;
};

type UpdateDocumentDto = Omit<Schemas.UpdateDocumentDto, 'documentMetadata'> & {
  documentMetadata?: Partial<Schemas.DocumentMetadata>;
};

const defaultPercent = VAT_DEFAULT_PERCENT / 100;
const middlePercent = VAT_MIDDLE_PERCENT / 100;

const getTotalAmount = (items: InvoiceItem[]): number =>
  +_.sum(items.map((item) => item.totalPrice)).toFixed(2);

const getSubTotalAmount = (items: InvoiceItem[]): number =>
  +_.sum(
    items.map((item) => item.totalPrice - item.totalPrice * item.taxRate)
  ).toFixed(2);

const getItemsByDefaultTaxRate = (items: InvoiceItem[]) =>
  items.filter((item) => item.taxRate === defaultPercent);

const getItemsByMiddleTaxRate = (items: InvoiceItem[]) =>
  items.filter((item) => item.taxRate === middlePercent);

const getAmountByDefaultTaxRate = (items: InvoiceItem[]): number => {
  const itemsByDefaultTaxRate = getItemsByDefaultTaxRate(items);

  return +_.sum(
    itemsByDefaultTaxRate.map((item) => item.totalPrice * item.taxRate)
  ).toFixed(2);
};

const getAmountByMiddleTaxRate = (items: InvoiceItem[]): number => {
  const itemsByMiddleTaxRate = getItemsByMiddleTaxRate(items);

  return +_.sum(
    itemsByMiddleTaxRate.map((item) => item.totalPrice * item.taxRate)
  ).toFixed(2);
};

const InvoiceDocument: FC<InvoiceDocumentProps> = ({
  document,
  onAfterUpdate,
}) => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const [isEditDataVisible, setIsEditDataVisible] = useState(false);
  const [isBankAddVisible, setIsBankAddVisible] = useState(false);
  const [isItemActionVisible, setIsItemActionVisible] = useState(false);
  const [actionItemType, setActionItemType] = useState<'add' | 'edit'>('add');
  const [selectedItem, setSelectedItem] = useState<InvoiceItem | null>(null);
  const [isEditName, setIsEditName] = useState(false);
  const [documentName, setDocumentName] = useState('');

  const { mutate: updateDocument } = useDocumentController_updateOneById();

  const documentItems = document.documentMetadata?.items || [];

  const documentNumber = document.name?.replace(
    translate('invoiceGenerator.document.name'),
    ''
  );

  useEffect(() => {
    if (documentNumber) {
      setDocumentName(documentNumber);
    }
  }, [documentNumber]);

  const columns = getTableColumns({
    translate,
  });

  const handleAddItem = () => {
    setIsItemActionVisible(true);
    setActionItemType('add');
  };

  const handleUpdateDocument = (values: UpdateDocumentDto) => {
    updateDocument(
      {
        parameter: {
          companyId: companyId!,
          id: document.id!,
        },
        requestBody: values as Schemas.UpdateDocumentDto,
      },
      {
        onSuccess: () => {
          onAfterUpdate();

          if (isItemActionVisible) {
            handleCancelActionItem();
          }

          setIsEditName(false);
          setIsBankAddVisible(false);
          setIsEditDataVisible(false);

          message.success(
            translate('invoiceGenerator.document.invoiceUpdatedMessage')
          );
        },
      }
    );
  };

  const handleSubmitItem = (values: InvoiceItem) => {
    handleUpdateDocument({
      documentMetadata: {
        ...document.documentMetadata,
        items:
          actionItemType === 'add'
            ? [...documentItems, values]
            : documentItems.map((item) =>
                item.id === (selectedItem && selectedItem.id)
                  ? { ...item, ...values }
                  : item
              ),
      },
    });
  };

  const handleRemoveItem = (id: string) => {
    handleUpdateDocument({
      documentMetadata: {
        ...document.documentMetadata,
        items: documentItems.filter((item) => item.id !== id),
      },
    });
  };

  const handleCancelActionItem = () => {
    setIsItemActionVisible(false);
    setActionItemType('add');
    setSelectedItem(null);
  };

  const handleUpdateName = () => {
    if (!isEditName) {
      setIsEditName(true);
    } else {
      handleUpdateDocument({
        name: `${translate('invoiceGenerator.document.name')}${documentName}`,
      });
    }
  };

  const handleUpdateByKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleUpdateName();
    }
  };

  const handleOnRow = (record: InvoiceItem) => ({
    onClick: () => {
      setIsItemActionVisible(true);
      setActionItemType('edit');
      setSelectedItem(record);
    },
  });

  return (
    <S.Card justify="space-between" vertical>
      <InvoiceEdit
        open={isEditDataVisible}
        onCancel={() => setIsEditDataVisible(false)}
      />

      <InvoiceBankAdd
        open={isBankAddVisible}
        onCancel={() => setIsBankAddVisible(false)}
      />

      {isItemActionVisible && (
        <InvoiceActionItem
          type={actionItemType}
          item={selectedItem}
          open={isItemActionVisible}
          onSubmit={handleSubmitItem}
          onCancel={handleCancelActionItem}
          onRemove={handleRemoveItem}
        />
      )}

      <div>
        <S.Header align="flex-start" justify="space-between">
          <S.Title level={2}>
            <Flex gap={8} align="center">
              <Flex align="center">
                {translate('invoiceGenerator.document.name')}

                {isEditName ? (
                  <S.Name>
                    <InputBorderless
                      value={documentName}
                      onKeyDown={handleUpdateByKeyPress}
                      onChange={(e) => setDocumentName(e.target.value)}
                    />
                  </S.Name>
                ) : (
                  documentNumber
                )}
              </Flex>

              <Button
                type="text"
                icon={isEditName ? <IconCheck /> : <IconEditUnderline />}
                size="small"
                onClick={handleUpdateName}
              />
            </Flex>
          </S.Title>

          <S.SubTitle level={5}>Pinnacle Innovations</S.SubTitle>
        </S.Header>

        <Flex gap={48} vertical>
          <Flex gap={5} vertical>
            <Flex align="center" gap={8}>
              <span>{translate('invoiceGenerator.document.issuedOn')}</span>
              <span>{document.documentMetadata?.issueDate || '-'}</span>

              <DatePickerWithAction
                onChange={(date) =>
                  handleUpdateDocument({
                    documentMetadata: {
                      ...document.documentMetadata,
                      issueDate: days(date).format('DD.MM.YYYY'),
                    },
                  })
                }
              >
                <Button type="text" icon={<IconCalendar />} size="small" />
              </DatePickerWithAction>
            </Flex>

            <Flex align="center" gap={8}>
              <span>{translate('invoiceGenerator.document.dueDate')}</span>
              <span>{document.documentMetadata?.dueDate || '-'}</span>

              <DatePickerWithAction
                onChange={(date) =>
                  handleUpdateDocument({
                    documentMetadata: {
                      ...document.documentMetadata,
                      dueDate: days(date).format('DD.MM.YYYY'),
                    },
                  })
                }
              >
                <Button type="text" icon={<IconCalendar />} size="small" />
              </DatePickerWithAction>
            </Flex>

            <Flex align="center" gap={8}>
              <span>{translate('invoiceGenerator.document.deliveryDate')}</span>
              <span>28.06.2024</span>

              <DatePickerWithAction onChange={() => {}}>
                <Button type="text" icon={<IconCalendar />} size="small" />
              </DatePickerWithAction>
            </Flex>
          </Flex>

          <Row gutter={[32, 0]}>
            <Col span={12}>
              <InvoiceDocumentDetailsTo
                contact={document.contact || null}
                onSelect={(contact) =>
                  handleUpdateDocument({
                    contactId: contact.id,
                  })
                }
              />
            </Col>

            <Col span={12}>
              <S.Details gap={8} vertical>
                <S.DetailsTitle>
                  {translate('invoiceGenerator.document.from')}
                </S.DetailsTitle>

                <Flex vertical>
                  <S.DetailsType>Pinnacle Innovations</S.DetailsType>

                  <S.ButtonAddVat onClick={() => setIsEditDataVisible(true)}>
                    <IconPlus />
                    {translate('invoiceGenerator.document.buttonAddVatId')}
                  </S.ButtonAddVat>

                  <span>
                    Business permit number: 49633361 / 6 50 Val Fleuri, 1526,
                    Luxembourg, Luxembourg
                  </span>
                </Flex>
              </S.Details>
            </Col>
          </Row>
        </Flex>

        <S.Table gap={8} vertical>
          <Table
            rowKey={({ id }) => id}
            onRow={handleOnRow}
            dataSource={documentItems}
            columns={columns}
            pagination={false}
            scroll={{ x: 720 }}
          />

          <ButtonDashed icon={<IconPlus />} onClick={handleAddItem} block>
            {translate('invoiceGenerator.document.buttonAddItem')}
          </ButtonDashed>

          {documentItems.length ? (
            <InvoiceDocumentTotal
              total={getTotalAmount(documentItems)}
              subTotal={getSubTotalAmount(documentItems)}
              defaultTaxRate={getAmountByDefaultTaxRate(documentItems)}
              middleTaxRate={getAmountByMiddleTaxRate(documentItems)}
            />
          ) : null}
        </S.Table>
      </div>

      <Flex gap={60} vertical>
        <Flex gap={24} vertical>
          <S.Control gap={8} vertical>
            <S.ControlLabel>
              {translate('invoiceGenerator.document.fieldNotes.label')}
            </S.ControlLabel>

            <Input.TextArea
              variant="borderless"
              placeholder={translate(
                'invoiceGenerator.document.fieldNotes.placeholder'
              )}
            />
          </S.Control>

          <S.Control gap={8} vertical>
            <S.ControlLabel>
              {translate('invoiceGenerator.document.fieldTerms.label')}
            </S.ControlLabel>

            <Input.TextArea
              variant="borderless"
              placeholder={translate(
                'invoiceGenerator.document.fieldTerms.placeholder'
              )}
            />
          </S.Control>
        </Flex>

        <Flex align="center" justify="space-between" gap={70}>
          <ButtonDashed
            icon={<IconPlus />}
            height={70}
            onClick={() => setIsBankAddVisible(true)}
          >
            {translate('invoiceGenerator.document.buttonAddBank')}
          </ButtonDashed>

          <Flex align="center" gap={4} vertical>
            <S.Address>alexandersmith@pinnacleinovations.com</S.Address>
            <S.Address>+352 28 228 229</S.Address>
          </Flex>
        </Flex>
      </Flex>
    </S.Card>
  );
};

export default InvoiceDocument;
