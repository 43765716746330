import { Modal, type ModalProps } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { FileViewer } from '@entities/index';

import * as S from './styled';

type OnboardingViewDocumentModalProps = {
  file: Schemas.CompanyFile | null;
  type: string | null;
  step: string | null;
} & ModalProps;

const OnboardingViewDocumentModal: FC<OnboardingViewDocumentModalProps> = ({
  file,
  type,
  step,
  ...rest
}) => {
  const isTypePDF = file?.mimeType?.includes('pdf');

  return (
    <Modal
      width="auto"
      footer={null}
      title={translateUntyped(`onboarding.kyc.${type}.${step}`)()}
      destroyOnClose
      centered
      {...rest}
    >
      {file && (
        <S.PreviewContent>
          {isTypePDF && (
            <FileViewer width="38vw" height="80vh" selectedFile={file} />
          )}
        </S.PreviewContent>
      )}
    </Modal>
  );
};

export default OnboardingViewDocumentModal;
