import { Params$FileMappingController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useCreateFileMappings = () => useApiClientMutation<
    Params$FileMappingController_create,
    Schemas.FileMapping
  >({
    method: 'FileMappingController_create',
  });

export default useCreateFileMappings;
