//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$CompanyController_findOneById,
  Response$CompanyController_findOneById$Status$200,
  ResponseContentType$CompanyController_findOneById,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useCompanyController_findOneById = (options: {
  params: Params$CompanyController_findOneById['parameter'];
  config?: QueryOptions<
    Response$CompanyController_findOneById$Status$200[ResponseContentType$CompanyController_findOneById]
  >;
}) =>
  useApiClient<
    Params$CompanyController_findOneById,
    Response$CompanyController_findOneById$Status$200[ResponseContentType$CompanyController_findOneById]
  >({
    method: 'CompanyController_findOneById',
    params: { parameter: options.params },
    config: options.config,
  });
