import { Flex } from 'antd';
import { FC, useEffect } from 'react';
import { useIntersectionObserver } from 'react-intersection-observer-hook';

import { Schemas } from '@api-client/generated/types';
import styled from '@emotion/styled';
import { useAccount } from '@hooks';
import { colors } from '@theme';
import { getDateFromNow } from '@utils';

import FilesList from '../../FilesList/FilesList';
import { isUnread } from '../transformData';
import MessageActions from './MessageActions';

const Actions = styled.div`
  width: 40px;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0;
  transition: opacity 0.1s;
`;

const Wrapper = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'position',
})<{
  position: 'left' | 'right';
}>`
  background-color: ${({ position }) =>
    position === 'right' ? colors.bgLightYellow : colors.bgGrey};
  border-radius: ${({ position }) =>
    position === 'right' ? '16px 16px 0 16px' : '16px 16px 16px 0'};
  margin-left: ${({ position }) => (position === 'left' ? '48px' : 0)};
  padding: 15px 16px;
  position: relative;
  gap: 5px;
  justify-content: ${({ position }) =>
    position === 'left' ? 'flex-start' : 'flex-end'};

  &:hover {
    ${Actions} {
      opacity: 1;
    }
  }
`;

const Text = styled.p`
  color: var(--Black);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  p {
    min-height: 6px;
  }

  p + p,
  ul + p {
    margin-top: 8px;
  }

  ul li {
    list-style-type: disc;
    margin-left: 20px;
  }
`;

const Date = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
`;

const DeletedBody = styled.span`
  color: var(--Grey-400);
`;

type MessageProps = {
  position: 'left' | 'right';
  onRemove: () => void;
  message: Schemas.ChatMessage;
  onRead: (ids: string[]) => void;
};

const Message: FC<MessageProps> = ({
  message: { body, deletedAt, createdAt, files, seenData, id },
  position,
  onRemove,
  onRead,
}) => {
  const { account } = useAccount();

  if (!account) {
    throw new Error('Account is not defined');
  }

  const [ref, { entry }] = useIntersectionObserver();
  const isVisibleAndUnread =
    entry && entry.isIntersecting && isUnread({ seenData }, account.id);

  useEffect(() => {
    if (isVisibleAndUnread) {
      onRead([id]);
    }
  }, [id, isVisibleAndUnread, onRead]);

  return (
    <Wrapper position={position} vertical ref={ref}>
      {deletedAt ? (
        <Text>
          <DeletedBody>{t('chatLive.deletedMessage')()}</DeletedBody>
        </Text>
      ) : (
        <Text dangerouslySetInnerHTML={{ __html: body }} />
      )}

      {position === 'right' && (
        <Actions>
          <MessageActions onRemove={onRemove} />
        </Actions>
      )}

      {!deletedAt && files && <FilesList dataSource={files} />}

      {!deletedAt && createdAt && <Date>{getDateFromNow(createdAt)}</Date>}
    </Wrapper>
  );
};

export default Message;
