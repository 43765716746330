import { AccountsProvider, TransactionsImportProvider } from '@context';

import TransactionsImport from './TransactionsImport';

const TransactionsImportPage = () => (
  <TransactionsImportProvider>
    <AccountsProvider>
      <TransactionsImport />
    </AccountsProvider>
  </TransactionsImportProvider>
);

export default TransactionsImportPage;
