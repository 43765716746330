import { Badge as BaseBadge } from 'antd';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useThrottledCallback } from 'use-debounce';

import { useTaskController_findAll } from '@api-client/generated/TaskController/findAll';
import { IconTasks } from '@assets';
import styled from '@emotion/styled';
import { useAccount } from '@hooks';

const Badge = styled(BaseBadge)`
  .ui-badge-dot {
    width: 12px;
    height: 12px;
    box-shadow: none;
  }
`;

// might be reused in the future, but since used just once is kept here
const useUnreadChatMessagesCounter = () => {
  const { companyId } = useAccount();
  const location = useLocation();
  const isOnboarding = location.pathname === '/start';

  if (!companyId) {
    throw new Error('Company ID is not defined');
  }

  const { data: tasks, refetch } = useTaskController_findAll({
    params: {
      companyId,
    },
    config: {
      enabled: !isOnboarding,
    },
  });

  const throttleRefetch = useThrottledCallback(refetch, 5000);

  useEffect(() => {
    if (!isOnboarding) {
      throttleRefetch();
    }
  }, [isOnboarding, location, throttleRefetch]);

  return useMemo(
    () =>
      tasks?.reduce((acc, task) => acc + task.unseenMessagesCount, 0),
    [tasks]
  );
};

// className is required for correct menu item styling with Antd
export const ChatIcon = ({ className }: { className?: string }) => {
  const hasUnreadMessages = !!useUnreadChatMessagesCounter();

  return (
    <Badge className={className} dot={hasUnreadMessages} offset={[-3.5, 3]}>
      <IconTasks />
    </Badge>
  );
};
