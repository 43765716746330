import { Flex } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';

import { useDocumentController_findAll } from '@api-client/generated/DocumentController/findAll';
import { Schemas } from '@api-client/generated/types';
import { IconClose, IconTrash } from '@assets';
import { Select } from '@components';
import { AttachDropdownItem, TransactionFilePreviewModal } from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { colors } from '@theme';
import { getCurrencySymbol } from '@utils';

import * as S from './styled';

type CornerCardProps = {
  type: 'hovering' | 'selectable';
  transaction: Schemas.Transaction;
  file: Schemas.Document;
  selectedCard?: Schemas.Document | null;
  onDetach?: () => void;
  onDelete?: () => void;
  onRefresh?: () => void;
  onClick?: () => void;
  onAddExistingFile?: (file: Schemas.Document) => void;
  onListUpdate: (file: Schemas.Document) => void;
  onCancelUpload: (id: string) => void;
};

const CornerCard: FC<CornerCardProps> = ({
  type,
  transaction,
  file,
  selectedCard,
  onDetach,
  onDelete,
  onRefresh,
  onAddExistingFile,
  onListUpdate,
  onCancelUpload,
  onClick,
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();
  const [isViewMode, setIsViewMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const amount = file.documentMetadata?.amount || 0;
  const currencyCode = file.documentMetadata?.currency || 'EUR';
  const isNew = file.id === 'new';
  const isIncomeExpence = Boolean(
    file?.type === 'income_document' || 'expence_document'
  );

  const {
    isPending: loadingAll,
    refetch,
    data,
  } = useDocumentController_findAll({
    params: {
      companyId: companyId!,
      type: transaction.amount > 0 ? 'income_document' : 'expence_document',
      term: searchValue,
    },
    config: {
      enabled: isNew && isIncomeExpence,
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, transaction]);

  return (
    <S.Wrapper
      type={
        file?.id === selectedCard?.id
          ? 'selected'
          : type === 'hovering' && !isNew
            ? 'hovering'
            : 'selectable'
      }
      onClick={onClick}
    >
      <S.CornerCard
        type={
          file?.id === selectedCard?.id
            ? 'selected'
            : type === 'hovering' && !isNew
              ? 'hovering'
              : 'selectable'
        }
      />
      <S.TextBlock gap={19} justify="space-between" vertical>
        {isNew && isIncomeExpence ? (
          <span>{translate('common.cornerCard.attach')}</span>
        ) : (
          <Flex gap={6} vertical>
            <S.CornerCardAdditionalTitle>
              {translate('common.cornerCard.paymentDocument')}
            </S.CornerCardAdditionalTitle>
            <S.CornerCardTitle>
              {file?.contact?.name || transaction.contact?.name}
            </S.CornerCardTitle>
            <S.CornerCardDescription>
              {file.documentMetadata?.description || <div />}
            </S.CornerCardDescription>
          </Flex>
        )}

        {isNew && isIncomeExpence && (
          <Select
            autoFocus={isNew}
            options={data?.records.map((file) => ({
              ...file,
              label: file?.contact?.name || file?.name,
              value: file.id,
            }))}
            loading={loadingAll}
            listWidth={333}
            width={293}
            onSearch={setSearchValue}
            className="ui-attach-select"
            placeholder={translate('common.cornerCard.paymentDocument')}
            onChange={(_, option) => {
              onAddExistingFile?.(option as Schemas.Document);
            }}
            dropdownRender={(menu) => (
              <div className="ui-attach-select-dropdown">{menu}</div>
            )}
            optionRender={(oriOption) => (
              <AttachDropdownItem
                item={oriOption.data as Schemas.Document}
                key={oriOption.key}
              />
            )}
            allowClear={{ clearIcon: <IconClose width={20} height={20} /> }}
            dropdownAlign={{
              points: ['tl', 'bl'],
              offset: [-20, 0],
            }}
            filterOption={false}
            defaultOpen
            showSearch
          />
        )}

        {isNew && isIncomeExpence ? (
          <Flex justify="flex-start" align="center">
            <S.DetachButton type="text" onClick={onDelete}>
              <IconTrash color={colors.error} />
              {translate('common.actions.delete')}
            </S.DetachButton>
          </Flex>
        ) : (
          <S.Footer justify="space-between">
            <S.CornerCardPrice className="price">{`${amount < 0 ? '-' + getCurrencySymbol(currencyCode) + Math.abs(amount).toFixed(2) : getCurrencySymbol(currencyCode) + amount}`}</S.CornerCardPrice>
            <S.CornerCardDate className="date">
              {dayjs(file.documentMetadata?.issueDate).format('DD.MM.YYYY')}
            </S.CornerCardDate>

            <S.Actions className="actions">
              <S.ViewButton type="text" onClick={() => setIsViewMode(true)}>
                {translate('common.actions.view')}
              </S.ViewButton>
              <S.DetachButton type="text" onClick={onDetach}>
                {translate('common.actions.detach')}
              </S.DetachButton>
            </S.Actions>
          </S.Footer>
        )}
      </S.TextBlock>

      <TransactionFilePreviewModal
        type={transaction?.amount > 0 ? 'income' : 'expenses'}
        open={isViewMode}
        onClose={() => setIsViewMode(false)}
        file={file}
        onRefresh={onRefresh}
        onListUpdate={onListUpdate}
        onCancelUpload={onCancelUpload}
      />
    </S.Wrapper>
  );
};

export default CornerCard;
