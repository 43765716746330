import { Flex, Space } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconLogout } from '@assets';
import { CompaniesSelect } from '@entities';
import { useAccount } from '@hooks';

import { Logotype } from './Logotype';
import * as S from './styled';

export const Header: FC = () => {
  const navigate = useNavigate();

  const isDev = import.meta.env.VITE_APP_MODE === 'DEV';

  const { account, company, companies, clearAccount, isLogged } = useAccount();

  const handleLogout = () => {
    clearAccount();
    navigate('/login');
  };

  return (
    <S.Header>
      <Logotype />
      {isDev && <S.DevText>{t('common.header.devText')()}</S.DevText>}

      {isLogged && (
        <Flex align="center" style={{ marginLeft: 'auto' }}>
          <Flex gap={32}>
            <Space size={8}>
              <S.UserName>{account?.fullName}</S.UserName>
            </Space>

            {company && (
              <Space size={8}>
                {companies.length > 1 ? (
                  <CompaniesSelect />
                ) : (
                  <S.UserName>{company?.name}</S.UserName>
                )}
              </Space>
            )}
          </Flex>

          <S.Logout onClick={handleLogout}>
            <IconLogout />
          </S.Logout>
        </Flex>
      )}
    </S.Header>
  );
};
