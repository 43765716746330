import { Modal, message } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC, ReactNode, useEffect, useState } from 'react';

import { useDocumentController_delete } from '@api-client/generated/DocumentController/delete';
import { Schemas } from '@api-client/generated/types';
import {
  FilelessBlock,
  MissingPaymentDocuments,
  PageMeta,
  PaymentDocuments,
  SupplementaryDocuments,
  TransactionCardDetails,
  TransactionFilePreviewModal,
  TransactionSplit,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useDocumentCreate, useUpdateTransactionById } from '@hooks-api';
import useSocketClient from '@hooks/useSocketClient';

import * as S from './styled';

type TransactionContentProps = {
  id: string;
  transaction: Schemas.Transaction;
  headerContent: ReactNode;
  onChangeFileless: (value: boolean) => void;
  onChangeTransactionFileList?: (id: string, ids: Schemas.Document[]) => void;
  onUpdateTransaction?: VoidFunction;
  onRefresh: () => void;
  isModal: boolean;
};

const TransactionContent: FC<TransactionContentProps> = ({
  id,
  transaction,
  headerContent,
  onChangeFileless,
  onChangeTransactionFileList,
  onUpdateTransaction,
  onRefresh,
  isModal,
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();
  const [modal, contextHolder] = Modal.useModal();

  const [allFiles, setAllFiles] = useState<Schemas.Document[]>(
    transaction?.documents || []
  );
  const [childTransactionsCount, setChildTransactionsCount] =
    useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<Schemas.Document | null>(
    null
  );
  const [selectedPaymentFile, setSelectedPaymentFile] =
    useState<Schemas.Document | null>(null);
  const [isDownloadFileLoading, setIsDownloadFileLoading] = useState(false);
  const [isPaymentViewModalOpen, setIsPaymentViewModalOpen] = useState(false);
  const [isPotentialViewModalOpen, setIsPotentialViewModalOpen] =
    useState(false);
  const [isPaymentActionsOpen, setIsPaymentActionsOpen] = useState(false);
  const [isPotentialActionsOpen, setIsPotentialActionsOpen] = useState(false);
  const [isSupplementaryActionsOpen, setIsSupplementaryActionsOpen] =
    useState(false);
  const [
    isSupplementaryAdditionalActionsOpen,
    setIsSupplementaryAdditionalActionsOpen,
  ] = useState(false);
  const [selectedCornerCard, setSelectedCornerCard] =
    useState<Schemas.Document | null>(
      transaction?.potentialDocuments?.length === 1
        ? transaction?.potentialDocuments[0]
        : null
    );

  const { socket } = useSocketClient({
    namespace: 'documents',
  });

  useEffect(() => {
    setChildTransactionsCount(transaction.childTransactions?.length || 0);
    setAllFiles(transaction?.documents || []);
  }, [transaction]);

  const { mutate: updateTransaction } = useUpdateTransactionById();
  const { mutate: createFile, isPending: createFileLoading } =
    useDocumentCreate();
  const { mutate: deleteDocument } = useDocumentController_delete();

  const handleChangeTransactionIds = (ids: string[]) => {
    updateTransaction(
      {
        parameter: {
          id: id!,
          companyId: companyId!,
        },
        requestBody: {
          documentIds: Array.from(new Set(ids)).join(','),
        },
      },
      {
        onSuccess: (res) => {
          onRefresh();
          onChangeTransactionFileList?.(
            res.id,
            // @ts-expect-error-next-line
            ids?.map((item) => ({ id: item }))
          );
          message.success(translate('transaction.success.update'));
        },
      }
    );
  };

  useEffect(() => {
    socket.on('document:processed', (response) => {
      setAllFiles((prevState) =>
        prevState?.map((record) =>
          record.id === response.data.id ? response.data : record
        )
      );
      setSelectedPaymentFile(response.data);

      onRefresh();
    });
  }, [onRefresh, socket]);

  const amount = transaction.amount;

  const paymentData = allFiles?.filter(
    (file) =>
      file.type === 'income_document' || file.type === 'expence_document'
  );

  const supplementaryData = allFiles?.filter(
    (file) =>
      file.type !== 'income_document' && file.type !== 'expence_document'
  );

  const handleDownloadFile = async (
    file: Schemas.Document | (string & { url: string; name: string })
  ) => {
    setIsDownloadFileLoading(true);
    try {
      const response = await fetch(file.url!);
      const blob = await response.blob();
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.target = '_blank';
      link.download = file.name! || 'file.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setIsDownloadFileLoading(false);
      setSelectedFile(null);
    } catch {
      setIsDownloadFileLoading(false);
      setSelectedFile(null);
    }
  };

  const handleAttachExistingDocumentTemplate = (isOther: boolean) => {
    if (!isOther) {
      setIsPaymentActionsOpen(false);
      !allFiles?.some((file) => file.id === 'new') &&
        setAllFiles((prev) => [
          ...prev,
          {
            id: 'new',
            type: isOther
              ? 'other'
              : amount > 0
                ? 'income_document'
                : 'expence_document',
          } as Schemas.Document,
        ]);
    } else {
      setIsSupplementaryActionsOpen(false);
    }
  };

  const handleDelete = () => {
    setAllFiles((prev) => prev?.filter((file) => file.id !== 'new'));
  };

  const handleUploadFile = (
    options: UploadRequestOption,
    isOther: boolean = false,
    isSuggested: boolean = false
  ) => {
    !isOther && setIsPaymentViewModalOpen(true);
    const formData = new FormData();

    formData.append('file', options.file);
    // @ts-expect-error-next-line
    formData.append('name', options.file.name!);
    formData.append(
      'type',
      isOther ? 'other' : amount > 0 ? 'income_document' : 'expence_document'
    );

    createFile(
      {
        parameter: {
          companyId: companyId!,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        requestBody: formData as any,
      },
      {
        onSuccess: (response) => {
          setAllFiles((prev) => [...prev, response]);
          handleChangeTransactionIds([
            ...(allFiles?.map((file) => file.id) || []),
            response.id,
          ]);
          isSuggested && setSelectedCornerCard(response);
          setIsPaymentActionsOpen(false);
          setIsSupplementaryActionsOpen(false);
          setIsPotentialActionsOpen(false);
          onRefresh();
        },
      }
    );
  };

  const handleUpdateList = (file: Schemas.Document) => {
    setAllFiles((prev) =>
      prev.map((item) => (file.id === item.id ? file : item))
    );
    onRefresh();
  };

  const deleteFile = (id: string): Promise<void> =>
    new Promise((resolve, reject) => {
      modal.confirm({
        title: t('transaction.invoice.messageRemove')(),
        onOk: () =>
          deleteDocument(
            {
              parameter: {
                companyId: companyId!,
                id,
              },
            },
            {
              onSuccess: () => resolve(),
              onError: reject,
            }
          ),
        autoFocusButton: null,
        okButtonProps: {
          size: 'small',
        },
        cancelButtonProps: {
          size: 'small',
        },
      });
    });

  const handleCancelUpload = async (id: string) => {
    handleChangeTransactionIds(
      allFiles?.filter((item) => item.id !== id)?.map((item) => item.id)
    );

    await deleteFile(id);

    setAllFiles((prev) => prev?.filter((item) => item.id !== id));
    onRefresh();
  };

  const handleAttachSupplementaryDocuments = (ids: string[]) => {
    const supplementaryFiles = allFiles
      ?.filter(
        ({ type }) => type === 'income_document' || type === 'expence_document'
      )
      ?.map(({ id }) => id);
    handleChangeTransactionIds(
      Array.from(new Set([...supplementaryFiles, ...ids]))
    );
    onRefresh();
  };

  const handleDetachSupplementaryFile = (file: Schemas.Document) =>
    modal.confirm({
      title: translate('transaction.documents.payment.detachConfirm'),
      onOk: () => {
        handleChangeTransactionIds(
          allFiles?.map((item) => item?.id)?.filter((item) => file.id !== item)
        );
        setAllFiles((prevState) =>
          prevState?.filter(({ id }) => file.id !== id)
        );
      },
      autoFocusButton: null,
      okButtonProps: {
        size: 'small',
      },
      cancelButtonProps: {
        size: 'small',
      },
      width: 460,
    });

  useEffect(() => {
    if (transaction.isFileless) {
      onChangeTransactionFileList?.(id, []);
    } else {
      onChangeTransactionFileList?.(
        id,
        // @ts-expect-error-next-line
        allFiles.map((file) => ({ id: file.id }))
      );
    }
  }, [allFiles, id, transaction.isFileless, onChangeTransactionFileList]);

  const handleAttachMissingFile = () => {
    if (selectedCornerCard) {
      setAllFiles((prev) =>
        prev?.map((item) => (item.id === 'new' ? selectedCornerCard : item))
      );
      handleChangeTransactionIds([
        ...(allFiles
          ?.filter((file) => file.id !== 'new')
          ?.map((file) => file.id) || []),
        selectedCornerCard?.id,
      ]);
      onRefresh();
      setSelectedCornerCard(null);
    }
  };

  const handleUploadMissingFile = (options: UploadRequestOption) => {
    setIsPaymentViewModalOpen(true);
    setIsPaymentActionsOpen(false);
    setIsPotentialActionsOpen(false);
    handleUploadFile(options, false);
  };

  const handleAttachSuggestedFile = (file: Schemas.Document) => {
    setAllFiles((prev) =>
      prev?.map((item) => (item.id === 'new' ? file : item))
    );
    handleChangeTransactionIds([
      ...(allFiles
        ?.filter((file) => file.id !== 'new')
        ?.map((file) => file.id) || []),
      file.id,
    ]);
    onRefresh();
  };

  const handleUploadPaymentDocuments = (options: UploadRequestOption) => {
    setIsPaymentViewModalOpen(true);
    setIsPaymentActionsOpen(false);
    handleUploadFile(options, false);
  };

  const handleDetachPaymentDocument = (file: Schemas.Document) => {
    handleChangeTransactionIds([
      ...(allFiles
        ?.map((item) => item.id)
        .filter((item) => file.id !== item) || ['']),
    ]);
    setAllFiles((prev) => prev?.filter(({ id }) => id !== file.id));
  };

  const handleAddPaymentExistingFile = (file: Schemas.Document) => {
    setAllFiles((prev) =>
      prev?.map((item) => (item.id === 'new' ? file : item))
    );
    handleChangeTransactionIds([
      ...(allFiles
        ?.filter((file) => file.id !== 'new')
        ?.map((file) => file.id) || []),
      file.id,
    ]);
    onRefresh();
  };

  return (
    <S.TransactionContent vertical>
      <PageMeta title={transaction.details} />

      {headerContent}
      {contextHolder}

      <S.Content ismodal={isModal ? 'true' : ''}>
        <S.DetailsContainer ismodal={isModal ? 'true' : ''}>
          <S.FullWidth gap={30} vertical>
            {transaction && (
              <TransactionCardDetails
                icon={
                  transaction?.account?.connection?.bank?.bic &&
                  `/${transaction.account.connection.bank.bic}.png`
                }
                transaction={transaction}
                childTransactionsCount={childTransactionsCount}
                onUpdateTransaction={onUpdateTransaction}
                onRefresh={onRefresh}
              />
            )}

            {!transaction.parentId && (
              <TransactionSplit
                transactionId={id!}
                transactions={transaction.childTransactions || []}
                fullAmount={transaction.amount}
                currencyCode={transaction.currencyCode}
                onChangeChildrenCount={setChildTransactionsCount}
              />
            )}
          </S.FullWidth>
        </S.DetailsContainer>

        <S.FilelessWrapper ismodal={isModal ? 'true' : ''} vertical>
          {transaction.isFileless ? (
            <FilelessBlock onChange={onChangeFileless} />
          ) : (
            <>
              {!paymentData.length ? (
                <MissingPaymentDocuments
                  transaction={transaction}
                  isPotentialActionsOpen={isPotentialActionsOpen}
                  disableUpload={createFileLoading}
                  onAttachExistingDocumentTemplate={
                    handleAttachExistingDocumentTemplate
                  }
                  onOpenPotentialActions={setIsPotentialActionsOpen}
                  selectedCard={selectedCornerCard}
                  onCardClick={(file) => setSelectedCornerCard(file)}
                  onListUpdate={handleUpdateList}
                  onCancelUpload={handleCancelUpload}
                  onRefresh={onRefresh}
                  onAttach={handleAttachMissingFile}
                  onAddExistingFile={handleAttachSuggestedFile}
                  onUploadRequest={handleUploadMissingFile}
                  isModal={isModal}
                />
              ) : (
                <PaymentDocuments
                  transaction={transaction}
                  disableUpload={createFileLoading}
                  disableAttach={
                    amount === paymentData[0]?.documentMetadata?.amount
                  }
                  data={paymentData}
                  isPaymentActionsOpen={isPaymentActionsOpen}
                  onChangePaymentActionsOpen={setIsPaymentActionsOpen}
                  onAttachExistingTemplate={
                    handleAttachExistingDocumentTemplate
                  }
                  onUploadRequest={handleUploadPaymentDocuments}
                  onDetach={handleDetachPaymentDocument}
                  onDelete={handleDelete}
                  onAddExistingFile={handleAddPaymentExistingFile}
                  onListUpdate={handleUpdateList}
                  onCancelUpload={handleCancelUpload}
                  onRefresh={onRefresh}
                />
              )}

              <SupplementaryDocuments
                data={supplementaryData}
                selectedFile={selectedFile}
                attachedFiles={allFiles}
                onChangeSelectedFile={setSelectedFile}
                isSupplementaryActionsOpen={isSupplementaryActionsOpen}
                isSupplementaryAdditionalActionsOpen={
                  isSupplementaryAdditionalActionsOpen
                }
                onChangeSupplementaryActionsOpen={setIsSupplementaryActionsOpen}
                onChangeSupplementaryAdditionalActionsOpen={
                  setIsSupplementaryAdditionalActionsOpen
                }
                onUploadRequest={(options) => handleUploadFile(options, true)}
                disableUpload={createFileLoading}
                isDownloadLoading={isDownloadFileLoading}
                onDetach={(file) => handleDetachSupplementaryFile(file)}
                onDownloadFile={(file) => handleDownloadFile(file)}
                onAttach={handleAttachSupplementaryDocuments}
              />
            </>
          )}
        </S.FilelessWrapper>
      </S.Content>

      {isPaymentViewModalOpen && (
        <TransactionFilePreviewModal
          type={amount > 0 ? 'income' : 'expenses'}
          open={isPaymentViewModalOpen}
          onClose={() => {
            setIsPaymentViewModalOpen(false);
            setSelectedPaymentFile(null);
          }}
          file={selectedPaymentFile!}
          onRefresh={onRefresh}
          onListUpdate={handleUpdateList}
          onCancelUpload={handleCancelUpload}
        />
      )}

      {isPotentialViewModalOpen && (
        <TransactionFilePreviewModal
          type={amount > 0 ? 'income' : 'expenses'}
          open={isPotentialViewModalOpen}
          onClose={() => {
            setIsPotentialViewModalOpen(false);
            setSelectedCornerCard(null);
          }}
          file={selectedCornerCard!}
          onRefresh={onRefresh}
          onListUpdate={handleUpdateList}
          onCancelUpload={handleCancelUpload}
        />
      )}
    </S.TransactionContent>
  );
};

export default TransactionContent;
