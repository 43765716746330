import { Trans } from 'react-i18next';

import { PageEmptyState } from '@entities';
import { Button } from '@ui-kit/Button/Button';

import Instruction1Image from './images/instruction1.svg?react';
import Instruction2Image from './images/instruction2.svg?react';

const features = [
  t('rules.empty.features.transactionsAndDocuments'),
  t('rules.empty.features.automatic'),
  t('rules.empty.features.categoriesAndProjects'),
  t('rules.empty.features.existingData'),
];

const instructions = [
  {
    title: <Trans i18nKey="rules.empty.instructions.1" />,
    image: <Instruction1Image />,
  },
  {
    title: <Trans i18nKey="rules.empty.instructions.2" />,
    image: <Instruction2Image />,
  },
];

const description = [
  t('rules.empty.description.1'),
  t('rules.empty.description.2'),
];

type RulesEmptyProps = {
  onAddRule: () => void;
};

const RulesEmpty = ({ onAddRule }: RulesEmptyProps) => (
  <PageEmptyState
    title={t('rules.empty.title')()}
    description={description}
    features={features}
    action={
      <Button type="primary" onClick={onAddRule}>
        {t('rules.empty.add')()}
      </Button>
    }
    instructions={instructions}
  />
);

export default RulesEmpty;
