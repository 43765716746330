import { type InputNumberProps } from 'antd';
import { FocusEvent, useEffect, useState } from 'react';

import { getCurrencySymbol, inverseNumberValue } from '@utils';

import * as S from './styled';

type InputNumberMoneyProps = InputNumberProps<number> & {
  currencyCode?: string;
  fullAmount?: number;
  onChange: (value: number | null) => void;
};

const InputNumberMoney = ({
  defaultValue,
  currencyCode,
  prefix,
  fullAmount,
  onChange,
  ...rest
}: InputNumberMoneyProps) => {
  const [value, setValue] = useState<number | null>(defaultValue || null);

  useEffect(() => {
    if (defaultValue) {
      setValue(inverseNumberValue(defaultValue, fullAmount));
    }
  }, [defaultValue, fullAmount]);

  const handleChange = (value: number | null) => {
    setValue(value);
    onChange(value);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
    e.preventDefault();
    setValue(inverseNumberValue(e.target.value, fullAmount));
  };

  return (
    <S.InputNumberMoney
      defaultValue={defaultValue}
      value={value}
      prefix={currencyCode ? getCurrencySymbol(currencyCode) : prefix}
      controls={false}
      decimalSeparator=","
      precision={2}
      size="large"
      onChange={handleChange}
      onBlur={handleBlur}
      {...rest}
    />
  );
};

export default InputNumberMoney;
