import { Params$AuthController_resetPassword } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useAuthUpdatePassword = () => useApiClientMutation<
    Params$AuthController_resetPassword,
    Schemas.UpdatePasswordDto
  >({
    method: 'AuthController_resetPassword',
  });

export default useAuthUpdatePassword;
