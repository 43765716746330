import { Button, Flex, Typography } from 'antd';
import { useState } from 'react';

import {
  OnboardingRegisterCompany,
  OnboardingSwitchToAccounting,
} from '@assets';
import { OnboardingCardHeader, OnboardingRadioControl } from '@entities';
import { useOnboarding } from '@hooks';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingWelcome = () => {
  const { updateScreen, updateIncorporation } = useOnboarding();

  const [incorporationType, setIncorporationType] = useState('incorporation');

  const handleNext = () => {
    updateIncorporation({
      incorporationType,
    });

    updateScreen('INCORPORATION', { active: true });
  };

  return (
    <S.Container>
      <S.Inner>
        <OnboardingCardHeader
          title={t('onboarding.welcome.title')()}
          description={
            <Paragraph>{t('onboarding.welcome.description')()}</Paragraph>
          }
        />

        <OnboardingRadioControl
          value={incorporationType}
          setValue={setIncorporationType}
          controls={[
            {
              value: 'incorporation',
              title: t('onboarding.welcome.register.title')(),
              content: t('onboarding.welcome.register.description')(),
              rightContent: <OnboardingRegisterCompany />,
            },
            {
              value: 'accounting',
              title: t('onboarding.welcome.switch.title')(),
              content: t('onboarding.welcome.switch.description')(),
              rightContent: <OnboardingSwitchToAccounting />,
            },
          ]}
        />
      </S.Inner>

      <Flex justify="flex-end">
        <Button type="primary" size="large" onClick={handleNext}>
          {t('onboarding.buttonContinue')()}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingWelcome;
