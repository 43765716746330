import { FC } from 'react';

import { Select, type SelectProps } from '@components';
import { useAccount, useTranslate } from '@hooks';
import { useGetContactsList } from '@hooks-api';
import { selectFilterOption } from '@utils';

type FilterContactsProps = {
  placeholder?: string;
} & SelectProps;

const FilterContacts: FC<FilterContactsProps> = ({ placeholder, ...rest }) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const { data: contacts } = useGetContactsList({
    params: {
      companyId: companyId!,
    },
  });

  return (
    <Select
      placeholder={placeholder || translate('documentsPage.dropdown.contact')}
      options={contacts?.records.map((contact) => ({
        label: contact.name,
        value: contact.id,
      }))}
      size="large"
      showSearch
      filterOption={selectFilterOption}
      {...rest}
    />
  );
};

export default FilterContacts;
