import { FC } from 'react';

import { IconFileCheck } from '@assets';
import { PaymentDocumentUnit } from '@constants';

import * as S from './styled';

type PaymentDocumentNoInvoicesProps = {
  type: PaymentDocumentUnit;
  onDelete?: () => void;
};

const getTranslatesByType = (type: PaymentDocumentUnit) =>
  type === 'income'
    ? {
        title: t('income.noInvoices.title')(),
        description: t('income.noInvoices.description')(),
      }
    : {
        title: t('expenses.noInvoices.title')(),
        description: t('expenses.noInvoices.description')(),
      };

const PaymentDocumentNoInvoices: FC<PaymentDocumentNoInvoicesProps> = ({
  type,
}) => {
  const translates = getTranslatesByType(type);

  return (
    <S.Container>
      <S.Icon>
        <IconFileCheck width={32} height={32} />
      </S.Icon>

      <S.Title>{translates.title}</S.Title>
      <S.Description>{translates.description}</S.Description>
    </S.Container>
  );
};

export default PaymentDocumentNoInvoices;
