import type { DefaultOptionType } from 'antd/es/select';

export const filterOptionCountries = (
  input: string,
  option?: DefaultOptionType
) => {
  if (typeof option?.name === 'string') {
    return (option?.name.toLowerCase() ?? '').includes(
      input.toLocaleLowerCase()
    );
  }
  return false;
};
