import { Flex, Table, type TableColumnType, Typography } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDocumentController_findAll } from '@api-client/generated/DocumentController/findAll';
import { Schemas } from '@api-client/generated/types';
import { IconPlus } from '@assets';
import {
  DateText,
  FilterConstructor,
  type FilterControl,
  PageMeta,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';
import { LocalePaths } from '@locales';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type DocumentFile = Schemas.Document;

const { Title } = Typography;

const controls: Record<'left' | 'right', FilterControl[]> = {
  left: [
    {
      type: 'list-types',
      formName: 'type',
    },
    {
      type: 'search',
      formName: 'term',
    },
  ],
  right: [
    {
      type: 'range-picker',
      formName: 'date',
    },
    {
      type: 'module-columns-config',
    },
    {
      type: 'module-filter',
      controls: [
        {
          type: 'list-contacts',
          formName: 'contactIds',
        },
      ],
    },
  ],
};

const Documents: FC = () => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const [filterParamsReady, setFilterParamsReady] = useState(false);
  const [filterOptions, setFilterOptions] = useState<Record<string, unknown>>(
    {}
  );

  const [tableColumns, setTableColumns] = useState<
    TableColumnType<DocumentFile>[]
  >([]);

  const { metadata, incrementPage, hasNextPage, plainData, appendData } =
    usePagination<DocumentFile>(JSON.stringify(filterOptions));

  const {
    isFetching: isLoading,
    refetch,
    data,
  } = useDocumentController_findAll({
    params: {
      companyId: companyId!,
      page: metadata.currentPage,
      ...filterOptions,
    },
    config: {
      enabled: filterParamsReady,
    },
  });

  useEffect(() => {
    if (data) {
      appendData(data);
    }
  }, [appendData, data]);

  const { sentryRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  const columns: TableColumnType<DocumentFile>[] = useMemo(
    () => [
      {
        key: 'name',
        dataIndex: 'name',
        title: translate('documentsPage.table.name'),
        width: 250,
        render: (name) => name || translate('documentsPage.table.noName'),
        ellipsis: true,
      },
      {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: translate('documentsPage.table.date'),
        width: 150,
        render: (date) => <DateText date={date} format="date" />,
      },
      {
        key: 'type',
        dataIndex: 'type',
        title: translate('documentsPage.table.type'),
        width: 250,
        render: (type) =>
          type && translate(`documentsPage.types.${type}` as LocalePaths),
        ellipsis: true,
      },
      {
        key: 'contract',
        dataIndex: 'contact',
        title: translate('documentsPage.table.contact'),
        width: 250,
        render: (contact) => contact && contact.name,
        ellipsis: true,
      },
    ],
    [translate]
  );

  return (
    <Flex gap={24} vertical>
      <PageMeta title={translate('documentsPage.breadcrumbs.documents')} />

      <Title>{translate('documentsPage.breadcrumbs.documents')}</Title>

      <FilterConstructor<DocumentFile>
        columns={columns}
        controls={controls}
        actions={
          <Button
            type="primary"
            size="small"
            icon={<IconPlus />}
            onClick={() => navigate('/documents/new')}
          >
            {translate('documentsPage.addOne')}
          </Button>
        }
        onChange={setFilterOptions}
        onChangeColumns={setTableColumns}
        onRequest={refetch}
        onReady={setFilterParamsReady}
        withSearchParams
      />

      <S.TableContainer>
        <Table
          rowKey={({ id }) => id}
          dataSource={plainData}
          columns={tableColumns}
          loading={isLoading}
          pagination={false}
          scroll={{ x: 720 }}
          onRow={(record) => ({
            onClick: () => {
              navigate(record.id);
            },
          })}
        />
        {hasNextPage && <div ref={sentryRef} />}
      </S.TableContainer>
    </Flex>
  );
};

export default Documents;
