import {
  Badge,
  Button,
  type ButtonProps,
  Drawer,
  type DrawerProps,
  Flex,
  Form,
} from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { IconClose, IconFilter } from '@assets';
import { useTranslate } from '@hooks';

import FilterConstructorControls, {
  type FilterControl,
} from './FilterConstructorControls';
import * as S from './styled';

type FilterValues = Record<string, unknown>;

type FilterConstructorDrawerProps = {
  initialParams?: FilterValues;
  controls: FilterControl[];
  buttonParams?: ButtonProps;
  onSubmit?: (values: FilterValues | string[] | null) => void;
} & DrawerProps;

const FilterConstructorDrawer: React.FC<FilterConstructorDrawerProps> = ({
  initialParams,
  controls,
  buttonParams,
  onSubmit,
  ...rest
}) => {
  const { translate } = useTranslate();

  const [form] = Form.useForm();

  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState<FilterValues | null>(null);

  const getControlsName = useCallback(
    () => controls.map((control) => control.formName || ''),
    [controls]
  );

  useEffect(() => {
    const names = getControlsName();

    if (_.intersection(names, _.keys(initialParams)).length) {
      setFormValues(initialParams || null);
    }

    controls.map((control) => {
      if (initialParams) {
        if (control?.isBoolean) {
          const value =
            initialParams[control.formName!] == 'true' ||
            (typeof initialParams[control.formName!] === 'boolean' &&
              initialParams[control.formName!] == true);

          setFormValues((prev) => ({ ...prev, [control.formName!]: value }));
          form.setFieldsValue({ ...initialParams, [control.formName!]: value });
        } else {
          setFormValues(initialParams);
          form.setFieldsValue(initialParams);
        }
      }
    });
  }, [controls, form, getControlsName, initialParams]);

  const handleSubmit = (values: FilterValues) => {
    setIsOpen(false);

    onSubmit?.(values);
    setFormValues(values);
  };

  const handleReset = () => {
    form.resetFields();

    onSubmit?.({
      IS_RESET: true,
    });
    setFormValues(null);
    setIsOpen(false);
  };

  function hasFilters(obj: FilterValues | null) {
    if (_.isEmpty(obj)) {
      return false;
    }

    return _.some(
      obj,
      (value) =>
        !_.isNil(value) &&
        value !== false &&
        value !== 0 &&
        (!_.isArray(value) || value.length > 0) &&
        (!_.isObject(value) || _.keys(value).length > 0)
    );
  }

  return (
    <>
      <Drawer
        width={451}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        closable={false}
        {...rest}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
        >
          <S.DrawerContainer>
            <Flex gap={24} vertical>
              <Flex justify="space-between" align="center">
                <S.DrawerTitle>{translate('filter.title')}</S.DrawerTitle>

                <Button
                  type="text"
                  icon={<IconClose />}
                  size="small"
                  onClick={() => setIsOpen(false)}
                />
              </Flex>

              <Flex gap={24} vertical>
                {controls.map((control) => (
                  <FilterConstructorControls
                    key={`${control.type}-${control?.formName}`}
                    {...control}
                  />
                ))}
              </Flex>
            </Flex>
          </S.DrawerContainer>

          <Flex gap={10}>
            <Button type="default" onClick={handleReset} block>
              {translate('filter.buttonReset')}
            </Button>

            <Button type="primary" htmlType="submit" block>
              {translate('filter.buttonApply')}
            </Button>
          </Flex>
        </Form>
      </Drawer>

      <Badge dot={hasFilters(formValues)}>
        <Button
          icon={<IconFilter width={24} />}
          color="primary"
          variant="filled"
          size="small"
          onClick={() => setIsOpen(true)}
          {...buttonParams}
        />
      </Badge>
    </>
  );
};

export default FilterConstructorDrawer;
