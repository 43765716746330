//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Response$UserController_getCurrentUser$Status$200,
  ResponseContentType$UserController_getCurrentUser,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useUserController_getCurrentUser = (options?: {
  config?: QueryOptions<
    Response$UserController_getCurrentUser$Status$200[ResponseContentType$UserController_getCurrentUser]
  >;
}) =>
  useApiClient<
    never,
    Response$UserController_getCurrentUser$Status$200[ResponseContentType$UserController_getCurrentUser]
  >({
    method: 'UserController_getCurrentUser',
    config: options?.config,
  });
