import { Flex, Form } from 'antd';
import { rgba } from 'emotion-rgba';

import styled from '@emotion/styled';

const { Item } = Form;

export const BlockTitle = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const CompanyDetailsContainer = styled(Flex)`
  max-width: 855px;
  height: 100%;
  flex-direction: column;
  padding: 30px;
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.bgGrey};
`;

export const LargeFormItem = styled(Item)`
  && > .ui-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  && > .ui-row > .ui-col {
    text-align: start;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FlexInner = styled(Flex)`
  margin: 30px 0;
`;

export const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
`;

export const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  height: 100%;
  width: 100%;
  z-index: 2;
`;
