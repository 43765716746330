import { Flex, Menu, type MenuProps } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  IconAccounts,
  IconBilling,
  IconBudgets,
  IconCategories,
  IconCompany,
  IconContacts,
  IconDocuments,
  IconExpenses,
  IconIncome,
  IconIncorporation,
  IconLegalEmpty,
  IconPayroll,
  IconProjects,
  IconReport,
  IconRules,
  IconSettings,
  IconTaxes,
  IconTeam,
  IconTransactions,
  IconUser,
} from '@assets';
import { useAccount } from '@hooks';
import { layout } from '@theme';
import { getOriginalPathname } from '@utils';

import { ChatIcon } from './ChatIcon';
import * as S from './styled';

type MenuItem = Required<MenuProps>['items'][number];

export const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isLogged, company, companies, userAccess } = useAccount();

  const originalPathname = getOriginalPathname(pathname);

  const isOnboarding =
    pathname === '/start' || company?.status !== 'in_service';

  const [topActiveKey, setTopActiveKey] = useState(originalPathname);
  const [bottomActiveKey, setBottomActiveKey] = useState(originalPathname);

  useEffect(() => {
    if (isLogged && pathname === '/') {
      if (
        companies
          .map((company) => company.status)
          .every((status) => status === 'incorporating')
      ) {
        navigate('/start');
      } else {
        navigate(userAccess?.transactions ? '/transactions' : '/documents');
      }
    }
  }, [companies, isLogged, navigate, pathname, userAccess?.transactions]);

  const menuTop: MenuItem[] = [
    ...(isOnboarding
      ? [
          {
            key: '/start',
            label: t('common.navigation.incorporation')(),
            icon: <IconIncorporation />,
            disabled: !isOnboarding,
          },
        ]
      : []),
    ...(userAccess?.transactions
      ? [
          {
            key: '/transactions',
            label: t('common.navigation.transactions')(),
            icon: <IconTransactions />,
            disabled: isOnboarding,
          },
        ]
      : []),
    ...(userAccess?.incomesExpences
      ? [
          {
            key: '/income',
            label: t('common.navigation.income')(),
            icon: <IconIncome />,
            disabled: isOnboarding,
          },
          {
            key: '/expenses',
            label: t('common.navigation.expenses')(),
            icon: <IconExpenses />,
            disabled: isOnboarding,
          },
        ]
      : []),
    ...(userAccess?.reports
      ? [
          {
            key: '/report',
            label: t('common.navigation.reports')(),
            icon: <IconReport />,
            disabled: isOnboarding,
          },
          {
            key: '/budgets',
            label: t('common.navigation.budgets')(),
            icon: <IconBudgets />,
            disabled: true,
          },
        ]
      : []),
    ...(userAccess?.companyData
      ? [
          {
            key: '/taxes',
            label: t('common.navigation.taxes')(),
            icon: <IconTaxes />,
            disabled: true,
          },
        ]
      : []),
    ...(userAccess?.payroll
      ? [
          {
            key: '/payroll',
            label: t('common.navigation.payroll')(),
            icon: <IconPayroll />,
            disabled: true,
          },
        ]
      : []),
    {
      key: '/chat',
      label: t('common.navigation.tasks')(),
      icon: <ChatIcon />,
    },
    ...(userAccess?.incomesExpences ||
    userAccess?.transactions ||
    userAccess?.incomesExpences ||
    userAccess?.companyData
      ? [
          {
            key: '/company',
            label: t('common.navigation.company')(),
            icon: <IconCompany />,
            disabled: isOnboarding,
            children: [
              ...(userAccess?.incomesExpences
                ? [
                    {
                      key: '/documents',
                      label: t('common.navigation.documents')(),
                      icon: <IconDocuments />,
                      disabled: isOnboarding,
                    },
                  ]
                : []),
              ...(userAccess?.incomesExpences || userAccess?.transactions
                ? [
                    {
                      key: '/contacts',
                      label: t('common.navigation.contacts')(),
                      icon: <IconContacts />,
                    },
                    {
                      key: '/categories',
                      label: t('common.navigation.categories')(),
                      icon: <IconCategories />,
                    },
                  ]
                : []),
              ...(userAccess?.transactions
                ? [
                    {
                      key: '/accounts',
                      label: t('common.navigation.accounts')(),
                      icon: <IconAccounts />,
                    },
                    {
                      key: '/projects',
                      label: t('common.navigation.projects')(),
                      icon: <IconProjects />,
                    },
                    {
                      key: '/rules',
                      label: t('common.navigation.rules')(),
                      icon: <IconRules />,
                    },
                  ]
                : []),
              ...(userAccess?.companyData
                ? [
                    {
                      key: '/legal',
                      label: t('common.navigation.legal')(),
                      icon: <IconLegalEmpty />,
                      disabled: false,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ];

  const menuBottom: MenuItem[] = [
    {
      key: '/settings',
      label: t('common.navigation.settings')(),
      icon: <IconSettings />,
      disabled: isOnboarding,
      children: [
        {
          key: '/settings/personal',
          label: t('common.navigation.personal')(),
          icon: <IconUser />,
        },
        ...(userAccess?.companyData
          ? [
              {
                key: '/settings/company',
                label: t('common.navigation.company')(),
                icon: <IconCompany />,
              },
              {
                key: '/settings/billing',
                label: t('common.navigation.billing')(),
                icon: <IconBilling />,
              },
            ]
          : []),
        ...(userAccess?.userManagement
          ? [
              {
                key: '/settings/team-members',
                label: t('common.navigation.team')(),
                icon: <IconTeam />,
              },
            ]
          : []),
      ],
    },
  ];

  const handleMenuTopAction = (key: string) => {
    setTopActiveKey(key);
    setBottomActiveKey('');

    navigate(key);
  };

  const handleMenuBottomAction = (key: string) => {
    setTopActiveKey('');
    setBottomActiveKey(key);

    navigate(key);
  };

  return (
    <S.Sidebar width={layout.sidebarWidth}>
      <Flex vertical>
        <Menu
          theme="dark"
          onClick={({ key }) => handleMenuTopAction(key)}
          mode="vertical"
          items={menuTop}
          selectedKeys={[topActiveKey]}
        />
      </Flex>

      <Menu
        theme="dark"
        onClick={({ key }) => handleMenuBottomAction(key)}
        mode="vertical"
        items={menuBottom}
        selectedKeys={[bottomActiveKey]}
      />
    </S.Sidebar>
  );
};
