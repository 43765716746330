import { Button, Flex, Spin, Typography, Upload } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC, Fragment, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Schemas } from '@api-client/generated/types';
import { IconDownload, IconRefresh, IconUpload } from '@assets';
import { OnboardingCardHeader, OnboardingViewDocumentModal } from '@entities';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useCreateCompanyFile, useUpdateCompanyFileById } from '@hooks-api';
import { downloadFile } from '@utils';

import * as S from './styled';

const { Paragraph } = Typography;

const HONOR_DECLARATION = 'honor_declaration';

const OnboardingDeclarationOfHonor: FC = () => {
  const { translate } = useTranslate();

  const { companyId } = useAccount();

  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [selectedViewFile, setSelectedViewFile] =
    useState<Schemas.CompanyFile | null>(null);

  const { mutate: createCompanyFile, isPending: loadingCreate } =
    useCreateCompanyFile();
  const { mutate: updateCompanyFile, isPending: loadingUpdate } =
    useUpdateCompanyFileById();

  const { incorporationDetails, updateScreen, updateIncorporation } =
    useOnboarding();

  const handleUploadFile = (options: UploadRequestOption) => {
    const formData = new FormData();

    formData.append('file', options.file);
    if (typeof options.file === 'object' && 'name' in options.file) {
      formData.append('name', options.file.name);
    }
    formData.append('personId', 'company');
    formData.append('type', 'honor_declaration');

    createCompanyFile(
      {
        parameter: {
          companyId: companyId!,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        requestBody: formData as any,
      },
      {
        onSuccess: (response) =>
          updateIncorporation({
            files: {
              honor_declaration: [response],
            },
          }),
      }
    );
  };

  const handleUpdate = (options: UploadRequestOption, id: string) => {
    const formData = new FormData();

    formData.append('file', options.file);
    if (typeof options.file === 'object' && 'name' in options.file) {
      formData.append('name', options.file.name);
    }

    updateCompanyFile(
      {
        parameter: {
          companyId: companyId!,
          id,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        requestBody: formData as any,
      },
      {
        onSuccess: (response) => {
          const files = incorporationDetails?.files;

          if (files) {
            files[HONOR_DECLARATION] = files[HONOR_DECLARATION]?.map(
              (file: Schemas.CompanyFile) =>
                file.id === response.id ? response : file
            );
          }

          updateIncorporation({ files });
        },
      }
    );
  };

  const handleContinue = () => {
    updateScreen('MESSAGE_PROCESS_SARL_S_RCS', { active: true });
  };

  const handleDownload = () =>
    downloadFile(
      'https://guichet.public.lu/dam-assets/catalogue-formulaires/creation-societe/declaration-honneur-sarls/declaration-honneur-capital-sarls_EN.docx',
      'declaration of honor'
    );

  return (
    <S.Container>
      <OnboardingViewDocumentModal
        open={isOpenViewModal}
        type="company"
        step="honourDeclaration"
        file={selectedViewFile}
        onCancel={() => {
          setIsOpenViewModal(false);
          setSelectedViewFile(null);
        }}
      />

      <S.Inner>
        <OnboardingCardHeader
          title={translate('onboarding.declarationOfHonor.title')}
          description={
            <>
              <Paragraph>
                {translate('onboarding.declarationOfHonor.description')}
              </Paragraph>
            </>
          }
        />

        <Flex gap={20}>
          <S.Step span={12}>
            <Flex gap={12} vertical>
              <Flex gap={8} align="center">
                <S.StepCount>1</S.StepCount>
                <S.StepText>
                  {translate('onboarding.declarationOfHonor.downloadStepText')}
                </S.StepText>
              </Flex>
              <S.DownloadInput
                value={translate(
                  'onboarding.declarationOfHonor.downloadFileName'
                )}
                type="download"
                suffix={<IconDownload />}
                onClick={handleDownload}
                readOnly
              />
            </Flex>
          </S.Step>

          <S.Step span={12}>
            <Flex gap={12} vertical>
              <Flex gap={8} align="center">
                <S.StepCount>2</S.StepCount>
                <S.StepText>
                  {translate('onboarding.declarationOfHonor.uploadStepText')}
                </S.StepText>
              </Flex>

              <S.UploadFileCol span={24}>
                {incorporationDetails?.files?.[HONOR_DECLARATION]?.length &&
                  (loadingUpdate ? (
                    <S.Uploading>
                      <Spin indicator={<LoadingOutlined spin />} />
                      {translate('onboarding.kyc.uploading')}
                    </S.Uploading>
                  ) : (
                    <Flex gap={12} vertical>
                      <S.FileList>
                        {incorporationDetails?.files[HONOR_DECLARATION].map(
                          (file: Schemas.CompanyFile) => (
                            <Fragment key={file.id}>
                              <S.FileItem>
                                <S.FileName
                                  onClick={() => {
                                    setIsOpenViewModal(true);
                                    setSelectedViewFile(file);
                                  }}
                                >
                                  {file.fileName || file.id}
                                </S.FileName>

                                <Flex align="center" gap={8}>
                                  <Upload
                                    customRequest={(opts) =>
                                      handleUpdate(opts, file.id)
                                    }
                                    showUploadList={false}
                                  >
                                    <Button
                                      type="text"
                                      icon={<IconRefresh />}
                                      size="small"
                                    />
                                  </Upload>
                                </Flex>
                              </S.FileItem>
                            </Fragment>
                          )
                        )}
                      </S.FileList>
                    </Flex>
                  ))}

                {!incorporationDetails?.files?.[HONOR_DECLARATION]?.length &&
                  (loadingCreate ? (
                    <S.Uploading>
                      <Spin indicator={<LoadingOutlined spin />} />
                      {translate('onboarding.kyc.uploading')}
                    </S.Uploading>
                  ) : (
                    <S.UploadFile
                      customRequest={handleUploadFile}
                      showUploadList={false}
                    >
                      <S.UploadContent>
                        <S.UploadInner>
                          <IconUpload />
                          {translate(
                            'onboarding.declarationOfHonor.uploadPlaceholder'
                          )}
                        </S.UploadInner>
                        <S.UploadButton type="default">
                          {translate('onboarding.uploadAddressForm.selectFile')}
                        </S.UploadButton>
                      </S.UploadContent>
                    </S.UploadFile>
                  ))}
              </S.UploadFileCol>
            </Flex>
          </S.Step>
        </Flex>
      </S.Inner>

      <Flex justify="flex-end">
        <Button
          type="primary"
          disabled={!incorporationDetails?.files?.[HONOR_DECLARATION]}
          onClick={handleContinue}
        >
          {translate('onboarding.buttonNext')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingDeclarationOfHonor;
