import { Params$ContactsController_updateOneById } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';
import { Schemas } from '@api-client/generated/types';

type Params = Params$ContactsController_updateOneById;

const useUpdateContactById = () => useApiClientMutation<Params, Schemas.Contact>({
    method: 'ContactsController_updateOneById',
  });

export default useUpdateContactById;
