import { Flex } from 'antd';
import { FC, PropsWithChildren, ReactNode } from 'react';

import * as S from './styled';

type CardProps = {
  title?: ReactNode;
  description?: ReactNode;
  className?: string;
  padding?: number;
  containerId?: string;
  minHeight?: number;
  minHeightSuffix?: 'vh' | 'px' | '%';
  isGrey?: boolean;
  noShadow?: boolean;
};

const Card: FC<PropsWithChildren<CardProps>> = ({
  title,
  description,
  children,
  padding,
  minHeight,
  className,
  containerId = undefined,
  minHeightSuffix = 'px',
  isGrey,
  noShadow,
}) => (
  <S.Container
    className={className}
    padding={padding}
    id={containerId}
    minHeight={minHeight}
    minHeightSuffix={minHeightSuffix}
    isGrey={isGrey}
    noShadow={noShadow}
  >
    <Flex gap={20} vertical>
      <Flex gap={4} vertical>
        {title && <S.Title>{title}</S.Title>}
        {description && <S.Description>{description}</S.Description>}
      </Flex>
      <div>{children}</div>
    </Flex>
  </S.Container>
);

export default Card;
