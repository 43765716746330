import { Schemas } from "@api-client/generated/types";

type PersonDto = Schemas.PersonDto;
type Person = Schemas.Person;

export const hasDirectorAndAuthorized = (people: Partial<PersonDto | Person>[]): boolean => {
  const shareholders = people[0].people;

  if (shareholders) {
    const isDirector = shareholders.filter((item) => item && item.isDirector);
    const isSigner = shareholders.filter((item) => item && item.isSigner);

    return isDirector.length > 0 && isSigner.length > 0;
  } else {
    return false;
  }
}