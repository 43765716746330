import { Button, Flex, Typography } from 'antd';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { MessageProcessOnlineKyc } from '@assets';
import { OnboardingMessage } from '@entities';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

import * as S from './styled';

const { Paragraph, Text } = Typography;

const OnboardingMessageProcessOnline: FC = () => {
  const { translate } = useTranslate();
  const { updateScreen, updateIncorporation, updateStep } = useOnboarding();
  const { mutate: update, isPending: loading } = useUpdateIncorporation();
  const { companyId } = useAccount();

  const handleContinue = () => {
    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          group: 'online-kyc',
        },
      },
      {
        onSuccess: () => {
          updateIncorporation({ group: 'online-kyc' });
          updateStep('SHAREHOLDERS', { status: 'completed' });
          updateStep('ONLINE_KYC', { active: true });
          updateScreen('INCORPORATION', { active: true });
        },
      }
    );
  };

  return (
    <S.Container>
      <OnboardingMessage
        picture={<MessageProcessOnlineKyc />}
        title={translate('onboarding.messages.processOnlineKyc.title')}
        description={
          <Trans
            i18nKey={translate(
              'onboarding.messages.processOnlineKyc.description'
            )}
            components={[<Paragraph />, <Text strong />]}
          />
        }
      />

      <Flex justify="flex-end">
        <Button type="primary" onClick={handleContinue} loading={loading}>
          {translate('onboarding.buttonContinue')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingMessageProcessOnline;
