//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$FileMappingController_create,
  Response$FileMappingController_create$Status$201,
  ResponseContentType$FileMappingController_create,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useFileMappingController_create = () =>
  useApiClientMutation<
    Params$FileMappingController_create,
    Response$FileMappingController_create$Status$201[ResponseContentType$FileMappingController_create]
  >({ method: 'FileMappingController_create' });
