import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { LocalePaths } from '@locales';

/** @deprecated use global `t(key)()` instead */
const useTranslate = () => {
  const { t } = useTranslation();

  /** @deprecated use global `t(key)()` instead */
  const translate = useCallback(
    (key: LocalePaths, options = {}) => t(key, options),
    [t]
  );

  return { translate };
};

export default useTranslate;
