import { Dropdown, Flex, Upload } from 'antd';
import type { MenuProps } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccountsController_reconnectById } from '@api-client/generated/AccountsController/reconnectById';
import { Schemas } from '@api-client/generated/types';
import { BankDefaultLogotype, IconDots } from '@assets';
import { Loader } from '@components';
import { AccountStatusDescription, DateText, StatusTag } from '@entities';
import { useAccount } from '@hooks';
import { useCreateFileMappings } from '@hooks-api';

import * as S from './styled';

type Account = Schemas.Account;
type Balance = Schemas.Balance;

type AccountItemProps = {
  logo: string;
  account: Account;
  onEdit: (account: Account) => void;
  onDisconnect: (account: Account) => void;
  onImportModalOpen: (value: boolean) => void;
  onAccountIdChange: (id: string) => void;
};

const AccountItem: FC<AccountItemProps> = (props: AccountItemProps) => {
  const {
    logo,
    account,
    onEdit,
    onDisconnect,
    onImportModalOpen,
    onAccountIdChange,
  } = props;
  const navigate = useNavigate();
  const { companyId, userAccess } = useAccount();

  const { mutate: createFileMappings, isPending: createFileLoading } =
    useCreateFileMappings();

  const { mutate: reconnectAccount, isPending: reconnectAccountLoading } =
    useAccountsController_reconnectById();

  const [isErrorLogo, setIsErrorLogo] = useState(false);

  const isNotConnectedAccount = !account.connection;
  const balance =
    account.balances.find(
      (balance: Balance) => balance.balanceType === 'calculated'
    ) || account.balances[0];

  const actionEdit = {
    key: 'edit',
    label: t('accounts.menu.edit')(),
    onClick: () => onEdit(account),
  };

  const actionDisconnect = {
    key: 'disconnect',
    label: t('accounts.menu.disconnect')(),
    danger: true,
    onClick: () => onDisconnect(account),
  };

  const menu: MenuProps['items'] = isNotConnectedAccount
    ? [
        actionEdit,
        {
          key: 'imports',
          label: t('accounts.menu.manageImports')(),
          onClick: () => navigate(`/csv-imports/${account.id}`),
        },
        actionDisconnect,
      ]
    : [actionEdit, actionDisconnect];

  const handleFileAttach = (options: UploadRequestOption) => {
    onAccountIdChange(account.id);
    createFileMappings(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          accountId: account.id,
          mapping: {
            topRowsSkipAmount: 0,
            bottomRowsSkipAmount: 0,
          } as Schemas.CSVMapping,
          file: options.file as string,
        },
      },
      {
        onSuccess: () => {
          if (isNotConnectedAccount) {
            navigate(`/transactions-import/${account.id}`);
          } else {
            onImportModalOpen(true);
          }
        },
      }
    );
  };

  const handleReconnect = () => {
    reconnectAccount(
      {
        parameter: {
          companyId: companyId!,
          id: account.id,
        },
      },
      {
        onSuccess: (response) => {
          window.open(response.url!, '_self');
        },
      }
    );
  };

  return (
    <S.Wrapper gap={24} align="flex-start" justify="space-between" vertical>
      <S.Container align="center" justify="space-between" gap={16}>
        <Flex gap={8} align="center" justify="flex-start">
          <S.Logo
            src={isErrorLogo ? BankDefaultLogotype : logo}
            alt={account.accountName}
            onError={() => setIsErrorLogo(true)}
          />

          <S.Name className="accountName">
            {account.accountName || account.accountNumber}
          </S.Name>
        </Flex>

        <Dropdown menu={{ items: menu }}>
          <S.Edit>
            <IconDots />
          </S.Edit>
        </Dropdown>
      </S.Container>

      <S.Container vertical>
        <S.Header gap={4} vertical>
          <S.Amount
            amount={balance?.balanceAmount.amount}
            currencyCode={balance?.balanceAmount.currency}
          />

          {account.lastSyncedAt && (
            <S.Cell>
              <span>{t('accounts.syncedOn')()}</span>
              <DateText date={account.lastSyncedAt} format="date" />
            </S.Cell>
          )}
        </S.Header>

        <Flex gap={12} vertical>
          <Flex justify="space-between" align="center">
            <StatusTag status={account.status ? account.status : 'manually'} />
            {account.status !== 'disconnected' && userAccess?.transactions && (
              <Upload customRequest={handleFileAttach} showUploadList={false}>
                {createFileLoading ? (
                  <Loader />
                ) : (
                  <S.Action>{t('accounts.import.importCsv')()}</S.Action>
                )}
              </Upload>
            )}

            {account.status === 'disconnected' && (
              <S.ConnectBlock>
                {reconnectAccountLoading ? (
                  <Loader />
                ) : (
                  <S.Action onClick={handleReconnect}>
                    {t('accounts.reconnect')()}
                  </S.Action>
                )}
              </S.ConnectBlock>
            )}
          </Flex>
          <Flex gap={8} align="center">
            <AccountStatusDescription
              status={account.status}
              connectedTill={account.connectedTill}
            />
          </Flex>
        </Flex>
      </S.Container>
    </S.Wrapper>
  );
};

export default AccountItem;
