import { Params$CompanyInviteController_delete } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';
import { Schemas } from '@api-client/generated/types';

const useDeleteCompanyInvite = () => useApiClientMutation<
    Params$CompanyInviteController_delete,
    Schemas.CompanyInvite
  >({
    method: 'CompanyInviteController_delete',
  });

export default useDeleteCompanyInvite;
