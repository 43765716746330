import { Flex, Popover, Upload } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconClip, IconDown, IconUp, IconUpload } from '@assets';
import { CornerCard } from '@entities';
import { useTranslate } from '@hooks';

import * as S from './styled';

type File = Schemas.Document;

type PaymentDocumentsProps = {
  data: File[];
  transaction: Schemas.Transaction;
  isPaymentActionsOpen: boolean;
  disableUpload: boolean;
  disableAttach: boolean;
  onChangePaymentActionsOpen: (value: boolean) => void;
  onAttachExistingTemplate: (value: boolean) => void;
  onUploadRequest: (options: UploadRequestOption) => void;
  onDetach: (file: File) => void;
  onRefresh: () => void;
  onDelete: () => void;
  onAddExistingFile: (file: File) => void;
  onListUpdate: (file: File) => void;
  onCancelUpload: (id: string) => void;
};

const PaymentDocuments: FC<PaymentDocumentsProps> = ({
  onChangePaymentActionsOpen,
  isPaymentActionsOpen,
  disableAttach,
  disableUpload,
  onAttachExistingTemplate,
  onCancelUpload,
  onAddExistingFile,
  onRefresh,
  onListUpdate,
  onUploadRequest,
  onDelete,
  transaction,
  onDetach,
  data = [],
}) => {
  const { translate } = useTranslate();
  return (
    <S.PaymentDocuments vertical gap={14}>
      <Flex justify="space-between" align="flex-start">
        <S.BlockTitle>
          {translate('transaction.documents.payment.paymentDocuments')}
        </S.BlockTitle>
        <Popover
          visible={isPaymentActionsOpen}
          onVisibleChange={onChangePaymentActionsOpen}
          trigger="click"
          placement="bottomRight"
          arrow={false}
          overlayClassName="ui-popover-legal-actions"
          title={
            <S.Actions>
              <S.AttachNew onClick={() => onAttachExistingTemplate(false)}>
                <IconClip />
                <span>
                  {translate('transaction.documents.attachExistingDocument')}
                </span>
              </S.AttachNew>

              <S.UploadWrapper>
                <Upload
                  customRequest={onUploadRequest}
                  showUploadList={false}
                  disabled={disableUpload}
                >
                  <S.UploadExisting>
                    <IconUpload />
                    <span>
                      {translate('transaction.documents.uploadNewDocument')}
                    </span>
                  </S.UploadExisting>
                </Upload>
              </S.UploadWrapper>
            </S.Actions>
          }
        >
          <S.AttachButton type="link" disabled={disableAttach}>
            {translate('transaction.documents.attach')}
            {isPaymentActionsOpen ? <IconUp /> : <IconDown />}
          </S.AttachButton>
        </Popover>
      </Flex>

      {data.map((file) => (
        <CornerCard
          type="hovering"
          transaction={transaction}
          file={file}
          onDetach={() => onDetach(file)}
          onDelete={onDelete}
          onAddExistingFile={onAddExistingFile}
          onRefresh={onRefresh}
          onListUpdate={onListUpdate}
          onCancelUpload={onCancelUpload}
        />
      ))}
    </S.PaymentDocuments>
  );
};

export default PaymentDocuments;
