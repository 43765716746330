import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

import { type VariantUnit } from './PaymentDocumentStatusBarDetails';

export const Divider = styled.div`
  width: 2px;
  height: 22px;
  background-color: ${({ theme }) => theme.colors.grey300};
`;

export const Title = styled(Flex)<FlexProps & { variant: VariantUnit }>`
  color: ${({ theme, variant }) => {
    if (variant === 'process') return theme.colors.black;
    if (variant === 'warning') return theme.colors.yellow500;
    if (variant === 'success') return theme.colors.green500;
    if (variant === 'error') return theme.colors.red500;

    return theme.colors.grey500;
  }};
  font-weight: 600;
`;

export const Description = styled.div`
  font-size: 12px;

  strong {
    font-weight: 600;
  }
`;
