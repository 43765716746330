import { Params$AuthController_sendResetPasswordInstructions } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useAuthResetPassword = () => useApiClientMutation<
    Params$AuthController_sendResetPasswordInstructions,
    Schemas.ResetPasswordDto
  >({
    method: 'AuthController_sendResetPasswordInstructions',
  });

export default useAuthResetPassword;
