import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Language } from '@constants';

import { translations } from './';

// TODO provide correct types
window.t =
  (key, ...rest) =>
  () =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (i18n.t as any)(key, ...rest);
/**
 * @deprecated
 *
 * try to avoid and use with caution as it won't check translation keys
 * Created as a temporary hack to support migration from useTranslate hook
 *
 * never should be used
 */
window.translateUntyped = (key) => () => i18n.t(key);

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    resources: translations,
    lng: Language.En,
    fallbackLng: Language.En,
    interpolation: {
      escapeValue: false,
    },
  });

  return i18n;
};
