import { TableColumnType, Tooltip } from 'antd';

import { Schemas } from '@api-client/generated/types';
import { IconMissedFile } from '@assets';
import { Amount, DateText } from '@entities';
import { colors } from '@theme';

type Transaction = Schemas.Transaction;

export const columnsConfig: TableColumnType<Transaction>[] = [
  {
    key: 'file',
    title: '',
    width: 46,
    align: 'center',
    render: (row) =>
      !row.files?.length &&
      !row.isFileless && (
        <Tooltip
          title={t('transactionsPage.missedPaymentDocument')()}
          placement="right"
        >
          <IconMissedFile color={colors.error} />
        </Tooltip>
      ),
    onHeaderCell: () => ({
      style: {
        paddingInline: 0,
      },
    }),
    onCell: () => ({
      style: {
        paddingInline: 0,
      },
    }),
  },
  {
    key: 'amount',
    dataIndex: 'amount',
    title: t('transactionsPage.label.amount')(),
    align: 'right',
    width: 126,
    render: (amount, record) => (
      <Amount amount={amount} currencyCode={record.currencyCode} />
    ),
  },
  {
    key: 'contact',
    dataIndex: 'contact',
    title: t('transactionsPage.label.contact')(),
    width: 150,
    render: (contact) => contact?.name,
    ellipsis: true,
  },
  {
    key: 'details',
    dataIndex: 'details',
    width: 300,
    title: t('transactionsPage.label.details')(),
    ellipsis: true,
  },
  {
    key: 'category',
    dataIndex: 'category',
    title: t('transactionsPage.label.category')(),
    width: 120,
    render: (category) => category?.name,
    ellipsis: true,
  },
  {
    key: 'account',
    dataIndex: 'account',
    title: t('transactionsPage.label.accountName')(),
    render: (account: Schemas.Account) =>
      account?.connection?.bank?.name || account?.accountName,
    width: 109,
    ellipsis: true,
  },
  {
    key: 'date',
    dataIndex: 'bookingDate',
    title: t('transactionsPage.label.date')(),
    width: 110,
    align: 'right',
    render: (date) => <DateText date={date} format="date" />,
  },
];
