import { Flex } from 'antd';

import { Card as CardBase } from '@components';
import styled from '@emotion/styled';

export const Card = styled(CardBase)`
  align-self: baseline;
`;

export const ListHeaderRow = styled.header`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.colors.bgDarkGrey};
  padding: 6px 16px;

  & > span {
    font-size: 12px;
    font-weight: 600;
    color: ${(p) => p.theme.colors.text400};
  }
`;

export const ListRow = styled(Flex)`
  padding: 16px;
  border-bottom: 1px solid ${(p) => p.theme.colors.bgDarkGrey};
`;

export const Total = styled(Flex)`
  padding: 16px;

  & > span {
    font-size: 16px;
    font-weight: 600;
  }
`;
