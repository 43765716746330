import { FC } from 'react';

import { useCategoriesController_findAll } from '@api-client/generated/CategoriesController/findAll';
import { useCategoryGroupController_findAll } from '@api-client/generated/CategoryGroupController/findAll';
import { Select, type SelectProps } from '@components';
import { useAccount, useExtractCategoryGroups, useTranslate } from '@hooks';

type FilterCategoriesProps = SelectProps & {
  withGroups?: boolean;
};

const FilterCategories: FC<SelectProps & FilterCategoriesProps> = ({
  withGroups = false,
  placeholder,
  ...rest
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const { data: categories = [] } = useCategoriesController_findAll({
    params: {
      companyId: companyId!,
    },
    config: {
      enabled: !withGroups,
    },
  });

  const { data: categoryGroups } = useCategoryGroupController_findAll({
    params: {
      companyId: companyId!,
    },
    config: {
      enabled: withGroups,
    },
  });

  const { extractedGroupsWithChildren } =
    useExtractCategoryGroups(categoryGroups);

  const options = withGroups
    ? extractedGroupsWithChildren
    : categories.map((category) => ({
        label: category.name,
        value: category.id,
      }));

  return (
    <Select
      placeholder={placeholder || translate('common.navigation.categories')}
      options={options}
      size="large"
      onChange={rest.onChange}
      {...rest}
    />
  );
};

export default FilterCategories;
