import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import copy from 'copy-to-clipboard';
import { FC, useState } from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { IconChain, IconCopyInner } from '@assets';
import { colors } from '@theme';
import { Button } from '@ui-kit/Button/Button';

type CopyButtonProps = {
  value: string | undefined;
  icon?: 'chain' | 'copy';
  tooltipPlacement?: TooltipPlacement;
  type?: 'default' | 'link';
  disabled?: boolean;
};

const CopyButton: FC<CopyButtonProps> = ({
  value,
  icon = 'copy',
  tooltipPlacement = 'top',
  type = 'default',
  disabled,
}) => {
  const [copied, setCopied] = useState(false);

  const IconCopy = icon === 'copy' ? IconCopyInner : IconChain;

  const handleCopy = () => {
    copy(value!);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Tooltip
      title={
        copied
          ? t('common.copied')()
          : type === 'link'
            ? t('common.copyLink')()
            : t('common.copy')()
      }
      placement={tooltipPlacement}
    >
      <Button
        size="middle"
        type="link"
        onClick={handleCopy}
        icon={copied ? <CheckOutlined color={colors.primary} /> : <IconCopy />}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default CopyButton;
