import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconUpload } from '@assets';
import { PaymentDocumentUnit } from '@constants';
import { PaymentDocumentImportInvoice } from '@entities';
import { colors } from '@theme';

import * as S from './styled';

type PaymentDocumentDropFileOverlayProps = {
  type: PaymentDocumentUnit;
  onUpdate?: (file: Schemas.Document) => void;
};

const PaymentDocumentDropFileOverlay: FC<
  PaymentDocumentDropFileOverlayProps
> = ({ type, onUpdate }) => (
  <S.Container>
    <PaymentDocumentImportInvoice documentType={type} onUpdate={onUpdate}>
      <S.UploadContainer>
        <IconUpload color={colors.primary} />

        <S.Title>{t('income.dropFile.title')()}</S.Title>
      </S.UploadContainer>
    </PaymentDocumentImportInvoice>
  </S.Container>
);

export default PaymentDocumentDropFileOverlay;
