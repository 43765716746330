import { Button, Flex, Typography } from 'antd';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { MessageProcessPaymentToRcs } from '@assets';
import { OnboardingMessage } from '@entities';
import { useOnboarding, useTranslate } from '@hooks';

import * as S from './styled';

const { Paragraph, Text } = Typography;

const OnboardingMessageProcessPaymentRcs: FC = () => {
  const { translate } = useTranslate();
  const { updateScreen } = useOnboarding();

  const handleReturn = () => {
    updateScreen('TRANSFER_SHARE', { active: true });
  };

  const handleContinue = () => {
    updateScreen('INCORPORATION', { active: true });
  };

  return (
    <S.Container>
      <OnboardingMessage
        picture={<MessageProcessPaymentToRcs />}
        title={translate('onboarding.messages.processPaymentRcs.title')}
        description={
          <Trans
            i18nKey={translate(
              'onboarding.messages.processPaymentRcs.description'
            )}
            components={[<Paragraph />, <Text strong />]}
          />
        }
      />

      <Flex justify="flex-end">
        <Button type="text" onClick={handleReturn}>
          {translate('onboarding.buttonReturnToBankDetails')}
        </Button>

        <Button type="primary" onClick={handleContinue}>
          {translate('onboarding.buttonContinue')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingMessageProcessPaymentRcs;
