import { Flex } from 'antd';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import { useAccountsController_getAccounts } from '@api-client/generated/AccountsController/getAccounts';
import { Schemas } from '@api-client/generated/types';
import { Select, type SelectProps } from '@components';
import { AccountsSelectOption, SearchControl } from '@entities';
import { useAccount } from '@hooks';
import { formatNumber, getCurrencySymbol } from '@utils';

import * as S from './styled';

type Account = Schemas.Account;

const getBalance = (account: Account) =>
  account.balances.find(
    (balance: Schemas.Balance) => balance.balanceType === 'calculated'
  ) || account.balances[0];

const AccountsSelect: FC<SelectProps> = ({ ...rest }) => {
  const { companyId } = useAccount();

  const [search, setSearch] = useState<string>('');

  const { data: accounts = [], isPending: loadingAccounts } =
    useAccountsController_getAccounts({
      params: {
        companyId: companyId!,
      },
    });

  const filteredAccounts = accounts.filter((account) =>
    account.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Select
      placeholder={t('common.accountsControl.placeholder')()}
      size="large"
      {...rest}
      options={filteredAccounts.map((account) => ({
        label: account.name,
        value: account.id,
        item: account,
      }))}
      dropdownRender={(menu) => (
        <Flex className="ui-select-dropdown-accounts" vertical>
          <S.DropdownControl>
            <SearchControl
              onChange={(e) => setSearch(e.target.value)}
              fitWidth
            />
          </S.DropdownControl>

          {menu}
        </Flex>
      )}
      optionRender={({ label, data }) => (
        <AccountsSelectOption
          label={label}
          connection={data.item.connection}
          lastSyncedAt={dayjs(data.item.lastSyncedAt).format(
            'DD.MM.YYYY, HH:mm'
          )}
          currency={getCurrencySymbol(
            getBalance(data.item)?.balanceAmount.currency
          )}
          amount={formatNumber(getBalance(data.item)?.balanceAmount.amount)}
        />
      )}
      loading={loadingAccounts}
      filterOption={false}
      {...rest}
    />
  );
};

export default AccountsSelect;
