import { Params$ProjectController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useCreateProject = () => useApiClientMutation<Params$ProjectController_create, Schemas.Project>(
    {
      method: 'ProjectController_create',
    }
  );

export default useCreateProject;
