import { Button, Flex } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import {
  IconArrowUp,
  IconFactory,
  IconUser,
  IconWarningTransparent,
} from '@assets';
import styled from '@emotion/styled';
import { useTranslate } from '@hooks';

import { isLegalPersonWithoutChildren } from './legalPersonWithoutChildren';

type PersonDto = Schemas.PersonDto;
type Person = Schemas.Person;

type ListItemType = {
  item: Partial<PersonDto | Person>;
  areActionsEnabled: boolean;
  onClick: () => void;
  onEdit: () => void;
  onDelete: () => void;
  selected: boolean;
};

const Item = styled(Flex)<{ selected: boolean }>`
  border-radius: 5px;
  padding: 4px 10px;
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.strokeGrey : 'transparent'};

  &:hover {
    .item-actions {
      display: flex;
    }
  }
`;

const FullWidth = styled(Flex)`
  width: 100%;
`;

const Header = styled(Flex)`
  width: 100%;
  font-weight: 600;
`;

const WarningContainer = styled(Flex)`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  cursor: pointer;
`;

const Arrow = styled(IconArrowUp)`
  transform: rotate(90deg);
  cursor: pointer;
`;

const ItemName = styled.span`
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black};
`;

const Content = styled(Flex)`
  position: relative;
`;

const Actions = styled(Flex)`
  display: none;
  position: absolute;
  left: 90px;

  && button {
    padding: 0;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
  }
`;

const Description = styled.span`
  min-width: 70px;
`;

export const ListItem: FC<ListItemType> = ({
  item,
  areActionsEnabled,
  onClick,
  onEdit,
  onDelete,
  selected,
}) => {
  const { translate } = useTranslate();

  const getDataByType = () => {
    const icon = item.type === 'natural' ? <IconUser /> : <IconFactory />;

    const labels = [
      item.isShareholder
        ? translate('onboarding.shareholders.shareholder')
        : null,
      item.isDirector ? translate('onboarding.shareholders.manager') : null,
      item.isCompanyItself
        ? translate('onboarding.shareholders.owncompany')
        : null,
      item.isUbo ? translate('onboarding.shareholders.ubo') : null,
      item.isSigner ? translate('onboarding.shareholders.authorized') : null,
    ]
      .filter((el) => el)
      .join(', ');

    return {
      description: labels,
      icon: icon,
    };
  };

  return (
    <Item selected={selected} align="center" gap={10} onClick={onClick}>
      {getDataByType().icon}
      <FullWidth vertical>
        <Header justify="space-between" gap={10}>
          <ItemName>{item.name}</ItemName>
          {item.type === 'legal' && (
            <Flex gap={10} align="center">
              <WarningContainer justify="center" align="center">
                {isLegalPersonWithoutChildren(item) ? (
                  <IconWarningTransparent width={12} height={12} />
                ) : null}
              </WarningContainer>
              <Arrow />
            </Flex>
          )}
        </Header>

        <Content gap={10} align="center">
          <Description>{getDataByType().description}</Description>
          {areActionsEnabled && (
            <Actions className="item-actions" gap={10} align="center">
              <Button onClick={() => onEdit()} type="text">
                {translate('onboarding.shareholders.actions.edit')}
              </Button>
              <Button onClick={() => onDelete()} type="text" danger>
                {translate('onboarding.shareholders.actions.delete')}
              </Button>
            </Actions>
          )}
        </Content>
      </FullWidth>
    </Item>
  );
};
