import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaWidth } from '@ui-kit/theme/mediaWidth';

type StepProps = {
  selected?: boolean;
};

export const Container = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled.div`
  font-weight: 600;
  margin-bottom: 8px;

  ${mediaWidth.tablet(css`
    font-size: 12px;
  `)};
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.colors.text200};

  ${mediaWidth.tablet(css`
    display: none;
  `)};
`;

export const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
`;

export const Step = styled.div<StepProps>`
  height: 4px;
  border-radius: 12px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.primary : theme.colors.strokeGrey};
  flex: 1;
`;
