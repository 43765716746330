import { Control, SelectOptions, operator } from './utils';

export const getTransactionsConfig = ({
  projects,
  contacts,
  categories,
}: {
  projects: SelectOptions;
  contacts: SelectOptions;
  categories: SelectOptions;
}): {
  if: Control<'contact_name' | 'details', 'attribute', 'operator' | 'value'>;
  then: Control<
    | 'assign_contact'
    | 'assign_category'
    | 'assign_project'
    | 'exclude_from_pl_report'
    | 'mark_as_fileless',
    'name',
    'value'
  >;
} => ({
  if: {
    selector: {
      type: 'select',
      props: {
        placeholder: 'rules.modal.placeholders.attribute',
        width: 206,
        options: [
          {
            label: 'rules.modal.attributes.contactName',
            value: 'contact_name',
          },
          {
            label: 'rules.modal.attributes.details',
            value: 'details',
          },
        ],
      },
      fieldName: 'attribute',
    },
    operator: {
      contact_name: operator,
      details: operator,
    },
  },
  then: {
    selector: {
      type: 'select',
      uniqueValue: true,
      props: {
        placeholder: 'rules.modal.placeholders.action',
        width: 345,
        options: [
          {
            label: 'rules.modal.actions.contact',
            value: 'assign_contact',
          },
          {
            label: 'rules.modal.actions.project',
            value: 'assign_project',
            disabled: !projects.length,
          },
          {
            label: 'rules.modal.actions.category',
            value: 'assign_category',
          },
          {
            label: 'rules.modal.actions.excludeFromPl',
            value: 'exclude_from_pl_report',
          },
          {
            label: 'rules.modal.actions.fileless',
            value: 'mark_as_fileless',
          },
        ],
      },
      fieldName: 'name',
    },
    operator: {
      assign_contact: [
        {
          type: 'select_contact',
          props: {
            placeholder: 'rules.modal.placeholders.contact',
            width: 411,
            options: contacts,
          },
          fieldName: 'value',
        },
      ],
      assign_project: [
        {
          type: 'select',
          props: {
            placeholder: 'rules.modal.placeholders.project',
            width: 411,
            options: projects,
            showSearch: true,
          },
          fieldName: 'value',
        },
      ],
      assign_category: [
        {
          type: 'select',
          props: {
            placeholder: 'rules.modal.placeholders.category',
            width: 411,
            options: categories,
            showSearch: true,
          },
          fieldName: 'value',
        },
      ],
    },
  },
});
