import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaWidth } from '@ui-kit/theme/mediaWidth';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .ui-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
`;

export const Inner = styled.div`
  margin-bottom: 24px;

  .ui-otp {
    ${mediaWidth.tablet(css`
      width: 100%;
    `)};
  }

  .ui-otp-input {
    ${mediaWidth.mobileL(css`
      width: calc(100% / 6);
    `)};
  }
`;

export const Link = styled.a`
  font-size: 16px;
  font-weight: 500;
`;
