import { Flex, Modal, Typography } from 'antd';

import { Button } from '@ui-kit/Button/Button';

const { Text } = Typography;

type BillingConfirmDeletingCardModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const BillingConfirmDeletingCardModal = ({
  isOpen,
  onClose,
  onConfirm,
}: BillingConfirmDeletingCardModalProps) => (
  <Modal
    open={isOpen}
    width={504}
    title={t('settings.billing.paymentDetails.editCard.confirm.title')()}
    footer={null}
    onCancel={onClose}
    centered
  >
    <Flex gap={24} vertical>
      <Text>
        {t('settings.billing.paymentDetails.editCard.confirm.description')()}
      </Text>
      <Flex gap={12} justify="flex-end">
        <Button onClick={onClose}>
          {t(
            'settings.billing.paymentDetails.editCard.confirm.actions.cancel'
          )()}
        </Button>
        <Button type="primary" onClick={onConfirm} danger>
          {t(
            'settings.billing.paymentDetails.editCard.confirm.actions.delete'
          )()}
        </Button>
      </Flex>
    </Flex>
  </Modal>
);

export default BillingConfirmDeletingCardModal;
