import { Typography } from 'antd';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { MessageProcessPaymentSent } from '@assets';
import { OnboardingMessage } from '@entities';
import { useOnboarding } from '@hooks';

import * as S from './styled';

const { Paragraph, Text } = Typography;

const OnboardingMessageProcessPaymentSent: FC = () => {
  const { incorporationDetails } = useOnboarding();

  return (
    <S.Container>
      <OnboardingMessage
        picture={<MessageProcessPaymentSent />}
        title={t(
          incorporationDetails?.isPaid
            ? 'onboarding.messages.processPaymentSent.titlePaid'
            : 'onboarding.messages.processPaymentSent.titlePaying'
        )()}
        description={
          <Trans
            i18nKey={t(
              'onboarding.messages.processPaymentSent.description'
            )()}
            components={[<Paragraph />, <Text strong />]}
          />
        }
      />
    </S.Container>
  );
};

export default OnboardingMessageProcessPaymentSent;
