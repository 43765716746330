import { Layout } from 'antd';

import styled from '@emotion/styled';
import { config } from '@ui-kit/theme/theme';

export const Sidebar = styled(Layout.Sider)`
  &.ui-layout-sider {
    height: calc(100vh - 72px);
    background-color: ${config.components.Layout.siderBg};
    position: fixed;
    top: 72px;
    left: 0;
    z-index: 3;

    && ul {
      &&.ui-menu-item,
      .ui-menu-item-selected {
        background-color: ${config.components.Layout.siderActiveTab};
      }

      &&.ui-menu-item,
      .ui-menu-item-disabled {
        color: ${config.components.Layout.siderDisabledTabColor};
      }

      &&.ui-menu-item,
      .ui-menu-item-active {
        background-color: ${config.components.Layout.siderActiveTab};
      }
    }
  }

  .ui-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
