import { Flex } from 'antd';
import { FC } from 'react';

import { VAT_DEFAULT_PERCENT, VAT_MIDDLE_PERCENT } from '@constants';
import { Amount } from '@entities';
import { useTranslate } from '@hooks';

import * as S from './styled';

type InvoiceDocumentTotalProps = {
  total: number;
  subTotal: number;
  defaultTaxRate: number;
  middleTaxRate: number;
};

const InvoiceDocumentTotal: FC<InvoiceDocumentTotalProps> = ({
  total,
  subTotal,
  defaultTaxRate,
  middleTaxRate,
}) => {
  const { translate } = useTranslate();

  return (
    <S.Container justify="flex-end">
      <S.Total gap={12} vertical>
        <Flex align="center" justify="space-between">
          <S.SecondaryLabel>
            {translate('invoiceGenerator.document.total.subtotal')}:
          </S.SecondaryLabel>

          <Amount currencyCode="EUR" amount={subTotal} />
        </Flex>

        {defaultTaxRate ? (
          <Flex align="center" justify="space-between">
            <S.SecondaryLabel>
              {translate('invoiceGenerator.document.total.vat')}{' '}
              {VAT_MIDDLE_PERCENT}%:
            </S.SecondaryLabel>

            <Amount currencyCode="EUR" amount={defaultTaxRate} />
          </Flex>
        ) : null}

        {middleTaxRate ? (
          <Flex align="center" justify="space-between">
            <S.SecondaryLabel>
              {translate('invoiceGenerator.document.total.vat')}{' '}
              {VAT_DEFAULT_PERCENT}%:
            </S.SecondaryLabel>

            <Amount currencyCode="EUR" amount={middleTaxRate} />
          </Flex>
        ) : null}

        <Flex align="center" justify="space-between">
          <S.PrimaryLabel>
            {translate('invoiceGenerator.document.total.total')}:
          </S.PrimaryLabel>

          <S.PrimaryValue>
            <Amount currencyCode="EUR" amount={total} />
          </S.PrimaryValue>
        </Flex>
      </S.Total>
    </S.Container>
  );
};

export default InvoiceDocumentTotal;
