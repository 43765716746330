import { type SerializedStyles, css } from '@emotion/react';

type Breakpoints = {
  mobileS: number;
  mobileM: number;
  mobileL: number;
  tablet: number;
  laptop: number;
  laptopS: number;
  laptopM: number;
  laptopL: number;
  desktop: number;
};

export const breakpoints: Breakpoints = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopS: 1280,
  laptopM: 1440,
  laptopL: 1560,
  desktop: 2560,
};

const heightBreakpoints: Breakpoints = {
  mobileS: 568,
  mobileM: 667,
  mobileL: 755,
  tablet: 1024,
  laptop: 800,
  laptopS: 900,
  laptopM: 1050,
  laptopL: 1200,
  desktop: 1440,
};

export const mediaWidth = Object.keys(breakpoints).reduce(
  (acc, label) => {
    const breakpoint = label as keyof Breakpoints;
    acc[breakpoint] = (styles: SerializedStyles) => css`
      @media (max-width: ${breakpoints[breakpoint]}px) {
        ${styles}
      }
    `;
    return acc;
  },
  {} as {
    [key in keyof Breakpoints]: (styles: SerializedStyles) => SerializedStyles;
  }
);

export const mediaHeight = Object.keys(heightBreakpoints).reduce(
  (acc, label) => {
    const breakpoint = label as keyof Breakpoints;
    acc[breakpoint] = (styles: SerializedStyles) => css`
      @media (max-height: ${heightBreakpoints[breakpoint]}px) {
        ${styles}
      }
    `;
    return acc;
  },
  {} as {
    [key in keyof Breakpoints]: (styles: SerializedStyles) => SerializedStyles;
  }
);
