import { Flex, Input, Upload } from 'antd';

import styled from '@emotion/styled';

export const Container = styled.div<{ isSelected?: boolean }>`
  width: 100%;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.bgBrightBlue : theme.colors.bgLightBlue200};
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.primary : theme.colors.strokeGrey};
  border-radius: 12px;
  padding: 20px;

  && svg {
    cursor: pointer;
  }
`;

export const UploadContainer = styled(Flex)`
  width: 100%;
  height: 76px;
  border: 1px dashed ${({ theme }) => theme.colors.primary};
  border-radius: 12px;
  padding: 20px;
`;

export const UploadFile = styled(Upload)`
  width: 100%;
  div {
    width: 100%;
  }
`;

export const FilenameInput = styled(Input)`
  max-width: 504px;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.text400};
  font-weight: 600;
  font-size: 16px;
  padding: 20px 0;
`;

export const PreviewLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
`;
