import { Flex, Switch } from 'antd';
import { FC } from 'react';

import { IconLock } from '@assets';

import * as S from './styled';

type FilelessBlockProps = {
  onChange: (value: boolean) => void;
};

const FilelessBlock: FC<FilelessBlockProps> = ({ onChange }) => (
  <S.FilelessCard align="center" justify="center" gap={8} vertical>
    <IconLock width={24} height={24} />
    <S.FilelessTitle>{t('transaction.documents.isFileless')()}</S.FilelessTitle>
    <Flex gap={16}>
      <S.FilelessDescription>
        {t('transaction.documents.filelessTransaction')()}
      </S.FilelessDescription>
      <Switch checked={true} onChange={onChange} />
    </Flex>
  </S.FilelessCard>
);

export default FilelessBlock;
