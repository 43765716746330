interface GTMEventParams {
  [key: string]: number | string;
}

export const sendGTMEvent = (
  eventName: string,
  eventParams?: GTMEventParams
): void => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ...eventParams,
    });
  } else {
    console.error('GTM not initialized');
  }
};
