import { Button, Flex, type ModalProps, Space, Tooltip, message } from 'antd';
import { FC, useEffect, useState } from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { useTransactionController_findAll } from '@api-client/generated/TransactionController/findAll';
import { useTransactionController_findOneById } from '@api-client/generated/TransactionController/findOneById';
import { Schemas } from '@api-client/generated/types';
import { IconChain, IconLeft, IconRight, IconSplit } from '@assets';
import { Loader } from '@components';
import {
  TransactionButtonCopy,
  TransactionContent,
  TransactionSettingsButton,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useUpdateTransactionById } from '@hooks-api';
import { colors } from '@theme';
import { historyReplace } from '@utils';

import * as S from './styled';

type Transaction = Schemas.Transaction;

type FilterOptions = Record<string, unknown>;

type TransactionContentModalProps = {
  id: string;
  page: number;
  filterOptions?: FilterOptions;
  setSelectedRow?: (id: string) => void;
  transactions?: Transaction[] | null;
  onChangeTransactionFileList?: (id: string, ids: Schemas.Document[]) => void;
  handleChangeSettings?: (
    id: string,
    key: 'isFileless' | 'isIgnoredForPL',
    value: boolean
  ) => void;
  onUpdateTransaction?: VoidFunction;
} & ModalProps;

const TransactionContentModal: FC<TransactionContentModalProps> = ({
  open,
  id,
  page = 1,
  filterOptions,
  setSelectedRow,
  transactions,
  onChangeTransactionFileList,
  handleChangeSettings,
  onUpdateTransaction,
  ...rest
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();
  const [transactionId, setTransactionId] = useState(id);

  const [currentPage, setCurrentPage] = useState(page);
  const [totalPages, setTotalPages] = useState(transactions?.length || 0);
  const [isFilelessOpen, setIsFilelessOpen] = useState(false);
  const { mutate: updateTransactionById } = useUpdateTransactionById();

  const { isPending: loading, data } = useTransactionController_findAll({
    params: {
      companyId: companyId!,
      page: currentPage,
      perPage: 1,
      reportType:
        filterOptions?.showByPlDate === 'true' ||
        (typeof filterOptions?.showByPlDate === 'boolean' &&
          filterOptions?.showByPlDate)
          ? 'pl'
          : 'cf',
      ...filterOptions,
    },
    config: {
      placeholderData: (_) => _,
      enabled: id !== null,
      gcTime: 0,
    },
  });

  useEffect(() => {
    if (data) {
      if (!transactions?.length) {
        setTransactionId(data.records[0]?.id);
        setCurrentPage(data.metadata.currentPage);
        setTotalPages(data.metadata.totalPages);
        historyReplace(`/transactions/${data.records[0]?.id}`);
      } else {
        setSelectedRow && setSelectedRow(data.records[0]?.id);
        historyReplace(`/transactions/${transactionId}`);
      }
    }
  }, [data, setSelectedRow, transactionId, transactions?.length]);

  const {
    data: transaction,
    refetch,
    isPending: isTransactionByIdLoading,
  } = useTransactionController_findOneById({
    params: {
      companyId: companyId!,
      id: transactionId,
    },
    config: {
      placeholderData: (_) => _,
      enabled: !!transactionId,
      gcTime: 0,
    },
  });

  useEffect(() => {
    setCurrentPage(page);
  }, [page, id]);

  useEffect(() => {
    if (transactions?.length) {
      setTotalPages(transactions?.length || 0);
      setTransactionId(transactions[currentPage - 1]?.id);
    }
  }, [transactions, currentPage]);

  if (!transaction) {
    return null;
  }

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleUpdateTransaction = (
    key: 'isFileless' | 'isIgnoredForPL',
    value: boolean
  ) => {
    if (transactionId) {
      updateTransactionById(
        {
          parameter: {
            id: transactionId,
            companyId: companyId!,
          },
          requestBody: {
            [key]: value,
          },
        },
        {
          onSuccess: (res) => {
            handleChangeSettings?.(res.id, key, value);
            refetch();
            message.success(translate('transaction.success.update'));
          },
        }
      );
    }
  };

  const Title = () => (
    <Space split={<S.Divider />}>
      <Flex align="center">
        <Tooltip
          placement="bottom"
          title={translate('transaction.breadcrumbs.navigation.previous')}
        >
          <Button
            icon={
              <IconLeft
                color={currentPage === 1 ? colors.textDisabled : colors.text400}
              />
            }
            size="small"
            type="text"
            onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
          />
        </Tooltip>
        <Tooltip
          placement="bottom"
          title={translate('transaction.breadcrumbs.navigation.next')}
        >
          <Button
            size="small"
            type="text"
            icon={
              <IconRight
                color={
                  currentPage === totalPages
                    ? colors.textDisabled
                    : colors.text400
                }
              />
            }
            onClick={() =>
              currentPage < totalPages && setCurrentPage(currentPage + 1)
            }
          />
        </Tooltip>
        <S.Count color={colors.text400}>
          {currentPage} {translate('transaction.breadcrumbs.of')} {totalPages}
        </S.Count>
      </Flex>
      <Flex align="center">
        <TransactionButtonCopy id={transaction.id} />
      </Flex>
      <Flex align="center" gap={10}>
        <S.CopyButton
          copyable={{
            icon: [
              <Button
                type="text"
                icon={<IconChain color={colors.text400} />}
                size="small"
              />,
              <Button type="text" icon={<CheckOutlined />} size="small" />,
            ],
            text: location.origin + `/transactions/${transaction.id}`,
            tooltips: [
              translate('transaction.breadcrumbs.buttonCopy.copyLink'),
              translate('transaction.breadcrumbs.buttonCopy.linkCopied'),
            ],
          }}
        />
        <Tooltip
          placement="bottom"
          title={translate('transaction.breadcrumbs.split')}
        >
          <Button
            icon={<IconSplit color={colors.text400} />}
            size="small"
            onClick={() => scrollToElement('transactions-split')}
            type="text"
          />
        </Tooltip>
      </Flex>
      <TransactionSettingsButton
        initialFileless={transaction?.isFileless}
        initialPlDate={transaction?.isIgnoredForPL}
        isFilelessOpen={isFilelessOpen}
        onToggle={() => setIsFilelessOpen((prev) => !prev)}
        onChange={handleUpdateTransaction}
      />
    </Space>
  );

  return (
    <S.Modal
      className="ui-modal-top"
      width={1026}
      open={open}
      title={<Title />}
      footer={null}
      {...rest}
    >
      {loading || isTransactionByIdLoading ? (
        <S.LoaderContainer>
          <Loader />
        </S.LoaderContainer>
      ) : (
        <TransactionContent
          id={transactionId!}
          transaction={transaction}
          headerContent={null}
          onChangeFileless={(value) =>
            handleUpdateTransaction?.('isFileless', value)
          }
          onChangeTransactionFileList={onChangeTransactionFileList}
          onRefresh={refetch}
          onUpdateTransaction={onUpdateTransaction}
          isModal
        />
      )}
    </S.Modal>
  );
};

export default TransactionContentModal;
