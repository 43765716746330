//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$BillingPeriodController_current,
  Response$BillingPeriodController_current$Status$200,
  ResponseContentType$BillingPeriodController_current,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useBillingPeriodController_current = (options: {
  params: Params$BillingPeriodController_current['parameter'];
  config?: QueryOptions<
    Response$BillingPeriodController_current$Status$200[ResponseContentType$BillingPeriodController_current]
  >;
}) =>
  useApiClient<
    Params$BillingPeriodController_current,
    Response$BillingPeriodController_current$Status$200[ResponseContentType$BillingPeriodController_current]
  >({
    method: 'BillingPeriodController_current',
    params: { parameter: options.params },
    config: options.config,
  });
