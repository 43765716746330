import { Flex } from 'antd';
import { FC } from 'react';

import { IconEyeOff } from '@assets';
import { PaymentDocumentUnit } from '@constants';
import { PaymentDocumentImportInvoice } from '@entities';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type PaymentDocumentErrorProps = {
  type: PaymentDocumentUnit;
  onDelete?: () => void;
};

const getTranslatesByType = (type: PaymentDocumentUnit) =>
  type === 'income'
    ? {
        title: t('income.error.title')(),
        description: t('income.error.description')(),
        buttonDelete: t('income.error.buttonDelete')(),
        buttonUpload: t('income.error.buttonUpload')(),
      }
    : {
        title: t('expenses.error.title')(),
        description: t('expenses.error.description')(),
        buttonDelete: t('expenses.error.buttonDelete')(),
        buttonUpload: t('expenses.error.buttonUpload')(),
      };

const PaymentDocumentError: FC<PaymentDocumentErrorProps> = ({
  type,
  onDelete,
}) => {
  const translates = getTranslatesByType(type);

  return (
    <S.Container>
      <S.Icon>
        <IconEyeOff width={32} height={32} />
      </S.Icon>

      <S.Title>{translates.title}</S.Title>
      <S.Description>{translates.description}</S.Description>

      <Flex align="center" gap={8}>
        <Button type="text" onClick={onDelete} danger>
          {translates.buttonDelete}
        </Button>

        <PaymentDocumentImportInvoice documentType={type}>
          <Button size="small">{translates.buttonUpload}</Button>
        </PaymentDocumentImportInvoice>
      </Flex>
    </S.Container>
  );
};

export default PaymentDocumentError;
