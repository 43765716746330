import { Flex } from 'antd';
import { FC, ReactNode, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import { BankDefaultLogotype } from '@assets';

import * as S from './styled';

type AccountsSelectOptionProps = {
  label: ReactNode;
  amount: string;
  currency: string;
  connection: Schemas.BankConnection;
  lastSyncedAt: string;
};

const AccountsSelectOption: FC<AccountsSelectOptionProps> = ({
  label,
  amount,
  currency,
  connection,
  lastSyncedAt,
}) => {
  const [isErrorLogo, setIsErrorLogo] = useState(false);

  return (
    <Flex align="center" justify="space-between">
      <Flex gap={12} align="center">
        <S.Logo
          src={
            !connection || isErrorLogo
              ? BankDefaultLogotype
              : `./${connection.bank.bic}.png`
          }
          onError={() => setIsErrorLogo(true)}
        />

        <Flex gap={4} vertical>
          <S.Name>{label}</S.Name>
          <S.Details>{lastSyncedAt}</S.Details>
        </Flex>
      </Flex>

      <S.Amount>
        {currency} {amount}
      </S.Amount>
    </Flex>
  );
};

export default AccountsSelectOption;
