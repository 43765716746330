import {
  Button,
  Checkbox,
  Flex,
  Form,
  Modal,
  ModalProps,
  Typography,
} from 'antd';

import { useAccountsController_deleteAccount } from '@api-client/generated/AccountsController/deleteAccount';
import { Schemas } from '@api-client/generated/types';
import { useAccount, useAccounts } from '@hooks';

const { Text, Title } = Typography;

type AccountDisconnectModalProps = {
  account?: Schemas.Account | null;
  onCancel: () => void;
} & ModalProps;

const AccountDisconnectModal = ({
  account,
  onCancel,
  ...rest
}: AccountDisconnectModalProps) => {
  const open = Boolean(account);
  const [form] = Form.useForm();
  const { companyId } = useAccount();
  const { deleteAccount } = useAccounts();

  const { mutate: disconnectAccount, isPending: loading } =
    useAccountsController_deleteAccount();

  const handleClose = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = (data: Omit<Schemas.DeleteAccountDto, 'id'>) => {
    if (!account) {
      return;
    }

    disconnectAccount(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          id: account.id,
          keepTransactions: Boolean(data.keepTransactions),
        },
      },
      {
        onSuccess() {
          deleteAccount(account.id);
          handleClose();
        },
      }
    );
  };

  return (
    <Modal
      open={open}
      width={504}
      title={
        <Title level={3}>
          {t('accounts.accountDisconnect.title', {
            name: account?.accountName,
          })()}
        </Title>
      }
      onCancel={handleClose}
      footer={null}
      destroyOnClose
      centered
      {...rest}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{ keepTransactions: true }}
      >
        <Flex gap={24} vertical>
          <Flex gap={16} vertical>
            <Flex vertical>
              <Text>
                {t('accounts.accountDisconnect.confirmationDescriptionFirst')()}
              </Text>
              <Text>
                {t(
                  'accounts.accountDisconnect.confirmationDescriptionSecond'
                )()}
              </Text>
            </Flex>
            <Flex gap={8}>
              <Form.Item name="keepTransactions" valuePropName="checked">
                <Checkbox>
                  <Text strong>
                    {t('accounts.accountDisconnect.keepTransactionsOption')()}
                  </Text>
                </Checkbox>
              </Form.Item>
            </Flex>
          </Flex>
          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={handleClose} size="large">
                {t('accounts.accountDisconnect.buttons.cancel')()}
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
                danger
              >
                {t('accounts.accountDisconnect.buttons.confirm')()}
              </Button>
            </Flex>
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
};

export default AccountDisconnectModal;
