import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Alert = styled(Flex)<FlexProps>`
  background-color: ${({ theme }) => theme.colors.badgeSuccess};
  border-radius: 12px;
  padding: 16px 20px;
  margin-bottom: 24px;
`;

export const AlertTitle = styled.div`
  font-size: 16px;
`;
