import { Flex, Typography } from 'antd';

import styled from '@emotion/styled';

export const Container = styled(Flex)`
  height: 100%;
  padding: 80px 0;
`;

export const Title = styled(Typography.Title)`
  && {
    padding: 0;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.text400};
  }
`;

export const Description = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.text400};
  text-align: center;
`;
