import { Flex } from 'antd';

import styled from '@emotion/styled';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  margin-top: 8px;
  padding-bottom: 8px;
`;

export const ListItemContent = styled(Flex)`
  flex-direction: column;
  margin-left: 24px;
  border-left: 1px solid ${({ theme }) => theme.colors.strokeGrey};
`;
