import { Flex } from 'antd';

import { IconAccounts } from '@assets';
import { useTranslate } from '@hooks';

import * as S from './styled';

const AccountsListEmpty = () => {
  const { translate } = useTranslate();
  return (
    <S.Wrapper align="center" justify="center" gap={16} vertical>
      <S.IconWrapper align="center" justify="center">
        <IconAccounts width={32} height={32} />
      </S.IconWrapper>

      <Flex gap={6} align="center" vertical>
        <S.Title>{translate('accounts.empty.accounts.title')}</S.Title>
        <S.Description>
          {translate('accounts.empty.accounts.description')}
        </S.Description>
      </Flex>
    </S.Wrapper>
  );
};

export default AccountsListEmpty;
