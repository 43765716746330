import { Flex, Form, Select, Typography, message } from 'antd';
import { FC, PropsWithChildren } from 'react';

import { Schemas } from '@api-client/generated/types';
import { KYCFiles } from '@entities';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

type KYCManagingAccountsProps = {
  person: Schemas.Person;
  selectedType: string | null;
  selectedStepValue: string;
};

const { Paragraph, Title } = Typography;

const KYCManagingAccounts: FC<PropsWithChildren<KYCManagingAccountsProps>> = ({
  person,
  selectedType,
  selectedStepValue,
}) => {
  const { translate } = useTranslate();

  const { companyId } = useAccount();

  const [form] = Form.useForm();

  const { incorporationDetails, updateIncorporation } = useOnboarding();
  const { mutate: update } = useUpdateIncorporation();

  const onFinish = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      ...form.getFieldsValue(),
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>
          {translate('onboarding.kyc.legal.annual_accounts')}
        </Title>

        <Paragraph type="secondary">
          {translate('onboarding.kyc.legalDetails.annual_accounts')}
        </Paragraph>
      </div>

      <Form
        form={form}
        layout="vertical"
        colon={false}
        initialValues={person.kycData}
      >
        <Form.Item
          name="hasFiledAnnualAccounts"
          label={translate(
            'onboarding.kyc.legalKycForm.hasFiledAnnualAccounts.label'
          )}
        >
          <Select
            placeholder={translate(
              'onboarding.kyc.legalKycForm.hasFiledAnnualAccounts.placeholder'
            )}
            options={[
              {
                label: translate(
                  'onboarding.kyc.legalKycForm.hasFiledAnnualAccounts.values.yes'
                ),
                value: 'yes',
              },
              {
                label: translate(
                  'onboarding.kyc.legalKycForm.hasFiledAnnualAccounts.values.no'
                ),
                value: 'no',
              },
            ]}
            size="large"
            onChange={onFinish}
            virtual
          />
        </Form.Item>
      </Form>

      <KYCFiles
        person={person}
        selectedType={selectedType}
        selectedStepValue={selectedStepValue}
      />
    </Flex>
  );
};

export default KYCManagingAccounts;
