import { Params$BankConnectionsController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useInitializeBankConnections = () => useApiClientMutation<
    Params$BankConnectionsController_create,
    Schemas.BankConnectionData
  >({
    method: 'BankConnectionsController_create',
  });

export default useInitializeBankConnections;
