import { Flex, Typography } from 'antd';

import styled from '@emotion/styled';

export const Title = styled(Typography)`
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
`;

export const Content = styled(Flex)`
  min-height: 296px;
`;

export const OnlyArchived = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;
`;
