import { PaymentDocumentUnit } from '@constants';

const getTranslatesByType = (type: PaymentDocumentUnit) =>
  type === 'income'
    ? {
        title: t('income.title')(),
        tabAllInvoices: t('income.tabs.allInvoices')(),
        buttonImport: t('income.buttonImport')(),
        filterContacts: t('income.filter.contacts.label')(),
        filterShowInvoices: t('income.filter.showInvoices')(),
        detailsTitle: t('income.details.title')(),
        messageRemove: t('income.messageRemove')(),
      }
    : {
        title: t('expenses.title')(),
        tabAllInvoices: t('expenses.tabs.allInvoices')(),
        buttonImport: t('expenses.buttonImport')(),
        filterContacts: t('expenses.filter.contacts.label')(),
        filterShowInvoices: t('expenses.filter.showInvoices')(),
        detailsTitle: t('expenses.details.title')(),
        messageRemove: t('expenses.messageRemove')(),
      };

export default getTranslatesByType;
