import { useEffect } from 'react';

import { useDocumentController_findAll } from '@api-client/generated/DocumentController/findAll';
import { Schemas } from '@api-client/generated/types';
import { useAccount } from '@hooks';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';

export const useMatchedDocuments = ({
  conditions,
  enabled,
}: {
  // TODO that might be incorrect
  conditions: Partial<Schemas.Condition>[];
  enabled: boolean;
}) => {
  const { companyId } = useAccount();

  const stringifiedConditions = JSON.stringify(conditions);

  const { metadata, incrementPage, hasNextPage, plainData, appendData } =
    usePagination<Schemas.Document>(stringifiedConditions);

  const { data, isPending: isLoading } = useDocumentController_findAll({
    params: {
      companyId: companyId!,
      conditions: stringifiedConditions,
      page: metadata.currentPage,
    },
    config: {
      enabled,
    },
  });

  useEffect(() => {
    if (data) {
      appendData(data);
    }
  }, [appendData, data]);

  const { sentryRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  return {
    data: plainData,
    totalPages: data?.metadata.totalRecords,
    hasNextPage,
    isLoading,
    infiniteScrollRef: sentryRef,
  };
};
