import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Loader } from '@components';
import { PageMeta, PublicCardForm } from '@entities';
import { useTranslate } from '@hooks';
import { useConfirmEmailByToken } from '@hooks-api';
import { Button } from '@ui-kit/Button/Button';

const ConfirmEmail: FC = () => {
  const [searchParams] = useSearchParams();
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [confirmed, setConfirmed] = useState(false);

  const confirmationToken = searchParams.get('token');

  const {
    mutate: confirmEmailByToken,
    isPending: loading,
    error,
  } = useConfirmEmailByToken();

  useEffect(() => {
    confirmEmailByToken(
      {
        parameter: {
          token: confirmationToken!,
        },
      },
      {
        onSuccess: () => {
          setConfirmed(true);
        },
      }
    );
  }, [confirmEmailByToken, confirmationToken]);

  const goToRoot = () => {
    navigate('/');
  };

  if (error) {
    return (
      <PublicCardForm
        title={translate('auth.emailConfirmation.title')}
        subTitle={translate('auth.emailConfirmation.subtitleExpired')}
      />
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (confirmed) {
    return (
      <>
        <PageMeta title={translate('auth.emailConfirmation.title')} />

        <PublicCardForm
          title={translate('auth.emailConfirmation.title')}
          subTitle={translate('auth.emailConfirmation.subtitle')}
        >
          <Button type="primary" size="large" onClick={() => goToRoot()} block>
            {translate('auth.emailConfirmation.buttonAccept')}
          </Button>
        </PublicCardForm>
      </>
    );
  }

  return null;
};

export default ConfirmEmail;
