import { useContext } from 'react';

import { ModalManager, ModalManagerContext } from './ModalManager';

export const useModalManager = (): ModalManagerContext => {
  const context = useContext(ModalManager);

  if (!context) {
    throw new Error(
      'useModalManager must be used within a ModalManagerProvider'
    );
  }

  return context;
};
