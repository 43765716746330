import { Flex } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { PaymentDocumentUnit } from '@constants';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type PaymentDocumentPotentialDuplicateProps = {
  type: PaymentDocumentUnit;
  file?: Schemas.Document;
  onCancel?: () => void;
  onProceed?: () => void;
};

const getTranslatesByType = (type: PaymentDocumentUnit) =>
  type === 'income'
    ? {
        title: t('income.potentialDuplicate.title')(),
        buttonCancel: t('income.potentialDuplicate.buttonCancel')(),
        buttonProceed: t('income.potentialDuplicate.buttonProceed')(),
      }
    : {
        title: t('expenses.potentialDuplicate.title')(),
        buttonCancel: t('expenses.potentialDuplicate.buttonCancel')(),
        buttonProceed: t('expenses.potentialDuplicate.buttonProceed')(),
      };

const PaymentDocumentPotentialDuplicate: FC<
  PaymentDocumentPotentialDuplicateProps
> = ({ type, file, onCancel, onProceed }) => {
  const translates = getTranslatesByType(type);

  return (
    <S.Container gap={16} vertical>
      <Flex gap={5} vertical>
        <S.Title>{translates.title}</S.Title>

        <S.Description data-testid="file-description">
          {file?.contact?.name}
        </S.Description>
      </Flex>

      <S.View>
        {file?.mimeType?.includes('image') ? (
          <img src={file.url} alt={file.url} />
        ) : (
          <iframe src={file?.url} data-testid="iframe" />
        )}
      </S.View>

      <Flex gap={12} align="center">
        <Button type="text" onClick={onCancel} danger block>
          {translates.buttonCancel}
        </Button>

        <Button type="primary" onClick={onProceed} block>
          {translates.buttonProceed}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default PaymentDocumentPotentialDuplicate;
