import { Flex } from 'antd';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useDocumentController_findOneById } from '@api-client/generated/DocumentController/findOneById';
import { Loader } from '@components';
import { InvoiceDocument, InvoiceForm, PageMeta } from '@entities';
import { useAccount, useTranslate } from '@hooks';

import * as S from './styled';

const InvoiceGenerator: FC = () => {
  const { id: documentId } = useParams();

  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const {
    data: document,
    isPending: isLoadingDocument,
    refetch: refetchDocument,
  } = useDocumentController_findOneById({
    params: {
      companyId: companyId!,
      id: documentId!,
    },
    config: {
      enabled: !!documentId,
      refetchOnWindowFocus: false,
    },
  });

  if (!document || isLoadingDocument) {
    return <Loader />;
  }

  return (
    <S.Container>
      <PageMeta title={translate('invoiceGenerator.title')} />

      <Flex align="flex-start" gap={30}>
        <InvoiceDocument document={document} onAfterUpdate={refetchDocument} />
        <InvoiceForm />
      </Flex>
    </S.Container>
  );
};

export default InvoiceGenerator;
