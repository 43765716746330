import { Tabs, TabsProps } from 'antd';
import { FC, useMemo } from 'react';

import { useCategoryGroupController_findAll } from '@api-client/generated/CategoryGroupController/findAll';
import { useAccount, useExtractCategoryGroups, useTranslate } from '@hooks';

import { useLinkedData } from '../../useLinkedData';
import RulesForm from './RulesForm';
import { getDocumentsConfig } from './getDocumentsConfig';
import { getTransactionsConfig } from './getTransactionsConfig';
import { FormValues } from './utils';

type MatchingEntitiesProps = {
  totalPages?: number;
  setIsOpen: VoidFunction;
  canBeShown: boolean;
};

type AddRuleModalProps = {
  activeTab: 'transaction' | 'file';
  handleChangeActiveTab: (key: string) => void;
  handleChangeFormValues: <
    TIfOptionValue extends string,
    TIfSelectorFieldName extends string,
    TIfOperatorFieldName extends string,
    TThenOptionValue extends string,
    TThenSelectorFieldName extends string,
    TThenOperatorFieldName extends string,
  >(
    formValues: Partial<{
      conditions: FormValues<
        TIfOptionValue,
        TIfSelectorFieldName,
        TIfOperatorFieldName
      >[];
      actions: FormValues<
        TThenOptionValue,
        TThenSelectorFieldName,
        TThenOperatorFieldName
      >[];
    }>
  ) => void;
  formValues: {
    transaction: {
      conditions: FormValues<
        'contact_name' | 'details',
        'attribute',
        'operator' | 'value'
      >[];
      actions: FormValues<
        | 'assign_contact'
        | 'assign_category'
        | 'assign_project'
        | 'exclude_from_pl_report'
        | 'mark_as_fileless',
        'name',
        'value'
      >[];
    };
    file: {
      conditions: FormValues<
        'details' | 'contact_name',
        'attribute',
        'operator' | 'value'
      >[];
      actions: FormValues<'assign_contact', 'name', 'value'>[];
    };
  };
  matchingTransactions: MatchingEntitiesProps;
  matchingDocuments: MatchingEntitiesProps;
  tabs: readonly ['transaction' | 'file', ...('transaction' | 'file')[]];
};

const getSelectOptionsData = (prevData: { name: string; id: string }[]) =>
  prevData.map((item) => ({ label: item.name, value: item.id }));

export const AddRuleModal: FC<AddRuleModalProps> = ({
  activeTab,
  handleChangeActiveTab,
  handleChangeFormValues,
  formValues,
  matchingTransactions,
  matchingDocuments,
  tabs,
}) => {
  const { translate } = useTranslate();

  const { projects, contacts } = useLinkedData();

  const { companyId } = useAccount();

  const { data: categoryGroups } = useCategoryGroupController_findAll({
    params: {
      companyId: companyId!,
    },
  });

  const { extractedGroupsWithChildren } =
    useExtractCategoryGroups(categoryGroups);

  const transactionControls = useMemo(
    () =>
      getTransactionsConfig({
        projects: getSelectOptionsData(projects),
        contacts: getSelectOptionsData(contacts),
        categories: extractedGroupsWithChildren,
      }),
    [projects, contacts, extractedGroupsWithChildren]
  );

  const documentControls = useMemo(
    () =>
      getDocumentsConfig({
        contacts: getSelectOptionsData(contacts),
      }),
    [contacts]
  );
  const items: TabsProps['items'] = [];

  if (tabs.includes('transaction')) {
    items.push({
      key: 'transaction',
      label: translate('rules.modal.transaction.tabText'),
      children: (
        <RulesForm
          title={translate('rules.modal.transaction.ifTransactions')}
          onChange={handleChangeFormValues}
          controls={transactionControls}
          formValues={formValues.transaction}
          additionalButton={
            matchingTransactions.canBeShown
              ? {
                  title: matchingTransactions.totalPages
                    ? `${translate('rules.modal.transaction.previewMatchingTransactions')} (${matchingTransactions.totalPages})`
                    : translate(
                        'rules.modal.transaction.noMatchingTransactions'
                      ),
                  onClick: matchingTransactions.setIsOpen,
                }
              : undefined
          }
        />
      ),
    });
  }

  if (tabs.includes('file')) {
    items.push({
      key: 'file',
      label: translate('rules.modal.document.tabText'),
      children: (
        <RulesForm
          title={translate('rules.modal.document.ifDocuments')}
          onChange={handleChangeFormValues}
          controls={documentControls}
          formValues={formValues.file}
          additionalButton={
            matchingDocuments.canBeShown
              ? {
                  title: matchingDocuments.totalPages
                    ? `${translate('rules.modal.document.previewMatchingDocuments')} (${matchingDocuments.totalPages})`
                    : translate('rules.modal.document.noMatchingDocuments'),
                  onClick: matchingDocuments.setIsOpen,
                }
              : undefined
          }
        />
      ),
    });
  }

  if (items.length > 1) {
    return (
      <Tabs
        defaultActiveKey={activeTab}
        items={items}
        onChange={handleChangeActiveTab}
        // TODO fix at modal footer level
        style={{ marginBottom: 12 }}
      />
    );
  }

  return items[0].children;
};
