import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageEmptyState } from '@entities';
import { Button } from '@ui-kit/Button/Button';

import Instruction1Image from './images/instruction1.svg?react';
import Instruction2Image from './images/instruction2.svg?react';
import Instruction3Image from './images/instruction3.svg?react';

const features = [
  t('transaction.empty.features.syncAutomatically'),
  t('transaction.empty.features.safeAndSecure'),
  t('transaction.empty.features.reports'),
  t('transaction.empty.features.manualUpload'),
];

const instructions = [
  {
    title: <Trans i18nKey="transaction.empty.instructions.1" />,
    image: <Instruction1Image />,
  },
  {
    title: <Trans i18nKey="transaction.empty.instructions.2" />,
    image: <Instruction2Image />,
  },
  {
    title: <Trans i18nKey="transaction.empty.instructions.3" />,
    image: <Instruction3Image />,
  },
];

const description = [
  t('transaction.empty.description.1'),
  t('transaction.empty.description.2'),
];

const EmptyTransactions = () => {
  const navigate = useNavigate();

  const handleConnectClick = () => {
    navigate('/accounts');
  };

  return (
    <PageEmptyState
      title={t('transaction.empty.title')()}
      description={description}
      features={features}
      action={
        <Button type="primary" onClick={handleConnectClick}>
          {t('transaction.empty.connect')()}
        </Button>
      }
      instructions={instructions}
    />
  );
};

export default EmptyTransactions;
