import { Button, Col, Flex, Upload } from 'antd';

import styled from '@emotion/styled';
import { colors } from '@theme';

type Status = 'inProgress' | 'completed' | 'errorsFound' | null;

type StepProps = {
  status: Status;
  selected: boolean;
};

const getBadgeColorByStatus = (status?: Status, selected?: boolean) => {
  if (status === 'completed' || status === 'inProgress' || selected) {
    return {
      bg: colors.primary,
      color: colors.white,
    };
  }

  if (status === 'errorsFound') {
    return {
      bg: colors.error,
      color: colors.white,
    };
  }

  return {
    bg: colors.strokeGrey,
    color: colors.textBlue400,
  };
};

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .ui-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .ui-form-item {
      margin-bottom: 0;

      .ui-form-item-label {
        label {
          font-size: 12px;
        }
      }
    }
  }
`;

export const Inner = styled.div`
  margin-bottom: 24px;
`;

export const AddressFormWrapper = styled.div`
  border-radius: 8px;
  padding: 14px 20px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
`;

export const Wrap = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgBrightBlue};
  flex-wrap: wrap;
  row-gap: 8px;
  padding-bottom: 12px;
`;

export const FormLabel = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  margin-bottom: 4px;
`;

export const UploadFileCol = styled(Col)`
  &&.ui-col {
    .ui-form-item {
      margin-bottom: 0;
    }
  }
`;

export const UploadFile = styled(Upload.Dragger)`
  &&.ui-upload-wrapper {
    &&.ui-upload,
    .ui-upload-drag {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const UploadContent = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const UploadInner = styled(Flex)`
  flex-direction: column;
  gap: 6px;
  align-items: center;
`;

export const UploadButton = styled(Button)`
  font-weight: 600;
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FileItem = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  gap: 12px;
  padding: 12px;
`;

export const FileName = styled.a`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FileItemError = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  padding-left: 12px;
`;

export const Uploading = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  gap: 12px;
  padding: 12px 16px;

  .ui-spin {
    .ui-spin-dot {
      font-size: 24px;
    }
  }
`;

export const StepNumber = styled.div<Partial<StepProps>>`
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ selected, status }) =>
    getBadgeColorByStatus(status, selected).bg};
  color: ${({ selected, status }) =>
    getBadgeColorByStatus(status, selected).color};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
`;
