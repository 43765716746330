import { Flex, Button as ButtonBase } from 'antd';
import styled from '@emotion/styled';

export const Fieldset = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.grey100};
  padding: 14px 20px;
  border-radius: 8px;
`;

export const Warning = styled.div`
  background-color: ${({ theme }) => theme.colors.bgLightYellow};
  padding: 12px;
  border-radius: 12px;
`;

export const Button = styled(ButtonBase)`
  height: 46px;
`;
