import { Button, Flex, Popover } from 'antd';
import { FC } from 'react';

import { IconAscending, IconDescending, IconSort } from '@assets';
import { Select } from '@components';

export type FilterColumn = {
  label: string;
  value: string;
};

type FilterSortProps = {
  columns: FilterColumn[];
  onChange: (value: string, event: string) => void;
};

const FilterSort: FC<FilterSortProps> = ({ columns, onChange }) => (
  <Popover
    content={
      <Flex gap={12}>
        <Select
          options={columns}
          placeholder={t('common.sorting.column')()}
          onChange={(value: string) => onChange('sortBy', value)}
          size="large"
          data-testid="sorting-column-select"
        />

        <Select
          options={[
            {
              label: (
                <Flex justify="space-between" align="center">
                  {t('common.sorting.ascending')()}
                  <IconAscending />
                </Flex>
              ),
              value: 'ASC',
            },
            {
              label: (
                <Flex justify="space-between" align="center">
                  {t('common.sorting.descending')()} <IconDescending />
                </Flex>
              ),
              value: 'DESC',
            },
          ]}
          placeholder={t('common.sorting.direction')()}
          onChange={(value: string) => onChange('sortDirection', value)}
          size="large"
          data-testid="sorting-direction-select"
        />
      </Flex>
    }
    trigger="click"
    placement="bottom"
  >
    <Button
      icon={<IconSort width={24} />}
      color="primary"
      variant="filled"
      size="small"
      data-testid="filterSort-open-button"
    />
  </Popover>
);

export default FilterSort;
