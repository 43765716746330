import { Schemas } from '@api-client/generated/types';

type ChatMessage = Schemas.ChatMessage;
type User = Schemas.User;

type GroupedMessages = {
  author: User;
  messages: ChatMessage[];
};

export const getGroupedMessagesById = (
  messages: ChatMessage[]
): GroupedMessages[] => {
  const pushGroup = (message: ChatMessage) => {
    groupedMessages.push({
      author: message.author,
      messages: [message],
    });
  };

  const groupedMessages: GroupedMessages[] = [];

  messages.forEach((message) => {
    const currentGroup = groupedMessages[groupedMessages.length - 1];

    if (!currentGroup || message.author.id !== currentGroup.author.id) {
      return pushGroup(message);
    }

    currentGroup.messages.push(message);
  });

  return groupedMessages;
};

export const isUnread = (
  message: { seenData: ChatMessage['seenData'] },
  accountId: string
) => !message.seenData.some((data) => data.userId === accountId);

export const shouldShowUnreadDivider = (
  groupedMessages: { messages: ChatMessage[] }[],
  accountId: string,
  groupIndex: number,
  messageIndex: number
) => {
  const previousMessage = !messageIndex
    ? groupedMessages[groupIndex - 1]?.messages.at(-1)
    : groupedMessages[groupIndex].messages[messageIndex - 1];

  return (
    previousMessage &&
    !isUnread(previousMessage, accountId) &&
    isUnread(groupedMessages[groupIndex].messages[messageIndex], accountId)
  );
};
