import { Typography } from 'antd';
import { type Dayjs } from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { FC, useEffect, useMemo, useRef } from 'react';

import { Amount } from '@entities';

import * as config from './config';
import * as S from './styled';

type ReportOverviewProps = {
  title: string;
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
  loading?: boolean;
  dataSource: config.ReportRow[];
  flowType: config.FlowType;
};

const { Title } = Typography;

const ReportOverview: FC<ReportOverviewProps> = ({
  dateFrom,
  dateTo,
  title,
  dataSource,
  loading,
  flowType,
}) => {
  const refPie = useRef<ReactECharts | null>(null);
  const refBar = useRef<ReactECharts | null>(null);

  const pieInstance = refPie.current?.getEchartsInstance();
  const barInstance = refBar.current?.getEchartsInstance();

  const totalAmount = config.getTotalAmount(dataSource, flowType);

  useEffect(() => {
    if (pieInstance && dataSource) {
      const groupedData = config.groupByCategoryName(dataSource);

      pieInstance.setOption({
        series: [
          {
            data: config.createPieSerieData(groupedData, dataSource),
          },
        ],
      });
    }
  }, [pieInstance, dataSource]);

  useEffect(() => {
    if (barInstance && dataSource) {
      const groupedData = config.groupByCategoryName(dataSource);

      barInstance.setOption({
        series: config.createBarSerieData(groupedData),
      });
    }
  }, [barInstance, dataSource]);

  useEffect(() => {
    if (barInstance) {
      barInstance.setOption({
        xAxis: {
          data: config.setXAxisDataByInterval({
            dateFrom,
            dateTo,
          }),
        },
      });
    }
  }, [barInstance, dateFrom, dateTo]);

  const ChartPie = useMemo(
    () => (
      <ReactECharts
        ref={(e) => (refPie.current = e)}
        showLoading={loading}
        option={config.optionPie}
        style={{ width: '100%', height: '240px' }}
      />
    ),
    [loading]
  );

  const ChartBar = useMemo(
    () => (
      <ReactECharts
        ref={(e) => (refBar.current = e)}
        showLoading={loading}
        option={config.optionBar}
        style={{ width: '100%', height: '300px' }}
      />
    ),
    [loading]
  );

  return (
    <S.Card>
      <Title level={3}>{title}</Title>

      <S.Grid>
        <S.GridLeft>
          {ChartPie}

          <S.TotalAmount>
            <Amount amount={totalAmount} currencyCode="EUR" />
          </S.TotalAmount>
        </S.GridLeft>

        <S.GridRight>{ChartBar}</S.GridRight>
      </S.Grid>
    </S.Card>
  );
};

export default ReportOverview;
