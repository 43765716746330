import { Flex, Form, Input, Select, Typography, message } from 'antd';
import { FC, PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { KYCFiles } from '@entities';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

import * as S from './styled';

type KYCExtractProps = {
  person: Schemas.Person;
  selectedType: string | null;
  selectedStepValue: string;
};

const { Paragraph, Title } = Typography;

const KYCExtract: FC<PropsWithChildren<KYCExtractProps>> = ({
  person,
  selectedType,
  selectedStepValue,
}) => {
  const { translate } = useTranslate();

  const { companyId } = useAccount();

  const { incorporationDetails, updateIncorporation } = useOnboarding();

  const { mutate: update } = useUpdateIncorporation();

  const [form] = Form.useForm();

  const onFinish = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      ...form.getFieldsValue(),
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>{translate('onboarding.kyc.legal.extract')}</Title>

        <Paragraph type="secondary">
          {translate('onboarding.kyc.legalDetails.extract')}
        </Paragraph>
      </div>

      <Form
        form={form}
        layout="vertical"
        colon={false}
        initialValues={person.kycData}
      >
        <Form.Item
          name="hasExtract"
          label={translate('onboarding.kyc.legalKycForm.hasExtract.label')}
        >
          <Select
            placeholder={translate(
              'onboarding.kyc.legalKycForm.hasExtract.placeholder'
            )}
            options={[
              {
                label: translate(
                  'onboarding.kyc.legalKycForm.hasExtract.values.yes'
                ),
                value: 'yes',
              },
              {
                label: translate(
                  'onboarding.kyc.legalKycForm.hasExtract.values.no'
                ),
                value: 'no',
              },
            ]}
            size="large"
            onChange={onFinish}
            virtual
          />
        </Form.Item>

        {person.kycData?.hasExtract === 'no' && (
          <S.ExtractHelp>
            <Form.Item
              name="hasRequestedExtract"
              label={translate(
                'onboarding.kyc.legalKycForm.hasRequestedExtract.label'
              )}
            >
              <Select
                placeholder={translate(
                  'onboarding.kyc.legalKycForm.hasRequestedExtract.placeholder'
                )}
                options={[
                  {
                    label: translate(
                      'onboarding.kyc.legalKycForm.hasRequestedExtract.values.yes'
                    ),
                    value: 'yes',
                  },
                  {
                    label: translate(
                      'onboarding.kyc.legalKycForm.hasRequestedExtract.values.no'
                    ),
                    value: 'no',
                  },
                ]}
                size="large"
                onChange={onFinish}
                virtual
              />
            </Form.Item>

            {person.kycData?.hasRequestedExtract === 'yes' && (
              <Form.Item
                name="rcsNumber"
                label={translate('onboarding.kyc.legal.rcsNumber')}
              >
                <Input size="large" placeholder="B123456" onBlur={onFinish} />
              </Form.Item>
            )}

            {person.kycData?.hasRequestedExtract === 'no' && (
              <Trans
                i18nKey="onboarding.kyc.legal.rcsHint"
                components={[
                  <Link
                    to="https://www.lbr.lu/mjrcs/jsp/IndexActionNotSecured.action?time=1717599476297&loop=1"
                    target="_blank"
                  />,
                ]}
              />
            )}
          </S.ExtractHelp>
        )}
      </Form>

      {person.kycData?.hasExtract === 'yes' && (
        <KYCFiles
          person={person}
          selectedType={selectedType}
          selectedStepValue={selectedStepValue}
        />
      )}
    </Flex>
  );
};

export default KYCExtract;
