import { Button, Flex, Typography } from 'antd';

import { IconEye, IconMoreVertical } from '@assets';
import styled from '@emotion/styled';

export const BlockTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const AttachButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  height: 20px;
  gap: 4px;
  align-items: center;
  padding: 0;

  && {
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const SupplementaryDocuments = styled(Flex)`
  padding: 20px;
  background: ${({ theme }) => theme.colors.badgeGrey};
  height: 100%;
  border-bottom-right-radius: 20px;
`;

export const SupplementaryItem = styled(Flex)`
  padding: 12px 0;

  :hover {
    .supplementary-item-actions {
      display: flex;
      gap: 12px;
    }
  }
`;

export const SupplementaryItemActions = styled.div`
  display: none;
  padding-left: 12px;
`;

export const SupplementaryItemInner = styled(Flex)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SupplementaryItemTitle = styled(Typography.Paragraph)`
  && {
    width: 100%;
    height: 20px;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0;
  }
`;

export const NoDocuments = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.textDisabled};
`;

export const ViewIcon = styled(IconEye)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const MoreIcon = styled(IconMoreVertical)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Actions = styled.div`
  width: 233px;
`;

export const AttachNew = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text400};
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue};
  }
`;

export const UploadWrapper = styled.div`
  && .ui-upload {
    width: 100%;
  }
`;

export const UploadExisting = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text400};
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue};
  }
`;
