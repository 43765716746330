import { Button, Col, Flex, Form, Input, Row, message } from 'antd';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconTrash } from '@assets';
import { Card, InputBorderless, Select } from '@components';
import { PROJECT_STATUSES } from '@constants';
import { PageContainerWithBar, PopupConfirm } from '@entities';
import { useAccount, useTranslate } from '@hooks';
import {
  useCreateProject,
  useDeleteProject,
  useUpdateProjectById,
} from '@hooks-api';
import { LocalePaths } from '@locales';

import * as S from './styled';

type ProjectContentProps = {
  id?: string;
  project: Schemas.Project | null;
  headerContent: ReactNode;
  type: 'view' | 'create';
};

const ProjectContent: FC<ProjectContentProps> = ({
  id,
  project,
  headerContent,
  type,
}) => {
  const navigate = useNavigate();
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const [form] = Form.useForm();

  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);

  const { mutate: createProject, isPending: loadingCreate } =
    useCreateProject();
  const { mutate: updateProject, isPending: loadingUpdate } =
    useUpdateProjectById();
  const { mutate: deleteProjectById } = useDeleteProject();

  const isView = type === 'view';
  const isCreate = type === 'create';

  useEffect(() => {
    if (project) {
      form.setFieldsValue({
        name: project.name,
        status: project.status,
        notes: project.notes,
      });
    }
  }, [form, project]);

  const onFinish = (values: Schemas.ProjectDto) => {
    if (isView) {
      updateProject(
        {
          parameter: {
            id: id!,
            companyId: companyId!,
          },
          requestBody: values,
        },
        {
          onSuccess: () => {
            message.open({
              type: 'success',
              content: translate('projects.success.update'),
            });
          },
        }
      );
    }

    if (isCreate) {
      createProject(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: values,
        },
        {
          onSuccess: (response) => {
            message.open({
              type: 'success',
              content: translate('projects.success.create'),
            });

            navigate(`/projects/${response.id}`);
          },
        }
      );
    }
  };

  const handleRemove = (id: string) =>
    deleteProjectById(
      {
        parameter: {
          id: id!,
          companyId: companyId!,
        },
      },
      {
        onSuccess: () => {
          setIsOpenPopupConfirm(false);

          navigate('/projects');

          message.open({
            type: 'success',
            content: translate('projects.success.delete'),
          });
        },
      }
    );

  return (
    <Form
      form={form}
      layout="vertical"
      colon={false}
      onFinish={onFinish}
      requiredMark={false}
    >
      <PopupConfirm
        open={isOpenPopupConfirm}
        title={translate('projects.popupDelete.title')}
        description={translate('projects.popupDelete.description')}
        confirmParams={{
          text: translate('projects.popupDelete.buttonConfirm'),
        }}
        onConfirm={() => handleRemove(id!)}
        onCancel={() => setIsOpenPopupConfirm(false)}
      />

      <PageContainerWithBar
        fixedContent={
          <Flex align="center" justify="flex-end">
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingCreate || loadingUpdate}
            >
              {translate('projects.buttonSave')}
            </Button>
          </Flex>
        }
      >
        <Flex gap={24} vertical>
          {headerContent}

          <Flex gap={30} vertical>
            <Flex align="center" justify="space-between">
              <S.FormItem name="name">
                <InputBorderless
                  placeholder={translate('projects.name.placeholder')}
                />
              </S.FormItem>

              {isView && (
                <S.ButtonDelete
                  type="text"
                  size="small"
                  onClick={() => setIsOpenPopupConfirm(true)}
                  danger
                >
                  {translate('projects.buttonDelete')}
                  <IconTrash />
                </S.ButtonDelete>
              )}
            </Flex>

            <Row gutter={[48, 30]}>
              <Col span={14}>
                <Card>
                  <Row>
                    <Col span={12}>
                      <S.FormItem
                        name="status"
                        label={translate('projects.form.status.label')}
                      >
                        <Select
                          placeholder={translate(
                            'projects.form.status.placeholder'
                          )}
                          options={PROJECT_STATUSES.map((status) => ({
                            label: translate(
                              `common.statuses.${status}` as LocalePaths
                            ),
                            value: status,
                          }))}
                          size="large"
                        />
                      </S.FormItem>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={10}>
                <S.FormItem
                  name="notes"
                  label={translate('projects.form.notes.label')}
                >
                  <Input.TextArea
                    placeholder={translate('projects.form.notes.placeholder')}
                    rows={4}
                    size="large"
                  />
                </S.FormItem>
              </Col>
            </Row>
          </Flex>
        </Flex>
      </PageContainerWithBar>
    </Form>
  );
};

export default ProjectContent;
