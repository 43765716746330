import { Button as AntdButton } from 'antd';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

const xsStyles = css`
  && {
    --ui-button-content-font-size: 12px;
    --ui-border-radius: 8px;
    --ui-button-padding-inline: 16px;
    --ui-control-height: 32px;

    --ui-icon-size: 16px;
    --ui-button-padding-inline-start: var(--ui-button-padding-inline-sm);
    --ui-button-padding-inline-end: var(--ui-button-padding-inline-sm);
    --ui-line-height: 20px;

    &.ui-btn-link {
      --ui-control-height: 28px;
    }
  }
`;

export type ButtonProps = Omit<
  Parameters<typeof BaseButton>[0],
  'size' | 'type'
> & {
  size?: Parameters<typeof BaseButton>[0]['size'] | 'xs';
  type?: 'link' | 'primary' | 'dashed' | 'text';
  isFullWidth?: boolean;
};

export const Button = ({ size, ...props }: ButtonProps) =>
  size === 'xs' ? (
    <BaseButton {...props} css={xsStyles} />
  ) : (
    <BaseButton {...props} size={size} />
  );

type BaseButtonProps = {
  isFullWidth?: boolean;
};

export const BaseButton = styled(AntdButton, {
  shouldForwardProp: (prop) => !['isFullWidth'].includes(prop),
})<BaseButtonProps>`
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'max-content')};
`;
