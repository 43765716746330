import { App as AppDesign, ConfigProvider } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IoProvider } from 'socket.io-react-hook';

import { AccountProvider, AppStateProvider } from '@context';
import { ModalManagerProvider } from '@context/ModalManager/ModalManager';
import { ThemeProvider } from '@emotion/react';
import { ErrorFallback } from '@entities/errorBoundary/ErrorFallback';
import { ErrorBoundary } from '@sentry/react';
import { GlobalStyles } from '@ui-kit/theme/GlobalStyles';
import { colors, config } from '@ui-kit/theme/theme';

import AppRouter from './AppRouter';

const queryClient = new QueryClient();

const App = () => (
  <ErrorBoundary
    fallback={(errorData) => <ErrorFallback {...errorData} fullPage />}
  >
    <QueryClientProvider client={queryClient}>
      <IoProvider>
        <ConfigProvider prefixCls="ui" iconPrefixCls="ui-icon" theme={config}>
          <ThemeProvider theme={{ colors }}>
            <AppDesign>
              <GlobalStyles />
              <AppStateProvider>
                <AccountProvider>
                  <DndProvider backend={HTML5Backend}>
                    <ModalManagerProvider>
                      <AppRouter />
                    </ModalManagerProvider>
                  </DndProvider>
                </AccountProvider>
              </AppStateProvider>
            </AppDesign>
          </ThemeProvider>
        </ConfigProvider>
      </IoProvider>
    </QueryClientProvider>
  </ErrorBoundary>
);

export default App;
