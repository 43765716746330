import { Button, Divider, Flex, Typography } from 'antd';
import { FC, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconEditUnderline } from '@assets';
import { Card, Loader } from '@components';

import * as S from './styled';

type PaymentMethodResponseDto = Schemas.PaymentMethodResponseDto;

const { Text } = Typography;

type BillingPaymentDetailsProps = {
  isLoading?: boolean;
  emails?: string[];
  paymentMethod?: PaymentMethodResponseDto;
  onChangeEmailClick: () => void;
  onCardEditOpenClick: () => Promise<void>;
};

const BillingPaymentDetails: FC<BillingPaymentDetailsProps> = ({
  isLoading,
  emails = [],
  paymentMethod,
  onCardEditOpenClick,
  onChangeEmailClick,
}) => {
  const title = t('settings.billing.paymentDetails.title')();
  const [isOpening, setOpening] = useState(false);

  if (isLoading) {
    return (
      <Card title={title}>
        <Loader />
      </Card>
    );
  }

  const handleEditCardClick = async () => {
    setOpening(true);
    await onCardEditOpenClick();
    setOpening(false);
  };

  return (
    <Card title={title}>
      <Flex gap={20} vertical>
        <Flex justify="space-between" gap={16}>
          <Flex gap={4} vertical>
            <S.Title>
              {t('settings.billing.paymentDetails.paymentMethod.title')()}
            </S.Title>
            <S.Description>
              {t('settings.billing.paymentDetails.paymentMethod.description')()}
            </S.Description>
          </Flex>
          <Flex justify="center" align="center">
            {paymentMethod ? (
              <Flex align="center" gap={32}>
                <Text>{paymentMethod.name}</Text>
                <Button
                  onClick={handleEditCardClick}
                  disabled={isOpening}
                  loading={isOpening}
                >
                  {t(
                    'settings.billing.paymentDetails.paymentMethod.actions.update'
                  )()}
                </Button>
              </Flex>
            ) : (
              <Button type="primary" onClick={onCardEditOpenClick}>
                {t(
                  'settings.billing.paymentDetails.paymentMethod.actions.add'
                )()}
              </Button>
            )}
          </Flex>
        </Flex>
        <Divider />
        <Flex justify="space-between" gap={16}>
          <Flex gap={4} vertical>
            <S.Title>
              {t('settings.billing.paymentDetails.billingEmail.title')()}
            </S.Title>
            <S.Description>
              {t('settings.billing.paymentDetails.billingEmail.description')()}
            </S.Description>
          </Flex>
          <Flex justify="flex-end" align="center">
            <Flex gap={16} align="center">
              <S.Email>
                {emails[0] || ''}
                {emails.length > 1 ? ` (+${emails.length - 1})` : ''}
              </S.Email>
              <S.EditButton onClick={onChangeEmailClick} type="text">
                <IconEditUnderline width={24} height={24} />
              </S.EditButton>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default BillingPaymentDetails;
