import { FC, useEffect, useState } from 'react';

import { PaymentDocumentUnit } from '@constants';

import * as S from './styled';

type ControlType = 'unpaid' | 'paid';

type PaymentDocumentSwitchControlProps = {
  type: PaymentDocumentUnit;
  status: string;
  onChange?: (type: ControlType) => void;
};

const PaymentDocumentSwitchControl: FC<PaymentDocumentSwitchControlProps> = ({
  status,
  onChange,
}) => {
  const [selectedKey, setSelectedKey] = useState<string>(status);

  useEffect(() => {
    setSelectedKey(status);
  }, [status]);

  const handleChange = (type: ControlType) => {
    setSelectedKey(type);
    onChange?.(type);
  };

  return (
    <S.Container align="center">
      <S.Control
        type="unpaid"
        onClick={() => handleChange('unpaid')}
        active={selectedKey === 'unpaid'}
      >
        {t('income.details.tabs.unpaid')()}
      </S.Control>

      <S.Control
        type="paid"
        onClick={() => handleChange('paid')}
        active={selectedKey === 'paid'}
      >
        {t('income.details.tabs.paid')()}
      </S.Control>
    </S.Container>
  );
};

export default PaymentDocumentSwitchControl;
