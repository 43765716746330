import { Params$AuthController_login } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

type Params = Params$AuthController_login;

const useAuthLogIn = () => useApiClientMutation<Params, Schemas.UserJwtResponse>({
    method: 'AuthController_login',
  });

export default useAuthLogIn;
