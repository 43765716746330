import { Flex } from 'antd';
import { FC } from 'react';

import { StatusCircle } from '@entities';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type TransactionIngestAlertUploadedProps = {
  onShowAll: () => void;
  onManageImports: () => void;
};

const TransactionIngestAlertUploaded: FC<
  TransactionIngestAlertUploadedProps
> = ({ onManageImports, onShowAll }) => (
  <S.Alert align="center" justify="space-between" gap={16}>
    <S.AlertTitle>
      <Flex align="center" gap={12}>
        <StatusCircle status="completed" size={20} />
        {t('csvImports.alertUploadedFile.title')()}
      </Flex>
    </S.AlertTitle>

    <Flex align="center" gap={16}>
      <Button type="link" onClick={onManageImports}>
        {t('csvImports.buttonManageImports')()}
      </Button>

      <Button type="primary" onClick={onShowAll}>
        {t('csvImports.alertUploadedFile.buttonShowAll')()}
      </Button>
    </Flex>
  </S.Alert>
);

export default TransactionIngestAlertUploaded;
