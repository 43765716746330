import { Flex, Form } from 'antd';

import styled from '@emotion/styled';
import { Amount as AmountBase, DateText as DateTextBase } from '@entities';

export const Details = styled(Flex)`
  padding: 20px 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  background-color: ${({ theme }) => theme.colors.strokeDarkGrey};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const FullWidth = styled(Flex)`
  width: 100%;
`;

export const Content = styled.div`
  padding: 15px 20px 20px;
`;

export const LogoWrapper = styled(Flex)`
  border-radius: 40px;
  border: 1px solid ${({ theme }) => theme.colors.textDisabled};
`;

export const Logo = styled.img`
  border-radius: 40px;
  width: 40px;
  height: 40px;
`;

export const Item = styled(Form.Item)`
  margin-bottom: 0;
`;

export const TooltipTitleBox = styled.div`
  width: 138px;
`;

export const Breadcrumbs = styled.span`
  font-size: 9px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text200};
`;

export const Description = styled.span`
  font-size: 16px;
  line-height: 24px;
`;

export const Amount = styled(AmountBase)`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

export const DateText = styled(DateTextBase)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text400};
`;
