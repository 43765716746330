import { Flex } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { DEFAULT_CURRENCY_CODE } from '@constants';
import { Amount, CutCornerCard } from '@entities';
import { useTranslate } from '@hooks';

import * as S from './styled';

type DocumentFile = Schemas.Document;

type ContactsPaymentDocItemProps = {
  file: DocumentFile;
  onView: () => void;
};

const ContactsPaymentDocItem: FC<ContactsPaymentDocItemProps> = ({
  file,
  onView,
}) => {
  const { translate } = useTranslate();

  return (
    <S.Container>
      <CutCornerCard>
        <S.Inner justify="space-between" vertical>
          <Flex gap={6} vertical>
            <S.Label>
              {translate('contactsPage.paymentDocuments.label')}
            </S.Label>

            <S.Name>
              {file.documentMetadata?.number || file.contact?.name}
            </S.Name>

            <S.Description>{file.documentMetadata?.description}</S.Description>
          </Flex>

          <Flex className="card-details" align="center" justify="space-between">
            <S.Amount>
              <Amount
                amount={file.documentMetadata?.amount || 0}
                currencyCode={
                  file.documentMetadata?.currency || DEFAULT_CURRENCY_CODE
                }
              />
            </S.Amount>

            <S.Date>
              {dayjs(file.documentMetadata?.issueDate).format('DD.MM.YYYY')}
            </S.Date>
          </Flex>

          <S.Actions className="card-actions">
            <S.ViewButton type="link" onClick={onView}>
              {translate('common.actions.view')}
            </S.ViewButton>
          </S.Actions>
        </S.Inner>
      </CutCornerCard>
    </S.Container>
  );
};

export default ContactsPaymentDocItem;
