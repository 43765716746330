import { Flex, Row, Typography } from 'antd';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SearchControl } from '@entities';
import { mediaWidth } from '@ui-kit/theme/mediaWidth';

const { Title } = Typography;

export const AccountsWrapper = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 12px;
  height: min-content;
  width: 385px;
  min-height: 369px;
`;

export const Heading = styled(Title)`
  && {
    font-size: 28px;
    margin-bottom: 0;
  }
`;

export const SubHeading = styled(Title)`
  && {
    &.ui-typography {
      font-size: 24px;
      margin-bottom: 2px;
    }
  }
`;

export const BanksWrapper = styled.div`
  width: 100%;
  padding: 24px 32px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  background: ${({ theme }) => theme.colors.white};
`;

export const HeaderRow = styled(Row)`
  ${mediaWidth.laptopM(css`
    display: flex;
    flex-direction: column;

    && .ui-col-12 {
      width: 100%;
      max-width: 100%;
    }
  `)};
`;

export const HeaderTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`;

export const Search = styled(SearchControl)`
  margin-top: 28px;

  && {
    width: 100%;
  }

  ${mediaWidth.laptopL(css`
    margin: 28px 0;
  `)};
`;

export const AddBank = styled(Flex)`
  border-radius: 12px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.badgeGrey};
  width: 302px;
  height: fit-content;

  ${mediaWidth.laptopL(css`
    width: 100%;
  `)};
`;

export const BankIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.text200};
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colors.white};
`;

export const FindBankQuestion = styled.span`
  font-weight: 600;
  line-height: 20px;
`;

export const AddBankLink = styled.span`
  cursor: pointer;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primary};
`;
