import { Flex } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useTransactionIngestController_findAll } from '@api-client/generated/TransactionIngestController/findAll';
import { Schemas } from '@api-client/generated/types';
import { IconPlus } from '@assets';
import { Loader, Scrollbar } from '@components';
import {
  FilterConstructor,
  FilterConstructorDrawer,
  PageMeta,
  TransactionIngestCard,
  TransactionIngestImport,
  TransactionIngestList,
} from '@entities';
import { useAccount } from '@hooks';
import { Button } from '@ui-kit/Button/Button';
import { getSearchParams } from '@utils';

import * as S from './styled';

type TransactionIngest = Schemas.TransactionIngest;

type FilterOptions = {
  term: string;
  accountIds: string[];
  createdAt: [Dayjs, Dayjs];
};

const CSVImports: FC = () => {
  const { search } = useLocation();
  const { id: ingestId } = useParams();
  const navigate = useNavigate();

  const { companyId } = useAccount();

  const searchParams = getSearchParams<FilterOptions>(search);

  const [filteredList, setFilteredList] = useState<TransactionIngest[]>([]);
  const [selectedIngest, setSelectedIngest] =
    useState<TransactionIngest | null>(null);

  const [modalImportVisible, setModalImportVisible] = useState(false);

  const [filterOptions, setFilterOptions] =
    useState<FilterOptions>(searchParams);

  const [filterDrawerOptions, setFilterDrawerOptions] = useState<
    Record<string, unknown> | string[] | null
  >(null);

  const [isEmptyAccount, setIsEmptyAccount] = useState(false);

  const {
    data: list = [],
    isPending: isLoadingList,
    isFetching: isFetchingList,
    refetch: refetchList,
  } = useTransactionIngestController_findAll({
    params: {
      companyId: companyId!,
    },
    config: {
      refetchOnWindowFocus: false,
    },
  });

  useEffect(() => {
    if (list) {
      setFilteredList(list);

      if (list.length) {
        const selectedImport = list.filter(
          (item) => item.id === ingestId || item.account.id === ingestId
        )[0];

        navigate(
          `/csv-imports/${ingestId ? (selectedImport ? selectedImport.id : ingestId) : list[0].id}`
        );

        setSelectedIngest(ingestId ? selectedImport : list[0]);
        setIsEmptyAccount(!selectedImport);
      }
    }
  }, [ingestId, list, navigate]);

  useEffect(() => {
    if (ingestId) {
      setIsEmptyAccount(false);
    }
  }, [ingestId]);

  const handleAfterImport = (accountId: string) => {
    refetchList();
    navigate(`/transactions-import/${accountId}`);
  };

  const handleFilterChange = (values: FilterOptions) => {
    const filteredList = _.filter(list, (item) => {
      const isNameMatch = values.term
        ? item.account.name.toLowerCase().includes(values.term.toLowerCase())
        : true;

      const isAccountIdMatch = values.accountIds
        ? values.accountIds.includes(item.account.id)
        : true;

      const isDateInRange = values.createdAt
        ? dayjs(item.createdAt).isAfter(values.createdAt[0]) &&
          dayjs(item.createdAt).isBefore(values.createdAt[1])
        : true;

      return isNameMatch && isAccountIdMatch && isDateInRange;
    });

    setFilteredList(filteredList);
    setFilterOptions({ ...values });
  };

  const handleSelectIngest = (item: TransactionIngest) => {
    setSelectedIngest(item);
    navigate(`/csv-imports/${item.id}${search}`);
  };

  if (isLoadingList) {
    return <Loader />;
  }

  return (
    <Flex gap={24} vertical>
      <PageMeta title={t('csvImports.title')()} />

      <TransactionIngestImport
        open={modalImportVisible}
        onAfterImport={handleAfterImport}
        onCancel={() => setModalImportVisible(false)}
      />

      <Flex align="flex-start" gap={30}>
        <S.List>
          <Flex gap={24} vertical>
            <S.Header align="center" justify="space-between">
              <S.Title>{t('csvImports.title')()}</S.Title>

              <Flex align="center" gap={12}>
                <FilterConstructorDrawer
                  initialParams={filterOptions}
                  controls={[
                    {
                      label: t('csvImports.filter.bank')(),
                      type: 'list-banks',
                      formName: 'accountIds',
                    },
                    {
                      label: t('csvImports.filter.date')(),
                      type: 'range-picker',
                      formName: 'createdAt',
                    },
                  ]}
                  buttonParams={{
                    type: 'link',
                    variant: 'link',
                    size: 'middle',
                  }}
                  onSubmit={setFilterDrawerOptions}
                />

                <Button
                  type="link"
                  icon={<IconPlus />}
                  onClick={() => setModalImportVisible(true)}
                />
              </Flex>
            </S.Header>

            <FilterConstructor<TransactionIngest, FilterOptions>
              externalParameters={filterDrawerOptions}
              controls={{
                left: [
                  {
                    type: 'search',
                    formName: 'term',
                    params: {
                      width: 340,
                    },
                  },
                ],
              }}
              filterControls={['accountIds', 'createdAt']}
              onChange={handleFilterChange}
              withSearchParams
            />

            <Scrollbar height="calc(100vh - 310px)" withoutPadding>
              <TransactionIngestList
                selectedId={ingestId || selectedIngest?.id}
                dataSource={filteredList}
                loading={isFetchingList}
                onSelected={handleSelectIngest}
              />
            </Scrollbar>
          </Flex>
        </S.List>

        <TransactionIngestCard
          details={selectedIngest}
          isEmptyAccount={isEmptyAccount}
          onAfterDelete={refetchList}
        />
      </Flex>
    </Flex>
  );
};

export default CSVImports;
