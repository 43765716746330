import dayjs, { type Dayjs } from 'dayjs';
import * as echarts from 'echarts';
import { type EChartsOption } from 'echarts-for-react';
import { rgba } from 'emotion-rgba';

import { Parameter$TransactionController_getStatsByCategory } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { LocalePaths } from '@locales';
import { colors } from '@theme';
import { formatNumber, getDateRangeByInterval } from '@utils';

type DatePeriods = {
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
};

type DatePeriodsWithUnit = {
  unit: BreakDown;
} & DatePeriods;

type DifferenceData = {
  reportDate: string;
  value: number;
};

export type ReportRow = Schemas.TimeReportRow;

export type BreakDown =
  Parameter$TransactionController_getStatsByCategory['breakDown'];

type CreateOption = {
  translate: (key: LocalePaths) => void;
};

const serieColors = {
  moneyIn: '#3CCE99',
  moneyOut: '#FF6767',
  difference: {
    start: '#0066FF',
    end: '#8AB9FF',
  },
};

const createSerieBarOption = (color: string) => ({
  type: 'bar',
  barMaxWidth: 24,
  itemStyle: {
    borderRadius: 4,
    color,
  },
  zlevel: 1,
});

export const createOption: EChartsOption = ({ translate }: CreateOption) => ({
  grid: {
    top: 60,
    bottom: 0,
    right: 0,
    left: 10,
    containLabel: true,
  },
  title: {
    text: translate('report.overview.title'),
    textStyle: {
      color: colors.black,
      fontFamily: 'Inter',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  textStyle: {
    fontFamily: 'Inter',
  },
  toolbox: {
    show: false,
  },
  legend: {
    itemWidth: 8,
    itemHeight: 8,
    itemGap: 20,
    top: 4,
    left: 130,
    lineStyle: {
      inactiveWidth: 0,
    },
    textStyle: {
      color: colors.secondary,
      fontSize: 14,
    },
    data: [
      {
        name: translate('report.overview.moneyIn'),
        icon: 'circle',
        inactiveColor: rgba(colors.black, 0.7),
      },
      {
        name: translate('report.overview.moneyOut'),
        icon: 'circle',
        inactiveColor: rgba(colors.black, 0.7),
      },
      {
        name: translate('report.overview.difference'),
        inactiveColor: rgba(colors.black, 0.7),
        lineStyle: {
          inactiveWidth: 2,
        },
      },
    ],
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'none',
    },
    valueFormatter: (value: number) => formatNumber(value),
  },
  xAxis: {
    type: 'category',
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
    axisLabel: {
      color: rgba(colors.black, 0.7),
      margin: 16,
    },
  },
  yAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: rgba(colors.black, 0.7),
      },
    },
    axisLabel: {
      margin: 12,
    },
    splitLine: {
      lineStyle: {
        color: rgba(colors.black, 0.05),
      },
    },
  },
  series: [
    {
      name: translate('report.overview.difference'),
      type: 'line',
      symbol: 'circle',
      symbolSize: 12,
      smooth: true,
      lineStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: serieColors.difference.start,
          },
          {
            offset: 1,
            color: serieColors.difference.end,
          },
        ]),
        shadowColor: 'rgba(96, 91, 255, 0.17)',
        shadowBlur: 4,
        shadowOffsetY: 4,
      },
      itemStyle: {
        borderWidth: 3,
        borderColor: serieColors.difference.start,
        color: '#ffffff',
        shadowColor: 'rgba(96, 91, 255, 0.17)',
        shadowBlur: 4,
        shadowOffsetY: 4,
      },
    },
    {
      name: translate('report.overview.moneyIn'),
      ...createSerieBarOption(serieColors.moneyIn),
    },
    {
      name: translate('report.overview.moneyOut'),
      ...createSerieBarOption(serieColors.moneyOut),
    },
  ],
});

const getDataByUnit = (
  reportDate: Dayjs | Date | number | string,
  unit: BreakDown
) => {
  const date = dayjs(reportDate);

  return unit === 'quarter'
    ? `Q${date.quarter()}, ${date.format('YY')}`
    : date.format('MMM, YY');
};

export const setXAxisDataByInterval = ({
  dateFrom,
  dateTo,
  unit,
}: DatePeriodsWithUnit): string[] => {
  const intervalDates = getDateRangeByInterval(dateFrom, dateTo, unit);

  return intervalDates.map((item) => getDataByUnit(item, unit));
};

const getDateByPeriod = (period: string, filterPeriod: BreakDown) => {
  const date = dayjs(period);

  if (filterPeriod === 'month') {
    return date.format('MMM, YY');
  }

  if (filterPeriod === 'quarter') {
    return `Q${date.quarter()}, ${date.format('YY')}`;
  }
};

export const getDifferenceData = (data: ReportRow[]) =>
  data.map((item) => ({
    reportDate: item.reportDate,
    value: Number(item.sums[1]) + Number(item.sums[0]),
  }));

export const createSerieDifferenceData = (
  data: DifferenceData[],
  filterPeriod: BreakDown
) =>
  data.map((item) => ({
    value: [
      getDateByPeriod(item.reportDate, filterPeriod),
      Number(item.value).toFixed(2),
    ],
    itemStyle: {
      borderColor: item.value >= 0 ? serieColors.moneyIn : serieColors.moneyOut,
    },
  }));

export const createSerieBarData = (
  data: ReportRow[],
  index: number,
  filterPeriod: BreakDown
) =>
  data
    .map((item) => ({
      date: getDateByPeriod(item.reportDate, filterPeriod),
      sum: Number(item.sums[index]).toFixed(2),
    }))
    .map((item) => [
      item.date,
      Number(item.sum) > 0
        ? Number(item.sum).toFixed(2)
        : (Number(item.sum) * -1).toFixed(2),
    ]);
