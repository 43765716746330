import { PaymentDocumentUnit } from '@constants';

const getTranslatesByType = (type: PaymentDocumentUnit) =>
  type === 'income'
    ? {
        numberLabel: t('income.details.number.label')(),
        numberPlaceholder: t('income.details.number.placeholder')(),
        contactLabel: t('income.details.contact.label')(),
        contactPlaceholder: t('income.details.contact.placeholder')(),
        descriptionLabel: t('income.details.description.label')(),
        descriptionPlaceholder: t('income.details.description.placeholder')(),
        issueDateLabel: t('income.details.issueDate.label')(),
        issueDatePlaceholder: t('income.details.issueDate.placeholder')(),
        dueDateLabel: t('income.details.dueDate.label')(),
        dueDatePlaceholder: t('income.details.dueDate.placeholder')(),
        amountLabel: t('income.details.amount.label')(),
        amountPlaceholder: t('income.details.amount.placeholder')(),
        currencyLabel: t('income.details.currency.label')(),
        buttonSubmit: t('income.details.buttonSubmit')(),
        successfullyUpdated: t('income.successfullyUpdated')(),
      }
    : {
        numberLabel: t('expenses.details.number.label')(),
        numberPlaceholder: t('expenses.details.number.placeholder')(),
        contactLabel: t('expenses.details.contact.label')(),
        contactPlaceholder: t('expenses.details.contact.placeholder')(),
        descriptionLabel: t('expenses.details.description.label')(),
        descriptionPlaceholder: t('expenses.details.description.placeholder')(),
        issueDateLabel: t('expenses.details.issueDate.label')(),
        issueDatePlaceholder: t('expenses.details.issueDate.placeholder')(),
        dueDateLabel: t('expenses.details.dueDate.label')(),
        dueDatePlaceholder: t('expenses.details.dueDate.placeholder')(),
        amountLabel: t('expenses.details.amount.label')(),
        amountPlaceholder: t('expenses.details.amount.placeholder')(),
        currencyLabel: t('expenses.details.currency.label')(),
        buttonSubmit: t('expenses.details.buttonSubmit')(),
        successfullyUpdated: t('expenses.successfullyUpdated')(),
      };

export default getTranslatesByType;
