import { Button, type ButtonProps, Flex, Modal, type ModalProps } from 'antd';
import { FC, ReactNode } from 'react';

import * as S from './styled';

type ModalDeleteProps = {
  title: ReactNode;
  description: ReactNode;
  picture: ReactNode;
  confirmParams: { text: string } & ButtonProps;
  onRemove: () => void;
  onCancel: () => void;
} & ModalProps;

const ModalDelete: FC<ModalDeleteProps> = ({
  open,
  picture,
  title,
  description,
  confirmParams,
  onRemove,
  onCancel,
}) => (
  <Modal
    width={760}
    open={open}
    footer={null}
    onCancel={onCancel}
    closeIcon={null}
    destroyOnClose
    centered
  >
    <Flex gap={12} vertical>
      <Flex align="center" vertical>
        <S.Picture>{picture}</S.Picture>
        <S.Divider />
        <S.Title level={3}>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </Flex>

      <Flex align="center" justify="flex-end" gap={12}>
        <Button onClick={onCancel}>{t('common.actions.cancel')()}</Button>

        <Button type="primary" onClick={onRemove} danger {...confirmParams}>
          {confirmParams.text}
        </Button>
      </Flex>
    </Flex>
  </Modal>
);

export default ModalDelete;
