import { Alert, Drawer, Flex, Typography } from 'antd';
import { useState } from 'react';

import { usePaymentMethodController_delete } from '@api-client/generated/PaymentMethodController/delete';
import { usePaymentMethodController_finalize } from '@api-client/generated/PaymentMethodController/finalize';
import { IconStatusWarning } from '@assets';
import { BillingConfirmDeletingCardModal } from '@entities/index';
import { useAccount } from '@hooks';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import * as S from './styled';

const { Text } = Typography;

type AddCardDrawerProps = {
  clientSecret: string;
  open: boolean;
  hasCard: boolean;
  onClose: () => void;
};

const BillingAddCardDrawer = ({
  open,
  hasCard,
  clientSecret,
  onClose,
}: AddCardDrawerProps) => {
  const { companyId } = useAccount();
  const stripe = useStripe();
  const elements = useElements();

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { mutateAsync: deleteCard } = usePaymentMethodController_delete();
  const { mutateAsync: finialize } = usePaymentMethodController_finalize();

  const handleDeleteCardClick = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmDeleteCardClick = async () => {
    if (!companyId) return;

    await deleteCard({
      parameter: {
        companyId,
      },
    });

    setConfirmModalOpen(false);
    onClose();
  };

  const handleSubmit = async () => {
    if (!stripe || !elements || !companyId) {
      return;
    }

    try {
      setError(false);
      setLoading(true);
      const { error } = await elements.submit();

      if (error) {
        setLoading(false);
        return;
      }

      const result = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${window.location.origin}${window.location.pathname}`,
        },
      });

      if (result.error) {
        setError(true);
        setLoading(false);
        return;
      }

      if (result.setupIntent && result.setupIntent.payment_method) {
        await finialize({
          parameter: {
            companyId,
          },
          requestBody: {
            paymentMethodStripeId: result.setupIntent.payment_method as string,
          },
        });
      }

      setLoading(false);
      onClose();
    } catch {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <>
      <Drawer
        title={
          hasCard
            ? t('settings.billing.paymentDetails.editCard.title.update')()
            : t('settings.billing.paymentDetails.editCard.title.add')()
        }
        open={open && !isConfirmModalOpen}
        onClose={onClose}
      >
        <S.Root gap={24} vertical>
          {hasError && (
            <Alert
              message={
                <Flex gap={12} align="center">
                  <S.Icon>
                    <IconStatusWarning />
                  </S.Icon>
                  <Text>
                    {t(
                      'settings.billing.paymentDetails.editCard.errors.generic'
                    )()}
                  </Text>
                </Flex>
              }
              type="error"
            />
          )}
          <S.Form onFinish={handleSubmit}>
            <S.Root vertical>
              <Flex flex="1 0">
                <PaymentElement />
              </Flex>
              <Flex gap={10}>
                {hasCard && (
                  <S.Button onClick={handleDeleteCardClick} danger disabled={isLoading}>
                    {t(
                      'settings.billing.paymentDetails.editCard.actions.delete'
                    )()}
                  </S.Button>
                )}
                <S.Button
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                  loading={isLoading}
                >
                  {hasCard
                    ? t(
                        'settings.billing.paymentDetails.editCard.actions.update'
                      )()
                    : t(
                        'settings.billing.paymentDetails.editCard.actions.add'
                      )()}
                </S.Button>
              </Flex>
            </S.Root>
          </S.Form>
        </S.Root>
      </Drawer>

      <BillingConfirmDeletingCardModal
        isOpen={isConfirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handleConfirmDeleteCardClick}
      />
    </>
  );
};

export default BillingAddCardDrawer;
