import { Flex, Form } from 'antd';
import { rgba } from 'emotion-rgba';

import styled from '@emotion/styled';

export const Card = styled.div<{ isArchived?: boolean }>`
  background-color: ${({ theme, isArchived }) =>
    isArchived ? rgba(theme.colors.white, 0.7) : theme.colors.white};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  padding: 0;
  width: 388px;
`;

export const Details = styled(Flex)`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  background-color: ${({ theme }) => theme.colors.strokeDarkGrey};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const FullWidth = styled(Flex)`
  width: 100%;
`;

export const Content = styled.div`
  padding: 16px 14px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const LogoWrapper = styled(Flex)`
  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.text200};
  color: ${({ theme }) => theme.colors.white};
  width: 32px;
  height: 32px;
  min-width: 32px;
  padding-bottom: 2px;
`;

export const Item = styled(Form.Item)`
  margin-bottom: 0;

  && label {
    color: ${({ theme }) => theme.colors.text200};
  }

  .ui-row {
    display: flex;
    flex-direction: column;
  }
`;

export const Breadcrumbs = styled.span`
  font-size: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text400};
  line-height: 16px;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const Description = styled.span`
  font-size: 12px;
  line-height: 20px;
`;

export const Amount = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-right: 20px;
`;

export const Date = styled.span`
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.text400};
  margin-left: auto;
`;
