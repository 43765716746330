import { Button, Col, Form, Input, Row, message } from 'antd';
import { FC, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import { Card } from '@components';
import { useTranslate } from '@hooks';
import { useUpdateUserPassword } from '@hooks-api';

const PersonalSecurity: FC = () => {
  const { translate } = useTranslate();

  const [currentPasswordError, setCurrentPasswordError] = useState<
    string | null
  >();
  const [newPasswordError, setNewPasswordError] = useState<string | null>();

  const { mutate: updateUserPassword, isPending: loading } =
    useUpdateUserPassword();

  const onFinish = (value: Schemas.UpdateUserPasswordDto) => {
    setCurrentPasswordError(null);
    setNewPasswordError(null);
    updateUserPassword(
      { requestBody: value },
      {
        onSuccess: () => {
          message.success(translate('settings.security.success'));
        },
        onError: (error) => {
          for (const message of error.response?.data?.message || []) {
            if (message.startsWith('currentPassword.')) {
              setCurrentPasswordError(message);
            }
            if (message.startsWith('newPassword.')) {
              setNewPasswordError(message);
            }
          }
        },
      }
    );
  };

  return (
    <Card title={translate('settings.security.title')}>
      <Form layout="vertical" colon={false} onFinish={onFinish}>
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item
              name="password"
              label={translate('settings.security.currentPassword.label')}
              rules={[
                {
                  required: true,
                  message: translate('settings.security.currentPassword.error'),
                },
              ]}
              validateStatus={currentPasswordError ? 'error' : ''}
              help={
                currentPasswordError &&
                // @ts-expect-error-next-line
                translate(`settings.security.${currentPasswordError}`)
              }
            >
              <Input.Password
                placeholder={translate(
                  'settings.security.currentPassword.placeholder'
                )}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item
              name="newPassword"
              label={translate('settings.security.newPassword.label')}
              rules={[
                {
                  required: true,
                  message: translate('settings.security.newPassword.error'),
                },
              ]}
              validateStatus={newPasswordError ? 'error' : ''}
              help={
                newPasswordError &&
                // @ts-expect-error-next-line
                translate(`settings.security.${newPasswordError}`)
              }
            >
              <Input.Password
                placeholder={translate(
                  'settings.security.newPassword.placeholder'
                )}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="newPasswordConfirmation"
              label={translate(
                'settings.security.newPasswordConfirmation.label'
              )}
              rules={[
                {
                  required: true,
                  message: translate(
                    'settings.security.newPasswordConfirmation.error'
                  ),
                },
              ]}
            >
              <Input.Password
                placeholder={translate(
                  'settings.security.newPasswordConfirmation.placeholder'
                )}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item noStyle>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            size="small"
          >
            {translate('settings.security.buttonSave')}
          </Button>
        </Form.Item>
      </Form>

      {/* <S.Item>
        <Flex gap={8} vertical>
          <Text strong>{translate('settings.security.twoStep.label')}</Text>

          <Text type="secondary">
            {isTwoStepCode
              ? translate('settings.security.twoStep.descriptionCode')
              : translate('settings.security.twoStep.description')}
          </Text>

          {isTwoStepCode && (
            <NumbersControl onSubmit={handleTwoStepFinish}>
              <Flex align="center" justify="flex-end" gap={10}>
                <Button size="small" onClick={() => setIsTwoStepCode(false)}>
                  {translate('settings.security.twoStep.buttonCancel')}
                </Button>

                <Button type="primary" htmlType="submit" size="small">
                  {translate('settings.security.twoStep.buttonVerify')}
                </Button>
              </Flex>
            </NumbersControl>
          )}
        </Flex>

        {!isTwoStepCode && (
          <Button
            type="primary"
            size="small"
            onClick={() => setIsTwoStepCode(true)}
          >
            {translate('settings.security.twoStep.buttonTurnOn')}
          </Button>
        )}
      </S.Item> */}
    </Card>
  );
};

export default PersonalSecurity;
