import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconDownload, IconTrash } from '@assets';
import { Scrollbar } from '@components';
import { TransactionsTable } from '@entities';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type TransactionIngestTransactionsProps = {
  ingestId: string;
  onDownload: () => void;
  onRemove: () => void;
};

const TransactionIngestTransactions: FC<TransactionIngestTransactionsProps> = ({
  ingestId,
  onDownload,
  onRemove,
}) => {
  const navigate = useNavigate();

  return (
    <Scrollbar height="calc(100vh - 385px)">
      <S.Container>
        <TransactionsTable
          hiddenColumns={['contact']}
          baseOptions={{
            ingestId: [ingestId],
          }}
          filterParams={{
            controls: {
              left: [
                {
                  type: 'search',
                  formName: 'term',
                },
              ],
              right: [
                {
                  type: 'module-sort',
                },
                {
                  type: 'custom-component',
                  component: (
                    <Button
                      color="primary"
                      variant="filled"
                      size="small"
                      icon={<IconDownload />}
                      onClick={onDownload}
                    />
                  ),
                },
                {
                  type: 'custom-component',
                  component: (
                    <Button
                      color="primary"
                      variant="filled"
                      size="small"
                      icon={<IconTrash />}
                      onClick={onRemove}
                    />
                  ),
                },
              ],
            },
            withSearchParams: false,
            inversionTheme: true,
          }}
          onAfterContentClose={() => navigate(`/csv-imports/${ingestId}`)}
          disabledConfig
          disabledRedirect
          enabledRequest
        />
      </S.Container>
    </Scrollbar>
  );
};

export default TransactionIngestTransactions;
