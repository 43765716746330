import { Upload } from 'antd';

import styled from '@emotion/styled';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 12px;
`;

export const AccountsLabel = styled.div`
  color: ${({ theme }) => theme.colors.textBlue400};
  font-size: 12px;
`;

export const Submit = styled.div`
  margin-top: 24px;
`;

export const UploadWrap = styled(Upload.Dragger)`
  &.ui-upload-wrapper {
    .ui-upload {
      background-color: ${({ theme }) => theme.colors.bgGrey};
      border-color: ${({ theme }) => theme.colors.textBlue400};
      border-radius: 12px;

      .ui-upload-btn {
        padding: 20px 24px;
      }

      .ui-typography {
        &.ui-typography-secondary {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
        }
      }

      svg {
        color: ${({ theme }) => theme.colors.textBlue400};
      }
    }
  }
`;

export const UploadWrapLabel = styled.div`
  color: ${({ theme }) => theme.colors.textBlue400};
  font-weight: 600;
  font-size: 16px;
`;
