import { Trans } from 'react-i18next';

import { PageEmptyState } from '@entities';
import { Button } from '@ui-kit/Button/Button';

import Instruction1Image from './images/instruction1.svg?react';
import Instruction2Image from './images/instruction2.svg?react';
import Instruction3Image from './images/instruction3.svg?react';

const instructions = [
  {
    title: <Trans i18nKey="report.empty.instructions.1" />,
    image: <Instruction1Image />,
  },
  {
    title: <Trans i18nKey="report.empty.instructions.2" />,
    image: <Instruction2Image />,
  },
  {
    title: <Trans i18nKey="report.empty.instructions.3" />,
    image: <Instruction3Image />,
  },
];

const description = [
  t('transaction.empty.description.1'),
  t('transaction.empty.description.2'),
];

const features = [
  t('report.empty.features.customCategories'),
  t('report.empty.features.automaticCategories'),
  t('report.empty.features.manualUpload'),
  t('report.empty.features.views'),
];

const ReportEmpty = () => (
  <PageEmptyState
    title={t('report.empty.title')()}
    description={description}
    features={features}
    action={<Button type="primary">{t('report.empty.connect')()}</Button>}
    instructions={instructions}
  />
);

export default ReportEmpty;
