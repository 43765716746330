import { Button, Form, Input } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';

type ForgotPasswordFormProps = {
  isLoading?: boolean;
  onSubmit: (values: Schemas.LoginDto) => void;
};

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  isLoading,
  onSubmit,
}) => (
  <Form onFinish={onSubmit} layout="vertical" requiredMark={false}>
    <Form.Item
      label={t('auth.forgotPassword.email.label')()}
      name="email"
      rules={[
        {
          required: true,
          message: t('auth.forgotPassword.email.error')(),
        },
      ]}
    >
      <Input
        placeholder={t('auth.forgotPassword.email.placeholder')()}
        size="large"
      />
    </Form.Item>

    <Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        loading={isLoading}
        block
      >
        {t('auth.forgotPassword.buttonSend')()}
      </Button>
    </Form.Item>
  </Form>
);

export default ForgotPasswordForm;
