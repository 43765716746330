import { Flex } from 'antd';

import styled from '@emotion/styled';

export const TransactionContent = styled(Flex)`
  height: 100%;
`;

export const Content = styled(Flex)<{ ismodal: string }>`
  width: 100%;
  max-width: 1440px;
  height: 100%;
`;

export const DetailsContainer = styled(Flex)<{ ismodal: string }>`
  width: ${({ ismodal }) => (ismodal.length ? '593px' : '60%')};
  padding: 30px 0;
  margin: 0 30px;
`;

export const FullWidth = styled(Flex)`
  width: 100%;
`;

export const FilelessWrapper = styled(Flex)<{ ismodal: string }>`
  width: ${({ ismodal }) => (ismodal.length ? '373px' : '40%')};
  min-height: 660px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-bottom-right-radius: 20px;
`;
