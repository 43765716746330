import { Button, Flex, Modal, ModalProps, Typography } from 'antd';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { default as ConnectErrorImage }  from './ConnectionError.svg?react';

import * as S from './styled';

const { Title, Text } = Typography;

type AccountConnectionErrorModalProps = {
  onCancel: () => void;
} & ModalProps;

const AccountConnectionErrorModal = ({
  open,
  onCancel,
}: AccountConnectionErrorModalProps) =>
  (
    <Modal
      open={open}
      width={760}
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      centered
    >
      <Flex gap={40} vertical>
        <S.Root gap={28} vertical>
          <ConnectErrorImage width={309} height={267} />
          <S.Delimiter />
          <S.Content gap={14} vertical>
            <Title level={3}>{t('accounts.connectionError.title')()}</Title>
            <Flex vertical>
              <Text>{t('accounts.connectionError.descriptionFirst')()}</Text>
              <Text>{t('accounts.connectionError.descriptionSecond')()}</Text>
            </Flex>
            <Text>
              <Trans components={[<Link to="/chat" />]}>
                {t('accounts.connectionError.descriptionThird')()}
              </Trans>
            </Text>
          </S.Content>
        </S.Root>
        <Flex justify="center" gap={12}>
          <Button onClick={onCancel} type="primary">
            {t('accounts.connectionError.buttons.confirm')()}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )


export default AccountConnectionErrorModal;
