import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaWidth } from '@ui-kit/theme/mediaWidth';

type ContentProps = {
  width: number | string;
  centering?: boolean;
};

type InnerProps = {
  withPadding: boolean;
};

export const Container = styled.div`
  ${mediaWidth.tablet(css`
    min-height: calc(100dvh - 72px);
    display: flex;
    flex-direction: column;
  `)};
`;

export const Inner = styled.div<InnerProps>`
  padding: ${({ withPadding }) => (withPadding ? '40px' : 0)};

  ${mediaWidth.tablet(css`
    min-height: calc(100dvh - 72px);
    display: flex;
    padding: 16px;
  `)};
`;

export const Content = styled.div<ContentProps>`
  width: 100%;
  max-width: ${({ width }) =>
    typeof width === 'string' ? width : `${width}px`};
  margin: ${({ centering }) => (centering ? '0 auto' : 0)};
  flex: 1;

  ${mediaWidth.tablet(css`
    display: flex;
  `)};
`;
