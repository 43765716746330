import { Button, Col, Flex, Row, Typography } from 'antd';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import {
  IconCheck,
  IconLegal,
  IconUser,
  IconWarningTransparent,
} from '@assets';
import { ScreenName } from '@context';
import {
  KYCActivityDetails,
  KYCAddressProof,
  KYCAnnualAccounts,
  KYCAoa,
  KYCBiometric,
  KYCCv,
  KYCExtract,
  KYCIds,
  KYCNationality,
  KYCOwnershipChart,
  KYCTax,
  KYCTin,
  KYCWealth,
  OnboardingCardHeader,
} from '@entities';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';
import { sendGTMEvent } from '@utils';

import * as S from './styled';

type Person = Schemas.Person;
type CompanyFile = Schemas.CompanyFile;

type StepWithFormErrors = 'tin' | 'wealth';

const { Paragraph, Text } = Typography;

const legalGenericSteps = [
  'extract',
  'aoa',
  'annual_accounts',
  'ownership_chart',
  'tin',
];

const legalMinimumSteps = ['activity_details'];

const naturalSteps = [
  'nationality',
  'id',
  'address_proof',
  'wealth',
  'cv',
  'tax',
];
const naturalDirectorSteps = ['nationality', 'id', 'address_proof'];

const OnboardingUploadDocuments: FC = () => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const {
    incorporationDetails,
    updateIncorporation,
    updateScreen,
    updateStep,
    isIncorporation,
  } = useOnboarding();

  const details = incorporationDetails as Schemas.Incorporation;

  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);

  const [selectedStepValue, setSelectedStepValue] = useState('extract');

  const { mutate: update, isPending: loading } = useUpdateIncorporation();

  useEffect(
    () => {
      if (incorporationDetails?.people) {
        const firstPerson = incorporationDetails?.people[0];

        handleSelectPerson(firstPerson as Person);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getLegalSteps = (person: Person) => {
    if (person.isCompanyItself) {
      if (isIncorporation) {
        return legalMinimumSteps;
      } else {
        return legalGenericSteps.concat(legalMinimumSteps);
      }
    } else {
      return legalGenericSteps;
    }
  };

  const handleSelectPerson = (person: Person) => {
    setSelectedPerson(person);
    setSelectedType(person.type);

    if (person.type === 'legal') {
      setSelectedStepValue(getLegalSteps(person)[0]);
      return;
    }

    if (person.type === 'natural' && (person.isShareholder || person.isUbo)) {
      setSelectedStepValue(naturalSteps[0]);
      return;
    }

    setSelectedStepValue(naturalDirectorSteps[0]);
  };

  const flattenDeep = (people: Person[]): Person[] =>
    people.reduce((acc, person) => {
      person.copyOfId = null;
      person.sameAsIds = [];
      return person.people?.length
        ? acc.concat(flattenDeep(person.people)).concat(person)
        : acc.concat(person);
    }, [] as Person[]);

  const deduplicatePeople = (people: Person[]): Person[] =>
    people
      .reduce((acc, person) => {
        for (const el of acc) {
          if (el.name.toLowerCase() === person.name.toLowerCase()) {
            el.sameAsIds.push(person.id);
            person.copyOfId = el.id;
          }
        }

        acc.push(person);
        return acc;
      }, [] as Person[])
      .filter((person) => !person.copyOfId);

  const allPeople = deduplicatePeople(flattenDeep(details.people || []));

  const handleNext = () => {
    let nextStep: ScreenName = 'LEGAL_ADDRESS';

    if (isIncorporation) {
      nextStep = 'PAYMENT';
    } else {
      nextStep = 'MESSAGE_PROCESS_DOCUMENTS';
    }

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {

          isSubmittedToKyc: !isIncorporation,
          isKycFailed: false,
        },
      },
      {
        onSuccess: (response) => {
          updateIncorporation(response);
          updateScreen(nextStep, { active: true });
          sendGTMEvent('kycFiled', {});
        },
      }
    );
  };

  const getPersonRole = (person: Person) => {
    const roles: string[] = [];

    if (person.isShareholder) {
      roles.push(translate('onboarding.kyc.shareholder'));
    }

    if (person.isDirector) {
      roles.push(translate('onboarding.kyc.manager'));
    }

    if (person.isUbo) {
      roles.push(translate('onboarding.kyc.ubo'));
    }

    return roles.join(', ');
  };

  const hasErrorStep = (step: string) => {
    if (step === 'id' && selectedPerson?.kycData?.hasFailedBiometricCheck) {
      return true;
    }

    if (step === 'wealth') {
      const incomeWithFileRequestedButNoFileOrError =
        selectedPerson?.kycData?.incomes?.find((income) => {
          if (income) {
            const step = `wealth_${income.type}`;
            return (
              income.isRequiredConfimation === 'yes' &&
              (!selectedPerson.files[step] ||
                selectedPerson.files[step].length === 0 ||
                selectedPerson.files[step].some(
                  (file: CompanyFile) => file.hasError
                ))
            );
          }
        });

      if (incomeWithFileRequestedButNoFileOrError) {
        return true;
      }
    }

    if (['tin', 'wealth'].includes(step) && selectedPerson?.kycData) {
      return selectedPerson.kycData[`${step as StepWithFormErrors}ErrorCode`];
    }

    const errors = (selectedPerson?.files[step] || [])
      .map((file: CompanyFile) => file.hasError)
      .filter((status: boolean) => status);

    return !!errors.length;
  };

  const hasErrorPerson = (person: Person) => {
    const incomeWithFileRequestedButNoFile = person?.kycData?.incomes?.find(
      (income) => {
        if (income) {
          const step = `wealth_${income.type}`;
          return (
            income.isRequiredConfimation === 'yes' &&
            (!person.files[step] || person.files[step].length === 0)
          );
        }
      }
    );

    if (incomeWithFileRequestedButNoFile) {
      return true;
    }

    if (
      person?.kycData?.tinErrorCode ||
      person?.kycData?.wealthErrorCode ||
      person?.kycData?.hasFailedBiometricCheck
    ) {
      return true;
    }

    const errors = _.flattenDeep(_.values(person?.files))
      .map((file: CompanyFile) => file.hasError)
      .filter((status: boolean) => status);

    return !!errors.length;
  };

  const hasFilesWithErrors = () =>
    !!details.people.filter((person) => hasErrorPerson(person)).length;

  const goToShareholders = () => {
    updateIncorporation({ group: 'shareholders' });
    updateStep('SHAREHOLDERS', { active: true, status: 'inProgress' });
    updateScreen('SHAREHOLDERS', { active: true });
  };

  const isStepComplete = (step: string, person: Person | null) => {
    switch (step) {
      case 'extract':
        return (
          (person?.kycData?.hasExtract === 'yes' &&
            person?.files[step]?.length > 0) ||
          (person?.kycData?.hasExtract === 'no' &&
            person?.kycData?.hasRequestedExtract === 'yes' &&
            !!person?.kycData?.rcsNumber?.length)
        );
      case 'tin':
        return !!person?.kycData?.tin;
      case 'nationality':
        return !!person?.kycData?.nationality;
      case 'id':
        return (
          person?.kycData?.hasCompletedBiometricCheck ||
          person?.kycData?.hasSentBiometricCheckLink
        );
      case 'address_proof':
        return (
          !!person?.kycData?.countryCode &&
          (person?.kycData?.address || '').length > 0 &&
          (person?.kycData?.zip || '').length > 0 &&
          (person?.kycData?.city || '').length > 0 &&
          person?.files[step]?.length > 0
        );
      case 'wealth': {
        const incomeWithFileRequestedButNoFile = person?.kycData?.incomes?.find(
          (income) => {
            if (income) {
              const step = `wealth_${income.type}`;
              return (
                income.isRequiredConfimation === 'yes' &&
                (!person.files[step] || person.files[step].length === 0)
              );
            }
          }
        );

        return (
          !incomeWithFileRequestedButNoFile &&
          (person?.kycData?.totalWealth || '').length > 0 &&
          person?.kycData?.incomes &&
          person?.kycData?.incomes.length > 0 &&
          !person?.kycData?.wealthErrorCode
        );
      }
      case 'cv':
        return (
          (person?.kycData?.pep === 'no' ||
            (person?.kycData?.pep === 'yes' &&
              (person?.kycData?.pepInfo || '').length > 0)) &&
          (person?.kycData?.blacklist === 'no' ||
            (person?.kycData?.blacklist === 'yes' &&
              (person?.kycData?.blacklistInfo || '').length > 0)) &&
          person?.files[step]?.length > 0
        );
      case 'tax':
        return (
          !!person?.kycData?.taxResidenceCountryCode &&
          (person?.kycData?.taxNumber || '').length > 0 &&
          !!person?.kycData?.professionalIncomeCountryCode &&
          (person?.kycData?.usaLink === 'no' ||
            (person?.kycData?.usaLink === 'yes' &&
              !!person?.kycData?.usaLinkType))
        );
      case 'annual_accounts':
        return (
          person?.kycData?.hasFiledAnnualAccounts === 'no' ||
          (person?.kycData?.hasFiledAnnualAccounts === 'yes' &&
            person?.files[step]?.length > 0)
        );
      case 'activity_details':
        return (
          !!person?.kycData?.purposeOfEstablishment &&
          !!person?.kycData?.assessmentOfIncomingFunds &&
          !!person?.kycData?.assessmentOfOutgoingFunds &&
          !!person?.kycData?.clientsOfCompany &&
          !!person?.kycData?.countriesInvolved &&
          !!person?.kycData?.longTermGoals
        );
      default:
        return !!person?.files?.[step]?.length;
    }
  };

  const stepsForType = (person: Person | null) => {
    if (!person) {
      return [];
    }

    if (person.type === 'legal') {
      return getLegalSteps(person);
    }

    if (person.isShareholder || person.isUbo) {
      return naturalSteps;
    } else {
      return naturalDirectorSteps;
    }
  };

  const personStepsCompletedCount = (person: Person) => {
    const steps = stepsForType(person);
    return steps.map((step) => isStepComplete(step, person)).filter((v) => v)
      .length;
  };

  const isEqualSteps = (person: Person) => {
    const completedSteps = personStepsCompletedCount(person);
    const requiredStepsCount = stepsForType(person).length;
    return completedSteps === requiredStepsCount;
  };

  const notAllDataPresent = () =>
    allPeople.filter((person) => !isEqualSteps(person)).length > 0;

  return (
    <Flex gap={24} vertical>
      <OnboardingCardHeader
        title={translate('onboarding.kyc.title')}
        description={
          <Paragraph>
            <Trans
              i18nKey={translateUntyped(
                `onboarding.kyc.description_${incorporationDetails?.incorporationType}`
              )()}
              components={[<Paragraph />, <strong />]}
            />
          </Paragraph>
        }
      />

      <Flex gap={24}>
        <S.Categories>
          <Flex align="center" justify="space-between">
            <S.CategoriesTitle level={4}>
              {translate('onboarding.kyc.titleList')}
            </S.CategoriesTitle>

            <S.CategoriesAction onClick={goToShareholders}>
              {translate('onboarding.kyc.modify')}
            </S.CategoriesAction>
          </Flex>

          <S.People>
            {allPeople.map((person) => (
              <S.PeopleWrap key={person.id}>
                <S.Person
                  onClick={() => handleSelectPerson(person)}
                  selected={selectedPerson?.id === person.id}
                >
                  <Flex gap={16}>
                    {person.type === 'legal' ? <IconLegal /> : <IconUser />}

                    <Flex vertical>
                      <S.PersonName>{person.name}</S.PersonName>
                      <S.PersonDetails>{getPersonRole(person)}</S.PersonDetails>
                    </Flex>
                  </Flex>

                  <Flex align="center" gap={4}>
                    <S.PersonFilesCount
                      selected={selectedPerson?.id === person.id}
                    >
                      {`${personStepsCompletedCount(person)}/${stepsForType(person).length}`}
                    </S.PersonFilesCount>

                    {isEqualSteps(person) && !hasErrorPerson(person) && (
                      <S.StepNumber status="completed">
                        <IconCheck />
                      </S.StepNumber>
                    )}

                    {hasErrorPerson(person) && (
                      <S.StepNumber status="errorsFound">
                        <IconWarningTransparent />
                      </S.StepNumber>
                    )}
                  </Flex>
                </S.Person>
              </S.PeopleWrap>
            ))}
          </S.People>
        </S.Categories>

        <S.Files>
          <Row gutter={[24, 0]}>
            <Col span={11}>
              <S.Steps>
                {stepsForType(selectedPerson).map((step, stepIndex) => (
                  <S.Step
                    key={step}
                    selected={selectedStepValue === step}
                    onClick={() => setSelectedStepValue(step)}
                  >
                    {isStepComplete(step, selectedPerson) &&
                      !hasErrorStep(step) && (
                        <S.StepNumber
                          status="completed"
                          selected={selectedStepValue === step}
                        >
                          <IconCheck />
                        </S.StepNumber>
                      )}

                    {hasErrorStep(step) && (
                      <S.StepNumber status="errorsFound">
                        <IconWarningTransparent />
                      </S.StepNumber>
                    )}

                    {!isStepComplete(step, selectedPerson) &&
                      !hasErrorStep(step) && (
                        <S.StepNumber
                          status={null}
                          selected={selectedStepValue === step}
                        >
                          {stepIndex + 1}
                        </S.StepNumber>
                      )}

                    <S.StepName>
                      <Trans
                        i18nKey={translateUntyped(
                          `onboarding.kyc.${selectedType}.${step}`
                        )()}
                        components={[<Text type="secondary" />]}
                      />
                    </S.StepName>
                  </S.Step>
                ))}
              </S.Steps>
            </Col>

            <Col span={13} key={selectedPerson?.id}>
              {selectedPerson && (
                <S.StepContent>
                  {selectedStepValue === 'tin' && (
                    <KYCTin person={selectedPerson!} />
                  )}

                  {selectedStepValue === 'extract' && (
                    <KYCExtract
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'aoa' && (
                    <KYCAoa
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'annual_accounts' && (
                    <KYCAnnualAccounts
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'ownership_chart' && (
                    <KYCOwnershipChart
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'activity_details' && (
                    <KYCActivityDetails person={selectedPerson!} />
                  )}

                  {selectedStepValue === 'ids' && (
                    <KYCIds
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'nationality' && (
                    <KYCNationality person={selectedPerson!} />
                  )}

                  {selectedStepValue === 'id' && (
                    <KYCBiometric person={selectedPerson!} />
                  )}

                  {selectedStepValue === 'address_proof' && (
                    <KYCAddressProof
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'wealth' && (
                    <KYCWealth person={selectedPerson!} />
                  )}

                  {selectedStepValue === 'cv' && (
                    <KYCCv
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'tax' && (
                    <KYCTax person={selectedPerson!} />
                  )}
                </S.StepContent>
              )}
            </Col>
          </Row>
        </S.Files>
      </Flex>

      <Flex align="center" justify="flex-end" gap={24}>
        <S.LastChance type="secondary">
          {translate('onboarding.kyc.lastChanceLabel')}
        </S.LastChance>

        <Button
          type="primary"
          loading={loading}
          disabled={hasFilesWithErrors() || notAllDataPresent()}
          onClick={handleNext}
        >
          {translate('onboarding.buttonSubmitToKyc')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default OnboardingUploadDocuments;
