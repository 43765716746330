import { Button, Checkbox, Flex, Form, Input } from 'antd';
import { FC, useState } from 'react';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type User = Schemas.User;
type CompanyInvite = Schemas.CompanyInvite;

export type TeamMembersAccessModalProps = {
  open: boolean;
  editableEntity: User | CompanyInvite | null;
  onChangeEditableEntity: (entity: User | CompanyInvite | null) => void;
  onDeleteEditableEntity: (entity: User | CompanyInvite) => void;
  onSubmit: (values: Schemas.AccessRights) => void;
  onClose: () => void;
};

const isUser = (obj: User | CompanyInvite): obj is User =>
  (obj as Schemas.User).fullName !== undefined;

const TeamMembersAccessModal: FC<TeamMembersAccessModalProps> = ({
  open,
  onChangeEditableEntity,
  onDeleteEditableEntity,
  editableEntity,
  onSubmit,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [email, setEmail] = useState('');

  const isNew = !editableEntity;

  const handleSubmit = () => {
    onSubmit(
      isNew
        ? { accessRights: form.getFieldsValue(), email }
        : form.getFieldsValue()
    );
  };

  const handleClose = () => {
    onChangeEditableEntity(null);
    onClose();
  };

  return (
    <S.AccessModal
      open={open}
      width={630}
      onCancel={handleClose}
      footer={
        <Flex justify={isNew ? 'flex-end' : 'space-between'}>
          {!isNew && (
            <S.RemoveButton
              type="default"
              onClick={() => onDeleteEditableEntity(editableEntity!)}
              danger
            >
              {t('settings.teamMembers.modal.removeFromTeam')()}
            </S.RemoveButton>
          )}
          <Flex gap={12}>
            <Button type="default" onClick={handleClose}>
              {t('settings.security.twoStep.buttonCancel')()}
            </Button>
            <Button
              type="primary"
              data-testid="submit-modal-access-button"
              disabled={isNew && !email}
              onClick={handleSubmit}
            >
              {t(
                isNew
                  ? 'settings.teamMembers.users.buttonInvite'
                  : 'settings.personal.buttonSave'
              )()}
            </Button>
          </Flex>
        </Flex>
      }
      closable
    >
      <S.ContentWrapper gap={32} data-testid="access-modal" vertical>
        <S.FormTitle>
          {t(
            isNew
              ? 'settings.teamMembers.setNewTeamMember'
              : 'settings.teamMembers.editUserAccess'
          )()}
        </S.FormTitle>

        {isNew ? (
          <S.EmailContainer gap={4} vertical>
            <S.Label>{t('settings.teamMembers.emailAddress')()}</S.Label>
            <Input
              size="large"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </S.EmailContainer>
        ) : (
          <S.Container
            justify="flex-start"
            align="flex-start"
            gap={2}
            data-testid="modal-user-name-and-email"
            isbordered="true"
            vertical
          >
            <S.Name>
              {isUser(editableEntity) ? editableEntity?.fullName : ''}
            </S.Name>
            <S.Email>{editableEntity?.email}</S.Email>
          </S.Container>
        )}

        <Form
          form={form}
          layout="vertical"
          initialValues={{
            transactions: !!editableEntity?.accessRights?.transactions,
            companyData: !!editableEntity?.accessRights?.companyData,
            userManagement: !!editableEntity?.accessRights?.userManagement,
            reports: !!editableEntity?.accessRights?.reports,
            payroll: !!editableEntity?.accessRights?.payroll,
            incomesExpences: !!editableEntity?.accessRights?.incomesExpences,
          }}
        >
          <S.Item name="transactions" valuePropName="checked">
            <Flex gap={12} align="flex-start">
              <Checkbox
                data-testid="transactions-checkbox"
                defaultChecked={!!editableEntity?.accessRights?.transactions}
              />
              <Flex gap={6} vertical>
                <S.FormItemTitle>
                  {t('settings.teamMembers.users.transactions')()}
                </S.FormItemTitle>
                <span>{t('settings.teamMembers.popup.transactions')()}</span>
              </Flex>
            </Flex>
          </S.Item>

          <S.Item name="companyData" valuePropName="checked">
            <Flex gap={12} align="flex-start">
              <Checkbox
                data-testid="companyData-checkbox"
                defaultChecked={!!editableEntity?.accessRights?.companyData}
              />
              <Flex gap={6} vertical>
                <S.FormItemTitle>
                  {t('settings.teamMembers.users.companyData')()}
                </S.FormItemTitle>
                <span>{t('settings.teamMembers.popup.companyData')()}</span>
              </Flex>
            </Flex>
          </S.Item>

          <S.Item name="userManagement" valuePropName="checked">
            <Flex gap={12} align="flex-start">
              <Checkbox
                data-testid="userManagement-checkbox"
                defaultChecked={!!editableEntity?.accessRights?.userManagement}
              />
              <Flex gap={6} vertical>
                <S.FormItemTitle>
                  {t('settings.teamMembers.users.userManagement')()}
                </S.FormItemTitle>
                <span>{t('settings.teamMembers.popup.userManagement')()}</span>
              </Flex>
            </Flex>
          </S.Item>

          <S.Item name="reports" valuePropName="checked">
            <Flex gap={12} align="flex-start">
              <Checkbox
                data-testid="reports-checkbox"
                defaultChecked={!!editableEntity?.accessRights?.reports}
              />
              <Flex gap={6} vertical>
                <S.FormItemTitle>
                  {t('settings.teamMembers.users.reports')()}
                </S.FormItemTitle>
                <span>{t('settings.teamMembers.popup.reports')()}</span>
              </Flex>
            </Flex>
          </S.Item>

          <S.Item name="payroll" valuePropName="checked">
            <Flex gap={12} align="flex-start">
              <Checkbox
                data-testid="payroll-checkbox"
                defaultChecked={!!editableEntity?.accessRights?.payroll}
              />
              <Flex gap={6} vertical>
                <S.FormItemTitle>
                  {t('settings.teamMembers.users.payroll')()}
                </S.FormItemTitle>
                <span>{t('settings.teamMembers.popup.payroll')()}</span>
              </Flex>
            </Flex>
          </S.Item>

          <S.Item name="incomesExpences" valuePropName="checked">
            <Flex gap={12} align="flex-start">
              <Checkbox
                data-testid="incomesExpences-checkbox"
                defaultChecked={!!editableEntity?.accessRights?.incomesExpences}
              />
              <Flex gap={6} vertical>
                <S.FormItemTitle>
                  {t('settings.teamMembers.users.incomesExpences')()}
                </S.FormItemTitle>
                <span>{t('settings.teamMembers.popup.incomesExpences')()}</span>
              </Flex>
            </Flex>
          </S.Item>
        </Form>
      </S.ContentWrapper>
    </S.AccessModal>
  );
};

export default TeamMembersAccessModal;
