import { Button, Typography } from 'antd';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { IconSuccessCircle } from '@assets';
import { useAccount, useOnboarding } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';
import { colors } from '@theme';

import * as S from './styled';

type OnboardingCompanyTypeCardProps = {
  type: /*'sa' |*/ 'sarl' | 'sarlS';
  price: string | number;
};

const { Paragraph, Text } = Typography;

const OnboardingCompanyTypeCard: FC<OnboardingCompanyTypeCardProps> = ({
  type,
  price,
}) => {
  const { companyId } = useAccount();

  const { updateScreen, updateIncorporation } = useOnboarding();

  const { mutate: update, isPending: isLoading } = useUpdateIncorporation();

  const examples = t(`onboarding.${type}.examples`, {
    returnObjects: true,
  })();

  const features = t(`onboarding.${type}.features`, {
    returnObjects: true,
  })();

  const details = t(`onboarding.${type}.details`, {
    returnObjects: true,
  })();

  const handleRegister = () => {
    const body = {
      companyType: type === 'sarlS' ? 'sarl-s' : type,
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: body,
      },
      {
        onSuccess: () => {
          updateScreen('SHAREHOLDERS', { active: true });
          updateIncorporation(body);
        },
      }
    );
  };

  return (
    <S.Card>
      <S.Inner>
        <S.Title level={3}>{t(`onboarding.${type}.title`)()}</S.Title>

        <Paragraph type="secondary">
          {t(`onboarding.${type}.description`)()}
        </Paragraph>

        <S.Detail>
          <Trans
            i18nKey={t(`onboarding.${type}.detail`)()}
            components={[<Text strong />, <br />, <Paragraph />]}
          />
        </S.Detail>
      </S.Inner>

      <S.Examples>
        <Typography.Title level={4}>
          {t(`onboarding.${type}.examplesTitle`)()}
        </Typography.Title>

        <ol type="A">
          {examples.map((example) => (
            <li key={example}>{example}</li>
          ))}
        </ol>
      </S.Examples>

      <S.Inner>
        <S.DetailsList>
          {details.map((detail) => (
            <S.DetailsItem key={detail}>{detail}</S.DetailsItem>
          ))}
        </S.DetailsList>

        <S.Minimum>
          <S.MinimumTitle level={4}>
            {t(`onboarding.${type}.note`)()}
          </S.MinimumTitle>

          <Paragraph type="secondary">
            {t(`onboarding.${type}.canUse`)()}
          </Paragraph>
        </S.Minimum>
      </S.Inner>

      <S.Price>
        <S.PriceTitle level={4}>
          {t(`onboarding.${type}.price`, {
            value: `€${price}`,
          })()}
        </S.PriceTitle>

        <S.PriceSubtitle>
          {t(`onboarding.${type}.incorporation`)()}
        </S.PriceSubtitle>

        <S.PriceDetails>
          {features.map((feature) => (
            <S.PriceDetailsItem key={feature}>
              <IconSuccessCircle color={colors.bgDarkBlue} /> {feature}
            </S.PriceDetailsItem>
          ))}
        </S.PriceDetails>

        <Button
          type="primary"
          onClick={handleRegister}
          loading={isLoading}
          block
        >
          {t('onboarding.common.registerButton', {
            type: t(`onboarding.${type}.title`)(),
          })()}
        </Button>

        <S.Discount strong>
          <Trans
            i18nKey={t('onboarding.common.discount')()}
            components={[<br />]}
          />
        </S.Discount>

        <S.ExtraText type="secondary">
          <Trans
            i18nKey={t('onboarding.common.includes')()}
            components={[<br />]}
          />
        </S.ExtraText>
      </S.Price>

      <S.Notes>
        <S.NotesItem>
          <Trans
            i18nKey={t(`onboarding.${type}.priceNoteOpen`)()}
            components={[<Text strong>+€250</Text>]}
          />
        </S.NotesItem>

        {type === 'sarl' && (
          <S.NotesItem>
            <Trans
              i18nKey={t(`onboarding.${type}.priceNoteCapital`)()}
              components={[<Text strong>+€75</Text>]}
            />
          </S.NotesItem>
        )}

        {type === 'sarl' && (
          <S.NotesItem>
            <Trans
              i18nKey={t(`onboarding.${type}.priceNoteFee`)()}
              components={[<Text strong>+€1800</Text>]}
            />
          </S.NotesItem>
        )}
      </S.Notes>
    </S.Card>
  );
};

export default OnboardingCompanyTypeCard;
