import { Flex, Typography } from 'antd';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Trans } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import { IconUpload } from '@assets';
import { PaymentDocumentImportInvoice } from '@entities';
import { colors } from '@theme';
import { Button } from '@ui-kit/Button/Button';

import Instruction1ExpenseImage from './images/instruction1expense.svg?react';
import Instruction1IncomeImage from './images/instruction1income.svg?react';
import Instruction2Image from './images/instruction2.svg?react';
import Instruction3Image from './images/instruction3.svg?react';
import * as S from './styled';

const { Text } = Typography;

const descriptionIncome = [
  t('income.empty.description.1'),
  t('income.empty.description.2'),
];

const descriptionExpense = [
  t('expenses.empty.description.1'),
  t('expenses.empty.description.2'),
];

const featuresIncome = [
  t('income.empty.features.automaticRecognition'),
  t('income.empty.features.categories'),
  t('income.empty.features.track'),
  t('income.empty.features.formats'),
];

const featuresExpense = [
  t('expenses.empty.features.automaticRecognition'),
  t('expenses.empty.features.categories'),
  t('expenses.empty.features.track'),
  t('expenses.empty.features.formats'),
];

const instructionsIncome = [
  {
    title: (
      <Trans
        i18nKey="income.empty.instructions.1"
        components={[<S.SuccessText />]}
      />
    ),
    image: <Instruction1IncomeImage />,
  },
  {
    title: <Trans i18nKey="income.empty.instructions.2" />,
    image: <Instruction2Image />,
  },
  {
    title: <Trans i18nKey="income.empty.instructions.3" />,
    image: <Instruction3Image />,
  },
];

const instructionsExpense = [
  {
    title: (
      <Trans
        i18nKey="expenses.empty.instructions.1"
        components={[<S.DangerText />]}
      />
    ),
    image: <Instruction1ExpenseImage />,
  },
  {
    title: <Trans i18nKey="expenses.empty.instructions.2" />,
    image: <Instruction2Image />,
  },
  {
    title: <Trans i18nKey="expenses.empty.instructions.3" />,
    image: <Instruction3Image />,
  },
];

type Props = {
  type: 'income' | 'expenses';
  onUpdate?: (file: Schemas.Document) => void;
};

const PaymentDocumentEmpty = ({ type, onUpdate }: Props) => {
  const [{ canDrop }, dropRef] = useDrop(
    {
      accept: [NativeTypes.FILE],
      collect: (monitor: DropTargetMonitor) => ({
        canDrop: monitor.canDrop(),
      }),
    },
    []
  );

  return (
    <S.PageEmptyState
      ref={dropRef}
      type={type}
      title={
        <Trans
          i18nKey={
            type === 'income' ? 'income.empty.title' : 'expenses.empty.title'
          }
          components={[type == 'income' ? <S.SuccessText /> : <S.DangerText />]}
        />
      }
      description={type === 'income' ? descriptionIncome : descriptionExpense}
      features={type === 'income' ? featuresIncome : featuresExpense}
      action={
        <>
          <PaymentDocumentImportInvoice documentType={type} onUpdate={onUpdate}>
            {canDrop && (
              <S.Dropzone>
                <S.DropzoneInner>
                  <IconUpload color={colors.primary} />

                  <S.DropzoneText>
                    {t('income.dropFile.title')()}
                  </S.DropzoneText>
                </S.DropzoneInner>
              </S.Dropzone>
            )}
            <Flex align="center" gap={15}>
              <Button type="primary">
                {t(
                  type === 'income'
                    ? 'income.empty.import'
                    : 'expenses.empty.import'
                )()}
              </Button>
              <Text type="secondary">
                {t(
                  type === 'income'
                    ? 'income.empty.drag'
                    : 'expenses.empty.drag'
                )()}
              </Text>
            </Flex>
          </PaymentDocumentImportInvoice>
        </>
      }
      instructions={
        type === 'income' ? instructionsIncome : instructionsExpense
      }
    />
  );
};

export default PaymentDocumentEmpty;
