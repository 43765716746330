// import { TabsProps } from 'antd';
import { useParams } from 'react-router-dom';

import { useTaskController_findAll } from '@api-client/generated/TaskController/findAll';
import { Loader } from '@components';
import { PageMeta } from '@entities';
import { useAccount } from '@hooks';

import ChatContent from './content/ChatContent/ChatContent';
import { ChatSidebar } from './sidebar/ChatSidebar';
// import { TasksSidebar } from './sidebar/TasksSidebar';
import * as S from './styled';

/*
const items: TabsProps['items'] = [
  {
    key: 'chat',
    label: 'Chats',
    children: <ChatSidebar />,
  },
  {
    key: 'tasks',
    label: 'Tasks',
    children: <TasksSidebar />,
  },
];
*/

export const Tasks = () => {
  const { id: taskId } = useParams();

  const { companyId } = useAccount();

  if (!companyId) {
    throw new Error('Company ID is not defined');
  }

  const { data: tasks, isPending: loading } = useTaskController_findAll({
    params: {
      companyId,
    },
  });

  const selectedTask = tasks?.find((task) => task.id === taskId);

  if (loading) {
    return <Loader />;
  }

  /*
  const activeKey =
    !selectedTask || selectedTask.isGeneral ? items[0].key : items[1].key;
*/

  return (
    <>
      <PageMeta title={t('tasks.title')()} />
      <S.Wrapper>
        <S.Sidebar>
          {/*<S.Tabs defaultActiveKey={activeKey} items={items} />*/}
          <ChatSidebar />
        </S.Sidebar>

        <S.Content>
          {!taskId || !selectedTask ? (
            <S.EmptyChat>{t('chat.selectChat')()}</S.EmptyChat>
          ) : (
            <ChatContent taskId={taskId} details={selectedTask} />
          )}
        </S.Content>
      </S.Wrapper>
    </>
  );
};
