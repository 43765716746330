import styled from '@emotion/styled';

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;

  .ui-typography {
    width: 150px;
    color: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
`;
