import { Flex, Typography } from 'antd';
import { Fragment, ReactNode, forwardRef } from 'react';

import ArrowImage from './images/arrow.svg?react';
import * as S from './styled';

const { Text, Title } = Typography;

type PageEmptyStateProps = {
  title: ReactNode;
  description: Array<() => string>;
  features: Array<() => string>;
  action: ReactNode;
  instructions: Array<{
    title: ReactNode;
    image: ReactNode;
  }>;
  className?: string;
};

const PageEmptyState = forwardRef<HTMLDivElement, PageEmptyStateProps>(
  ({ title, description, features, action, instructions, className }, ref) => (
    <S.Wrapper ref={ref} className={className} gap={50} vertical>
      <Flex gap={10}>
        <Flex flex="2 0" gap={14} vertical>
          <Flex gap={16} vertical>
            <Title level={2}>{title}</Title>
            <Flex vertical>
              {description.map((description, index) => (
                <S.Description key={index}>{description()}</S.Description>
              ))}
            </Flex>
          </Flex>
          {action}
        </Flex>
        <S.Features flex="1 0" gap={8} vertical>
          {features.map((feature, index) => (
            <Flex key={index} gap={10}>
              <S.FeatureIcon />
              <Text>{feature()}</Text>
            </Flex>
          ))}
        </S.Features>
      </Flex>
      <S.Instructions>
        {instructions.map((instruction, index) => (
          <Fragment key={index}>
            <S.Instruction gap={16} vertical>
              <S.InstructionText>{instruction.title}</S.InstructionText>
              {instruction.image}
            </S.Instruction>
            {index !== instructions.length - 1 && <ArrowImage />}
          </Fragment>
        ))}
      </S.Instructions>
    </S.Wrapper>
  )
);

export default PageEmptyState;
