import {
  Button,
  Col,
  Flex,
  Form,
  Modal,
  type ModalProps,
  Row,
  Typography,
} from 'antd';
import { FC, useEffect } from 'react';
import { Trans } from 'react-i18next';

import { useAccountsController_createAccount } from '@api-client/generated/AccountsController/createAccount';
import { useAccountsController_updateOneById } from '@api-client/generated/AccountsController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { AccountType, currencyCodes } from '@constants';
import { useAccount, useAccounts } from '@hooks';

import * as S from './styled';

type AccountAddModalProps = {
  account?: Schemas.Account | null;
  id?: string | null;
  onCancel: () => void;
} & ModalProps;

const { Title } = Typography;

const AccountAddModal: FC<AccountAddModalProps> = ({
  account,
  open,
  onCancel,
  ...rest
}) => {
  const { companyId } = useAccount();

  const [form] = Form.useForm();

  const { createAccount, updateAccount } = useAccounts();

  const { mutate: createNewAccount, isPending: loading } =
    useAccountsController_createAccount();
  const { mutate: updateAccountById, isPending: loadingUpdate } =
    useAccountsController_updateOneById();

  useEffect(() => {
    if (account) {
      form.setFieldsValue({
        accountName: account.accountName,
        accountType: account.accountType,
        currencyCode: account.currencyCode,
        initialBalance: account.balances[0].balanceAmount.amount,
      });
    }
  }, [account, form]);

  const handleClose = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = (
    values: Schemas.CreateAccountDto & {
      initialBalance: number;
    }
  ) => {
    const requestBody = {
      accountName: values.accountName,
      accountType: values.accountType,
      currencyCode: values.currencyCode,
    };

    const balances = [
      {
        balanceAmount: {
          amount: values.initialBalance,
          currency: values.currencyCode,
        },
      },
    ];

    if (account) {
      updateAccountById(
        {
          parameter: {
            id: account.id,
            companyId: companyId!,
          },
          requestBody: {
            ...requestBody,
            balances:
              values.initialBalance && values.currencyCode
                ? balances
                : undefined,
          },
        },
        {
          onSuccess: (response) => {
            updateAccount(response);
            handleClose();
          },
        }
      );
    } else {
      createNewAccount(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            ...requestBody,
            balances,
          },
        },
        {
          onSuccess: (response) => {
            createAccount(response);
            handleClose();
          },
        }
      );
    }
  };

  return (
    <Modal
      width={630}
      open={open}
      onCancel={handleClose}
      footer={null}
      title={
        <Title level={3}>
          {account
            ? t('accounts.accountEdit.title')()
            : t('accounts.accountAdd.title')()}
        </Title>
      }
      destroyOnClose
      centered
      {...rest}
    >
      <S.Inner>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            accountType: AccountType.Cash,
            currencyCode: 'EUR',
          }}
        >
          <Flex gap={20} vertical>
            <div>
              <Form.Item
                label={t('accounts.accountAdd.name.label')()}
                name="accountName"
                rules={[
                  {
                    required: true,
                    message: t('accounts.accountAdd.name.error')(),
                  },
                ]}
              >
                <S.FormInput
                  size="large"
                  placeholder={t('accounts.accountAdd.name.placeholder')()}
                />
              </Form.Item>

              {(!account || account.connection === null) && (
                <>
                  <Row gutter={[20, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label={t('accounts.accountAdd.type.label')()}
                        name="accountType"
                        rules={[
                          {
                            required: true,
                            message: t('accounts.accountAdd.type.error')(),
                          },
                        ]}
                      >
                        <S.FormSelect
                          placeholder={t(
                            'accounts.accountAdd.type.placeholder'
                          )()}
                          options={[
                            {
                              label: t(
                                `accounts.types.${AccountType.Current}`
                              )(),
                              value: AccountType.Current,
                            },
                            {
                              label: t(`accounts.types.${AccountType.Cash}`)(),
                              value: AccountType.Cash,
                            },
                            {
                              label: t(`accounts.types.${AccountType.Other}`)(),
                              value: AccountType.Other,
                            },
                          ]}
                          size="large"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label={t('accounts.accountAdd.currency.label')()}
                        name="currencyCode"
                        rules={[
                          {
                            required: true,
                            message: t('accounts.accountAdd.currency.error')(),
                          },
                        ]}
                      >
                        <S.FormSelect
                          placeholder={t(
                            'accounts.accountAdd.currency.placeholder'
                          )()}
                          options={currencyCodes.map((code) => ({
                            label: code,
                            value: code,
                          }))}
                          size="large"
                          showSearch
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <S.BalanceRow gutter={[20, 0]}>
                    <Col span={12}>
                      <Trans
                        i18nKey="accounts.accountAdd.initialBalance.hint"
                        components={[<S.Hint />, <S.Hint />]}
                      />
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label={t('accounts.accountAdd.initialBalance.label')()}
                        name="initialBalance"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'accounts.accountAdd.initialBalance.error'
                            )(),
                          },
                        ]}
                      >
                        <S.FormInputNumber placeholder="0.00" size="large" />
                      </Form.Item>
                    </Col>
                  </S.BalanceRow>
                </>
              )}
            </div>

            <Form.Item noStyle>
              <Flex justify="flex-end" gap={12}>
                <Button onClick={handleClose} size="large">
                  {t('accounts.accountAdd.buttonCancel')()}
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading || loadingUpdate}
                >
                  {account
                    ? t('accounts.accountEdit.buttonEdit')()
                    : t('accounts.accountAdd.buttonAdd')()}
                </Button>
              </Flex>
            </Form.Item>
          </Flex>
        </Form>
      </S.Inner>
    </Modal>
  );
};

export default AccountAddModal;
