import { createContext, useCallback, useMemo, useState } from 'react';

export type ModalManagerContext = {
  push: (modalId: string) => void;
  replace: (modalId: string) => void;
  pop: (...modalId: string[]) => void;
  isOpen: (modalId?: string) => boolean;
  createSetModalVisibility: (modalId: string) => (isOpen: boolean) => void;
};

export const ModalManager = createContext<ModalManagerContext | undefined>(
  undefined
);

export const ModalManagerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [modals, setModals] = useState<string[]>([]);

  const push = useCallback(
    (modalId: string) =>
      setModals((prev) => [
        ...prev.filter((modal) => modal !== modalId),
        modalId,
      ]),
    []
  );

  const replace = useCallback(
    (modalId: string) =>
      setModals((prev) => {
        prev.pop();

        return [...prev, modalId];
      }),
    []
  );

  const pop = useCallback(
    (...modalId: string[]) =>
      setModals((prev) => {
        if (modalId) {
          return prev.filter((modal) => !modalId.includes(modal));
        }

        prev.pop();

        return [...prev];
      }),
    []
  );

  const isOpen = useCallback(
    (modalId?: string) =>
      modalId ? modals.at(-1) === modalId : !!modals.length,
    [modals]
  );

  const createSetModalVisibility = useCallback(
    (modalId: string) => (isModalOpen: boolean) => {
      if (isModalOpen) {
        replace(modalId);
      } else {
        pop(modalId);
      }
    },
    [pop, replace]
  );

  const context = useMemo(
    () => ({ push, replace, pop, createSetModalVisibility, isOpen }),
    [isOpen, pop, push, replace, createSetModalVisibility]
  );

  return (
    <ModalManager.Provider value={context}>{children}</ModalManager.Provider>
  );
};
