const getTranslateByErrorCode = (errorCode: string) => {
  if (errorCode === 'wrongFile') {
    return t('common.documentErrorCode.wrongFile')();
  } else if (errorCode === 'notReadable') {
    return t('common.documentErrorCode.notReadable')();
  } else if (errorCode === 'notFull') {
    return t('common.documentErrorCode.notFull')();
  } else if (errorCode === 'noDateSignature') {
    return t('common.documentErrorCode.noDateSignature')();
  } else if (errorCode === 'tooOld') {
    return t('common.documentErrorCode.tooOld')();
  } else if (errorCode === 'expired') {
    return t('common.documentErrorCode.expired')();
  } else if (errorCode === 'wrongValue') {
    return t('common.documentErrorCode.wrongValue')();
  } else if (errorCode === 'missingDescription') {
    return t('common.documentErrorCode.missingDescription')();
  } else if (errorCode === 'notClearDescription') {
    return t('common.documentErrorCode.notClearDescription')();
  } else if (errorCode === 'provideSupportingDocument') {
    return t('common.documentErrorCode.provideSupportingDocument')();
  } else {
    return null;
  }
};

export default getTranslateByErrorCode;
