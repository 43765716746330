import { Typography } from 'antd';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaWidth } from '@ui-kit/theme/mediaWidth';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px;
  flex: 1;

  ${mediaWidth.tablet(css`
    text-align: left;
    align-items: flex-start;
    padding: 0;
  `)};
`;

export const Picture = styled.div`
  border-bottom: 0 solid ${({ theme }) => theme.colors.bgDarkGrey};

  ${mediaWidth.tablet(css`
    width: 100%;
    display: flex;
    justify-content: center;
    border-width: 2px;
    padding: 28px 0;
    margin-bottom: 28px;
  `)};

  svg {
    height: 272px;

    ${mediaWidth.tablet(css`
      height: 230px;
    `)};
  }
`;

export const Title = styled(Typography.Title)`
  max-width: 500px;

  &.ui-typography {
    font-size: 24px;
    margin-bottom: 16px;

    ${mediaWidth.tablet(css`
      max-width: 100%;
      width: 100%;
      font-size: 20px;
    `)};
  }
`;

export const Description = styled.div`
  .ui-typography {
    margin-bottom: 24px;
  }
`;
