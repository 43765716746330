import { Form } from 'antd';
import { rgba } from 'emotion-rgba';

import styled from '@emotion/styled';

export const SelectWrapper = styled(Form.Item)`
  width: 100%;
`;

export const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  background: ${({ theme }) => rgba(theme.colors.dark, 0.2)};
  width: 100%;
  height: 100%;
`;

export const TableContainer = styled.div`
  .ui-table-row {
    cursor: pointer;
  }
  .ui-table-wrapper table {
    width: auto;
  }
  overflow-x: auto;
  max-width: 100%;
`;
