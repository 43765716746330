import dayjs from 'dayjs';
import { FC } from 'react';

import * as S from './styled';

type Status = 'pending' | 'connected' | 'disconnected' | 'near_to_disconnect';

type AccountStatusDescriptionProps = {
  status: Status;
  connectedTill: string | null;
};

const AccountStatusDescription: FC<AccountStatusDescriptionProps> = ({
  status,
  connectedTill,
}) => {
  switch (status) {
    case 'disconnected': {
      return (
        <S.ErrorText>
          {t('accounts.statusDescription.disconnected')()}
        </S.ErrorText>
      );
    }
    case 'near_to_disconnect': {
      const time = connectedTill ? dayjs(connectedTill).toNow : '';

      return (
        <S.WarningText>
          {t('accounts.statusDescription.nearToDisconnect', {
            time,
          })()}
        </S.WarningText>
      );
    }

    case 'pending': {
      return (
        <S.SmallText>
          {t('accounts.statusDescription.pending')()}
        </S.SmallText>
      );
    }
    default:
      return null;
  }
};

export default AccountStatusDescription;
