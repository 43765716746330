import { Flex } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconClose } from '@assets';
import { PaymentDocumentUnit } from '@constants';
import { PaymentDocumentCard } from '@entities';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type IncomeInvoiceErrorProps = {
  type: PaymentDocumentUnit;
  selectedId?: string;
  file: Schemas.Document;
  details?: string;
  onSelectedInvoice: (id: string) => void;
  onDelete: () => void;
};

const PaymentDocumentInvoiceError: FC<IncomeInvoiceErrorProps> = ({
  type,
  selectedId,
  file,
  details,
  onDelete,
  onSelectedInvoice,
}) => {
  const navigate = useNavigate();

  const handleSelectInvoice = (id: string) => {
    navigate(`/${type}/${id}`);
    onSelectedInvoice?.(id);
  };

  return (
    <PaymentDocumentCard
      isSelected={selectedId === file.id}
      onClick={() => handleSelectInvoice(file.id)}
    >
      <Flex vertical>
        <S.Name>{file.name}</S.Name>
        {details && <S.Details>{details}</S.Details>}
      </Flex>

      <Button
        type="link"
        variant="link"
        icon={<IconClose />}
        size="middle"
        onClick={onDelete}
      />
    </PaymentDocumentCard>
  );
};

export default PaymentDocumentInvoiceError;
