import { Flex, Table } from 'antd';
import dayjs from 'dayjs';

import { Schemas } from '@api-client/generated/types';
import { IconBilling, IconDownload } from '@assets';
import { Card, Loader } from '@components';
import { DEFAULT_CURRENCY_CODE } from '@constants';
import { Amount, StatusTag } from '@entities';

import * as S from './styled';

type InvoiceItem = Schemas.InvoiceResponseDto;

type BillingHistoryProps = {
  invoices?: InvoiceItem[];
  isLoading: boolean;
};

const columns = [
  {
    key: 'issueDate',
    title: t('settings.billing.billingHistory.table.issueDate')(),
    render: (invoice: InvoiceItem) =>
      dayjs(invoice.issueDate).format('DD.MM.YYYY'),
  },
  {
    key: 'dueDate',
    title: t('settings.billing.billingHistory.table.dueDate')(),
    render: (invoice: InvoiceItem) =>
      dayjs(invoice.dueDate).format('DD.MM.YYYY'),
  },
  {
    key: 'reference',
    title: t('settings.billing.billingHistory.table.reference')(),
    render: (invoice: InvoiceItem) => invoice.number,
  },
  {
    key: 'amount',
    title: t('settings.billing.billingHistory.table.amount')(),
    render: (invoice: InvoiceItem) => (
      <Amount
        amount={invoice.amount}
        currencyCode={DEFAULT_CURRENCY_CODE}
        withColors
      />
    ),
  },
  {
    key: 'status',
    title: t('settings.billing.billingHistory.table.status')(),
    render: (invoice: InvoiceItem) => {
      const diff =
        invoice.status === 'unpaid'
          ? dayjs(invoice.dueDate).fromNow(true)
          : null;

      return <StatusTag status={invoice.status} additionalData={{ diff }} />;
    },
  },
  {
    key: 'actions',
    title: '',
    width: 48,
    render: (invoice: InvoiceItem) => (
      <a
        href={invoice.fileUrl}
        target="_blank"
        download={`${invoice.number}.pdf`}
      >
        <S.DownloadButton type="text">
          <IconDownload width={20} height={20} />
        </S.DownloadButton>
      </a>
    ),
  },
];

const BillingHistory = ({ invoices, isLoading }: BillingHistoryProps) => {
  const title = t('settings.billing.billingHistory.title')();

  if (isLoading) {
    return (
      <Card title={title}>
        <Loader />
      </Card>
    );
  }

  if (invoices?.length === 0) {
    return (
      <Card title={title}>
        <Flex gap={16} align="center" vertical>
          <S.Icon>
            <IconBilling width={32} height={32} />
          </S.Icon>
          <Flex align="center" vertical>
            <S.Title strong>
              {t('settings.billing.billingHistory.noHistory.title')()}
            </S.Title>
            <S.Subtitle>
              {t('settings.billing.billingHistory.noHistory.description')()}
            </S.Subtitle>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <Card title={title}>
      <Table
        pagination={false}
        loading={isLoading}
        columns={columns}
        dataSource={invoices}
      />
    </Card>
  );
};

export default BillingHistory;
