import { Flex } from 'antd';
import { FC, useState } from 'react';

import { IconPlus } from '@assets';
import { PageMeta, TeamMembers } from '@entities';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

const SettingsTeamMembers: FC = () => {
  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);

  return (
    <Flex gap={32} vertical>
      <PageMeta title={t('settings.title')()} />

      <S.Container>
        <Flex gap={24} vertical>
          <Flex justify="space-between">
            <S.Title>{t('settings.teamMembers.title')()}</S.Title>
            <Button
              size="small"
              data-testid="invite-button"
              onClick={() => setIsAccessModalOpen(true)}
              icon={<IconPlus />}
            >
              {t('settings.teamMembers.inviteMembers')()}
            </Button>
          </Flex>

          <TeamMembers
            isAccessModalOpen={isAccessModalOpen}
            onOpenAccessModal={setIsAccessModalOpen}
          />
        </Flex>
      </S.Container>
    </Flex>
  );
};

export default SettingsTeamMembers;
