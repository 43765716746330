import { Flex, Form as FormBase } from 'antd';

import styled from '@emotion/styled';
import { Button as ButtonBase } from '@ui-kit/Button/Button';

export const Root = styled(Flex)`
  height: 100%;
`;

export const Form = styled(FormBase)`
  height: 100%;
`;

export const Button = styled(ButtonBase)`
  flex: 1 0;
`;

export const Icon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;
