import { Flex, List } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useDocumentController_findAll } from '@api-client/generated/DocumentController/findAll';
import { Schemas } from '@api-client/generated/types';
import { Scrollbar } from '@components';
import {
  ContactsDocsEmpty,
  ContactsPaymentDocItem,
  FilterConstructor,
  FilterControl,
  TransactionFilePreviewModal,
} from '@entities';
import { useAccount } from '@hooks';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';

import * as S from './styled';

type DocumentFile = Schemas.Document;

const control: { left: FilterControl[] } = {
  left: [
    {
      type: 'search',
      formName: 'term',
    },
  ],
};

const usePaginatedDocumentsList = ({
  contactId,
  search,
  filterOptions,
}: {
  search: string;
  contactId: string | undefined;
  filterOptions: Record<string, unknown>;
}) => {
  const { companyId } = useAccount();

  if (!companyId) {
    throw new Error('Company ID is required');
  }

  const { metadata, incrementPage, hasNextPage, plainData, appendData } =
    usePagination<DocumentFile>(search);

  const {
    isFetching: isLoading,
    refetch,
    data,
  } = useDocumentController_findAll({
    params: {
      companyId,
      contactIds: contactId,
      withMetadataOnly: true,
      page: metadata.currentPage,
      ...filterOptions,
    },
  });

  useEffect(() => {
    if (data) {
      appendData(data);
    }
  }, [appendData, data]);

  const { sentryRef, rootRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  return {
    rootRef,
    refetch,
    plainData,
    isLoading,
    hasNextPage,
    sentryRef,
  };
};

const ContactsPaymentDocs: FC = () => {
  const { id: contactId } = useParams();
  const { search } = useLocation();

  const [isViewMode, setIsViewMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState<DocumentFile | null>(null);
  const [filterOptions, setFilterOptions] = useState<Record<string, unknown>>(
    {}
  );

  const { rootRef, refetch, plainData, isLoading, hasNextPage, sentryRef } =
    usePaginatedDocumentsList({ filterOptions, contactId, search });

  const handleOpenFilePreview = (file: DocumentFile) => {
    setSelectedFile(file);
    setIsViewMode(true);
  };

  const handleCloseFilePreview = () => {
    setSelectedFile(null);
    setIsViewMode(false);
  };

  const handleOnChange = useCallback(
    (values: Record<string, unknown>) => setFilterOptions({ ...values }),
    []
  );

  return (
    <Scrollbar height="calc(100vh - 385px)" ref={rootRef}>
      <Flex gap={24} vertical>
        {selectedFile && (
          <TransactionFilePreviewModal
            open={isViewMode}
            file={selectedFile}
            type={
              Number(selectedFile?.documentMetadata.amount) > 0
                ? 'income'
                : 'expenses'
            }
            onListUpdate={() => refetch()}
            onClose={handleCloseFilePreview}
          />
        )}

        <FilterConstructor<DocumentFile>
          controls={control}
          onChange={handleOnChange}
          onRequest={refetch}
          inversionTheme
        />

        <S.Documents>
          <List
            dataSource={plainData}
            renderItem={(file) => (
              <ContactsPaymentDocItem
                file={file}
                onView={() => handleOpenFilePreview(file)}
              />
            )}
            loading={isLoading}
            locale={{
              emptyText: <ContactsDocsEmpty />,
            }}
          />

          {hasNextPage && <div ref={sentryRef} />}
        </S.Documents>
      </Flex>
    </Scrollbar>
  );
};

export default ContactsPaymentDocs;
