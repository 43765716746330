import { Loader as CustomLoader } from '@components';
import styled from '@emotion/styled';

export const TableContainer = styled.div`
  .ui-table-row {
    cursor: pointer;
  }
`;

export const Loader = styled(CustomLoader)`
  margin-top: 150px;
`;

export const ExcludedTransaction = styled.span`
  color: ${({ theme }) => theme.colors.text200};
`;
