import { Card as AntdCard, Flex } from 'antd';

import styled from '@emotion/styled';

export const Field = styled.div<{ withSeparation: boolean }>`
  width: 100%;
  position: relative;
  padding-bottom: ${({ withSeparation }) => (withSeparation ? '20px' : 0)};
  margin-bottom: ${({ withSeparation }) => (withSeparation ? '20px' : 0)};
  border-bottom: ${({ withSeparation, theme }) =>
    withSeparation ? `1px solid ${theme.colors.strokeGrey}` : 0};

  && svg {
    color: ${({ theme }) => theme.colors.text200};
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Separator = styled(Flex)`
  position: absolute;
  left: 10px;
  top: 50px;

  width: 40px;
  height: 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  color: ${({ theme }) => theme.colors.text400};

  font-size: 12px;
  line-height: 20px;
`;

export const Card = styled(AntdCard)`
  padding: 0;

  && {
    .ui-card-head {
      background-color: ${({ theme }) => theme.colors.bgDarkGrey};
      padding: 7px 20px;
    }

    .ui-card-body {
      background-color: ${({ theme }) => theme.colors.badgeGrey};
      padding: 20px 20px 13px 20px;
    }

    .ui-form-item {
      margin-bottom: 0;
    }
  }
`;

export const RemoveBox = styled.div`
  width: 20px;
  margin-left: auto;
`;
