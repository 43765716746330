import { Modal as BaseModal, Flex } from 'antd';

import styled from '@emotion/styled';

import { useGetRules } from '../useGetRules';

const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

const Description = styled.span`
  font-size: 16px;
  line-height: 24px;
`;

export const AddRulesFlowModal = () => {
  const {
    modalProps: { title, description, children, ...modalProps },
  } = useGetRules();

  return (
    <BaseModal width={904} {...modalProps}>
      <Flex gap={20} vertical>
        <Flex gap={9} vertical>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Flex>
        {children}
      </Flex>
    </BaseModal>
  );
};
