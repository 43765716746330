import { Button } from 'antd';
import { FC } from 'react';

import { IconSettingsSemiBold } from '@assets';
import { Card } from '@components';
import { SettingsCard } from '@entities';
import { useClickOutside } from '@hooks';

import * as S from './styled';

type TransactionSettingsButtonProps = {
  initialFileless: boolean;
  initialPlDate: boolean;
  isFilelessOpen: boolean;
  onToggle: () => void;
  onChange: (key: 'isFileless' | 'isIgnoredForPL', value: boolean) => void;
};

const TransactionSettingsButton: FC<TransactionSettingsButtonProps> = ({
  onChange,
  onToggle,
  isFilelessOpen,
  initialFileless,
  initialPlDate,
}) => {
  const ref = useClickOutside(onToggle);

  return (
    <S.Container align="center">
      <Button
        icon={<IconSettingsSemiBold />}
        size="small"
        type="link"
        onClick={onToggle}
      />
      {isFilelessOpen && (
        <S.CardWrapper ref={ref}>
          <Card padding={24}>
            <SettingsCard
              initialFileless={initialFileless}
              initialPlDate={initialPlDate}
              onChange={onChange}
            />
          </Card>
        </S.CardWrapper>
      )}
    </S.Container>
  );
};

export default TransactionSettingsButton;
