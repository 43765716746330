import { Table } from 'antd';
import { type Dayjs } from 'dayjs';
import { FC, Key, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCategoryGroupController_findAll } from '@api-client/generated/CategoryGroupController/findAll';
import { Parameter$TransactionController_getStatsByCategory } from '@api-client/generated/client';
import { Card } from '@components';
import { useAccount, useTranslate } from '@hooks';
import { getSearchParams, setSearchParams } from '@utils';

import * as config from './config';
import * as S from './styled';

type BreakDown =
  Parameter$TransactionController_getStatsByCategory['breakDown'];

type ReportTableProps = {
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
  datePeriod: BreakDown;
  categoryIn: config.ReportRow[];
  categoryOut: config.ReportRow[];
  showByPlDate: string;
};

const ReportTable: FC<ReportTableProps> = ({
  dateFrom,
  dateTo,
  datePeriod,
  categoryIn,
  categoryOut,
  showByPlDate,
}) => {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const { search } = useLocation();

  const searchParams = getSearchParams<{
    expanded: string[] | undefined;
    dateTo?: string;
    dateFrom?: string;
  }>(search);

  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>(
    searchParams?.expanded || []
  );

  const { data: categoryGroups, isPending: loading } =
    useCategoryGroupController_findAll({
      params: {
        companyId: companyId!,
      },
    });

  useEffect(() => {
    const periods = {
      dateTo: searchParams.dateTo,
      dateFrom: searchParams.dateFrom,
    };
    const expandedRows = expandedRowKeys?.length
      ? { ...periods, expanded: expandedRowKeys }
      : { ...periods };

    navigate(`/report?${setSearchParams(expandedRows)}`);
  }, [expandedRowKeys, navigate, searchParams.dateFrom, searchParams.dateTo]);

  const groupedDataSource = useMemo(
    () =>
      config.statsGroupByKey({
        defaultName: translate('report.defaultCategoryName'),
        unit: datePeriod,
        categoryIn,
        categoryOut,
      }),
    [categoryIn, categoryOut, datePeriod, translate]
  );

  if (!categoryGroups) {
    return null;
  }

  return (
    <Card padding={0}>
      <S.Container>
        <Table
          columns={config.createColumns(
            { dateFrom, dateTo, unit: datePeriod },
            translate
          )}
          dataSource={config.createDataSource(
            {
              data: groupedDataSource,
              categoryIn,
              categoryOut,
              unit: datePeriod,
              dateFrom,
              dateTo,
              categoryGroups,
              showByPlDate,
            },
            translate,
            navigate
          )}
          loading={loading}
          pagination={false}
          rowClassName={({ children }) => (children ? 'row-with-children' : '')}
          expandable={{
            expandIcon: ({ record, onExpand }) =>
              record.children ? (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onExpand(record, e!);
                  }}
                >
                  {expandedRowKeys.includes(record.key) ? (
                    <S.ExpandableIconMinus />
                  ) : (
                    <S.ExpandableIconPlus />
                  )}
                </span>
              ) : null,
            onExpandedRowsChange: (rows) => {
              setExpandedRowKeys(
                rows.filter((row) => row !== 'table-total') as Key[]
              );
            },
            expandedRowClassName: ({ isCategory }) =>
              isCategory
                ? 'row-with-expandable-is-category'
                : 'row-with-expandable',
            expandRowByClick: true,
            expandedRowKeys,
          }}
          scroll={{
            x: config.getTableWidth({ unit: datePeriod, dateFrom, dateTo }),
          }}
        />
      </S.Container>
    </Card>
  );
};

export default ReportTable;
