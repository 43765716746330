import { Button, Flex, Menu, Popover } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import { ReactNode, useState } from 'react';

import { IconSettingsBold } from '@assets';
import { useTranslate } from '@hooks';

import * as S from './styled';

export type DropdownSelectProps = {
  options: (DefaultOptionType & { value: string })[];
  value?: string[];
  onChange: (value: string[]) => void;
  icon?: ReactNode;
};

const DropdownSelect = ({
  options,
  value = [],
  onChange,
  icon = <IconSettingsBold />,
}: DropdownSelectProps) => {
  const { translate } = useTranslate();

  const [tempValue, setTempValue] = useState(value);
  const [open, setOpen] = useState(false);

  const handleMenuClick: Parameters<typeof Menu>[0]['onClick'] = (e) => {
    e.domEvent.stopPropagation();

    const index = tempValue.indexOf(e.key);
    const newValue = [...tempValue];

    if (index === -1) {
      newValue.push(e.key);
    } else {
      newValue.splice(index, 1);
    }

    setTempValue(newValue);
  };

  const handleApplyClick = () => {
    onChange(tempValue);
    setOpen(false);
  };

  return (
    <Popover
      open={open}
      content={
        <Flex gap={6} vertical>
          <S.Menu
            multiple={true}
            onClick={handleMenuClick}
            items={options.map((option) => ({
              key: option.value,
              label: (
                <S.Item
                  checked={tempValue.includes(option.value as string)}
                  data-testid={option.label}
                >
                  {option.label}
                </S.Item>
              ),
            }))}
          />

          <Flex align="center" justify="center" gap={20}>
            <Button
              type="primary"
              onClick={handleApplyClick}
              size="small"
              data-testid="dropdown-select-apply-button"
              block
            >
              {translate('common.actions.apply')}
            </Button>
          </Flex>
        </Flex>
      }
      trigger="click"
      placement="bottom"
      onOpenChange={() => setOpen((prevState) => !prevState)}
    >
      <Button
        icon={icon}
        color="primary"
        variant="filled"
        size="small"
        data-testid="dropdownSelect-open-button"
      />
    </Popover>
  );
};

export default DropdownSelect;
