import { Col, DatePicker, Form, Input, InputNumber, Row, message } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useDocumentController_updateOneById } from '@api-client/generated/DocumentController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { Select } from '@components';
import { PaymentDocumentUnit, currencyCodes } from '@constants';
import {
  FilterContacts,
  PaymentDocumentAttachedTransactions,
  PaymentDocumentSwitchControl,
} from '@entities';
import { useAccount } from '@hooks';
import { DATE_ISO_FORMAT, getDateDefault } from '@utils';

import { getTranslatesByType } from './helpers';
import * as S from './styled';

type Document = Schemas.Document;

type PaymentDocumentDetailsFormProps = {
  type: PaymentDocumentUnit;
  details: Document;
  onUpdate: (document: Document | null) => void;
  onAttachTransaction: (document: Document | null) => void;
  onRefreshFile: () => void;
  hasSwitches?: boolean;
};

const PaymentDocumentDetailsForm: FC<PaymentDocumentDetailsFormProps> = ({
  type,
  details,
  onUpdate,
  onAttachTransaction,
  onRefreshFile,
  hasSwitches = true,
}) => {
  const { companyId, userAccess } = useAccount();

  const translates = getTranslatesByType(type);

  const [selectedStatus, setSelectedStatus] = useState<string>('');

  const [form] = Form.useForm();

  const { mutate: updateFile } = useDocumentController_updateOneById();

  const isReviewed = details.documentMetadata.isReviewed;

  useEffect(() => {
    if (details) {
      const { contact, documentMetadata: data } = details;

      form.setFieldsValue({
        number: data.number,
        contact: contact?.id,
        description: data.description,
        issueDate: data.issueDate ? dayjs(data.issueDate) : null,
        dueDate: data.dueDate ? dayjs(data.dueDate) : null,
        amount: data.amount,
        currency: data.currency,
      });

      setSelectedStatus(details?.documentMetadata.status || '');
    }
  }, [details, form]);

  const handleUpdateDetails = useDebouncedCallback(() => {
    if (details.documentMetadata?.isReviewed) {
      form.submit();
    }
  }, 1000);

  const handleSubmit = (
    values: Document['documentMetadata'] & { contact: string }
  ) => {
    const formData = new FormData();

    if (selectedStatus) {
      formData.append('documentMetadata[status]', selectedStatus);
    }

    if (values.number) {
      formData.append('documentMetadata[number]', values.number!);
    }

    if (values.contact) {
      formData.append('contactId', values.contact!);
    }

    if (values.description) {
      formData.append('documentMetadata[description]', values.description!);
    }

    if (values.amount) {
      formData.append('documentMetadata[amount]', String(values.amount));
    }

    if (values.currency) {
      formData.append('documentMetadata[currency]', values.currency!);
    }

    if (values.issueDate) {
      formData.append(
        'documentMetadata[issueDate]',
        getDateDefault(values.issueDate!, DATE_ISO_FORMAT)
      );
    }

    if (values.dueDate) {
      formData.append(
        'documentMetadata[dueDate]',
        getDateDefault(values.dueDate!, DATE_ISO_FORMAT)
      );
    }

    if (details.documentMetadata?.ignoreDuplicate) {
      formData.append(
        'documentMetadata[ignoreDuplicate]',
        JSON.stringify(true)
      );
    }

    formData.append('documentMetadata[isReviewed]', JSON.stringify(true));

    updateFile(
      {
        parameter: {
          companyId: companyId!,
          id: details.id,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        requestBody: formData as any,
      },
      {
        onSuccess: (response) => {
          onUpdate(response);
          message.success(translates.successfullyUpdated);
        },
      }
    );
  };

  const handleSelectStatus = (status: string) => {
    setSelectedStatus(status);

    if (details.documentMetadata?.isReviewed) {
      handleUpdateDetails();
    }
  };

  return (
    <S.Container>
      {hasSwitches && (
        <PaymentDocumentSwitchControl
          type={type}
          status={selectedStatus}
          onChange={handleSelectStatus}
        />
      )}

      <Form
        form={form}
        onFinish={handleSubmit}
        requiredMark={false}
        colon={false}
        layout="vertical"
      >
        <Form.Item label={translates.numberLabel} name="number">
          <Input
            placeholder={translates.numberPlaceholder}
            size="large"
            onChange={handleUpdateDetails}
          />
        </Form.Item>

        <Form.Item label={translates.contactLabel} name="contact">
          <FilterContacts
            placeholder={translates.contactPlaceholder}
            onChange={handleUpdateDetails}
          />
        </Form.Item>

        <Form.Item label={translates.descriptionLabel} name="description">
          <Input.TextArea
            placeholder={translates.descriptionPlaceholder}
            size="large"
            onChange={handleUpdateDetails}
          />
        </Form.Item>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item label={translates.issueDateLabel} name="issueDate">
              <DatePicker
                placeholder={translates.issueDatePlaceholder}
                size="large"
                onChange={handleUpdateDetails}
                format="DD.MM.YYYY"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={translates.dueDateLabel} name="dueDate">
              <DatePicker
                placeholder={translates.dueDatePlaceholder}
                size="large"
                onChange={handleUpdateDetails}
                format="DD.MM.YYYY"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item label={translates.amountLabel} name="amount">
              <InputNumber
                placeholder={translates.amountPlaceholder}
                size="large"
                onChange={handleUpdateDetails}
                decimalSeparator=","
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={translates.currencyLabel} name="currency">
              <Select
                options={currencyCodes.map((code) => ({
                  label: code,
                  value: code,
                }))}
                onChange={handleUpdateDetails}
                size="large"
                showSearch
              />
            </Form.Item>
          </Col>
        </Row>

        {!isReviewed && (
          <Form.Item noStyle>
            <S.ButtonSubmit type="primary" htmlType="submit" block>
              {translates.buttonSubmit}
            </S.ButtonSubmit>
          </Form.Item>
        )}

        {userAccess?.transactions && (
          <PaymentDocumentAttachedTransactions
            type={type}
            id={details.id}
            transactions={details?.transactions}
            potentialTransactions={details?.potentialTransactions}
            onAttachTransaction={onAttachTransaction}
            onRefreshFile={onRefreshFile}
          />
        )}
      </Form>
    </S.Container>
  );
};

export default PaymentDocumentDetailsForm;
