//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$TransactionController_getStatsByCategory,
  Response$TransactionController_getStatsByCategory$Status$200,
  ResponseContentType$TransactionController_getStatsByCategory,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useTransactionController_getStatsByCategory = (options: {
  params: Params$TransactionController_getStatsByCategory['parameter'];
  config?: QueryOptions<
    Response$TransactionController_getStatsByCategory$Status$200[ResponseContentType$TransactionController_getStatsByCategory]
  >;
}) =>
  useApiClient<
    Params$TransactionController_getStatsByCategory,
    Response$TransactionController_getStatsByCategory$Status$200[ResponseContentType$TransactionController_getStatsByCategory]
  >({
    method: 'TransactionController_getStatsByCategory',
    params: { parameter: options.params },
    config: options.config,
  });
