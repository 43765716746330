import { Flex } from 'antd';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTransactionIngestController_delete } from '@api-client/generated/TransactionIngestController/delete';
import { Schemas } from '@api-client/generated/types';
import { Loader } from '@components';
import {
  TransactionIngestPopupDelete,
  TransactionIngestTransactions,
} from '@entities';
import { useAccount } from '@hooks';
import { downloadFile } from '@utils';

import * as S from './styled';

type TransactionIngestCardProps = {
  isEmptyAccount: boolean;
  details: Schemas.TransactionIngest | null;
  onAfterDelete: () => void;
};

const TransactionIngestCard: FC<TransactionIngestCardProps> = ({
  isEmptyAccount,
  details,
  onAfterDelete,
}) => {
  const navigate = useNavigate();

  const { companyId } = useAccount();

  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);

  const { mutate: deleteTransactionIngest } =
    useTransactionIngestController_delete();

  const handleDelete = () => {
    if (details) {
      deleteTransactionIngest(
        {
          parameter: {
            companyId: companyId!,
            id: details.id,
          },
        },
        {
          onSuccess: () => {
            onAfterDelete();
            setIsVisibleModalDelete(false);

            navigate('/csv-imports');
          },
        }
      );
    }
  };

  const handleDownload = () =>
    details && downloadFile(details.fileUrl, details.account.name, true);

  if (isEmptyAccount) {
    return (
      <S.Container>
        <div />
      </S.Container>
    );
  }

  if (!details) {
    return (
      <S.Container justify="center" align="center">
        <Loader />
      </S.Container>
    );
  }

  return (
    <S.Container gap={24} vertical>
      <TransactionIngestPopupDelete
        open={isVisibleModalDelete}
        onDelete={handleDelete}
        onCancel={() => setIsVisibleModalDelete(false)}
      />

      <Flex align="center" justify="space-between" gap={24}>
        <Flex gap={4} vertical>
          <S.Title>{details.account.name}</S.Title>

          <S.Description>
            {dayjs(details.createdAt).format('DD.MM.YYYY, HH:mm')}
          </S.Description>
        </Flex>
      </Flex>

      <TransactionIngestTransactions
        ingestId={details.id}
        onDownload={handleDownload}
        onRemove={() => setIsVisibleModalDelete(true)}
      />
    </S.Container>
  );
};

export default TransactionIngestCard;
