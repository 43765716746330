import { Button, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Language } from '@constants';
import { OnboardingCardHeader, OnboardingRadioControl } from '@entities';
import { useOnboarding } from '@hooks';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingWelcomeLanguage: FC = () => {
  const { updateScreen, updateIncorporation } = useOnboarding();

  const { i18n } = useTranslation();

  const handleNext = () => {
    updateIncorporation({
      signUp: {
        locale: i18n.language,
      },
    });

    updateScreen('WELCOME', { active: true });
  };

  return (
    <S.Container>
      <S.Inner>
        <OnboardingCardHeader
          title={t('onboarding.welcomeLanguage.title')()}
          description={
            <>
              <Paragraph>
                {t('onboarding.welcomeLanguage.description')()}
              </Paragraph>

              <Paragraph strong>
                {t('onboarding.welcomeLanguage.label')()}
              </Paragraph>
            </>
          }
        />

        <OnboardingRadioControl
          value={i18n.language}
          setValue={i18n.changeLanguage}
          controls={[
            {
              value: Language.En,
              title: 'English',
            },
            {
              value: Language.Fr,
              title: 'Français',
            },
          ]}
        />
      </S.Inner>

      <S.Actions justify="flex-end" gap={16}>
        <S.LoginHint>
          <Link to="/login">{t('onboarding.welcomeLanguage.loginHint')()}</Link>
        </S.LoginHint>

        <Button type="primary" onClick={handleNext}>
          {t('onboarding.buttonStartIncorporation')()}
        </Button>
      </S.Actions>
    </S.Container>
  );
};

export default OnboardingWelcomeLanguage;
