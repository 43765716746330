import { PaymentDocumentUnit } from '@constants';

const getTranslatesByType = (type: PaymentDocumentUnit) =>
  type === 'income'
    ? {
        attachedTransactionsTitle: t('income.attachedTransactions.title')(),
        noAttachedTransactionsTitle: t('income.noAttachedTransactions.title')(),
        buttonAttach: t('income.noAttachedTransactions.buttonAttach')(),
        noAttachedTransactionsDescription: t(
          'income.noAttachedTransactions.description'
        )(),
        attachedTransactionsDescription: t(
          'income.attachedTransactions.description'
        )(),
        buttonChoose: t('income.details.buttonChoose')(),
        buttonYes: t('income.details.buttonYes')(),
        successfullyUpdated: t('income.successfullyUpdated')(),
      }
    : {
        attachedTransactionsTitle: t('expenses.attachedTransactions.title')(),
        noAttachedTransactionsTitle: t(
          'expenses.noAttachedTransactions.title'
        )(),
        buttonAttach: t('expenses.noAttachedTransactions.buttonAttach')(),
        noAttachedTransactionsDescription: t(
          'expenses.noAttachedTransactions.description'
        )(),
        attachedTransactionsDescription: t(
          'expenses.attachedTransactions.description'
        )(),
        buttonChoose: t('expenses.details.buttonChoose')(),
        buttonYes: t('expenses.details.buttonYes')(),
        successfullyUpdated: t('expenses.successfullyUpdated')(),
      };

export default getTranslatesByType;
