import { Button, Flex, Space, Table } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useTransactionController_findAll } from '@api-client/generated/TransactionController/findAll';
import { Schemas } from '@api-client/generated/types';
import { IconClose } from '@assets';
import { SearchControl } from '@entities';
import {
  useAccount,
  useDocumentsTransactionsColumns,
  useTranslate,
} from '@hooks';

import * as S from './styled';

type Transaction = Schemas.Transaction;

type AttachTransactionsModalProps = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  onAttachTransactions: (transactions: Transaction[]) => void;
  fileTransactions: Transaction[];
};
const AttachTransactionsModal: FC<AttachTransactionsModalProps> = ({
  open,
  setOpen,
  onAttachTransactions,
  fileTransactions,
}) => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();
  const { columns, selectedTransactions, setSelectedTransactions } =
    useDocumentsTransactionsColumns('all');

  const [filterSearch, setFilterSearch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const onChange = (page: number) => {
    setCurrentPage(page);
  };

  const {
    data,
    isPending: loading,
    refetch,
  } = useTransactionController_findAll({
    params: {
      companyId: companyId!,
      term: filterSearch!,
      page: currentPage,
      perPage: 5,
    },
  });

  useEffect(() => {
    setSelectedTransactions(fileTransactions);
  }, [fileTransactions, setSelectedTransactions]);

  useEffect(() => {
    if (filterSearch || filterSearch === '') {
      refetch();
    }
  }, [filterSearch, refetch]);

  const handleSearch = useDebouncedCallback((value) => {
    setFilterSearch(value);
    setCurrentPage(1);
  }, 200);

  const handleOk = () => {
    onAttachTransactions(selectedTransactions);
    setOpen(false);
  };

  const footer = (
    <Flex align="center" justify="space-between">
      <Space split={<S.Divider />}>
        <S.Pagination
          current={currentPage}
          onChange={onChange}
          showSizeChanger={false}
          pageSize={5}
          total={data?.metadata.totalRecords}
        />
        <S.Text>
          {translate('documentsPage.table.selected', {
            count: selectedTransactions.length,
          })}
        </S.Text>
      </Space>

      <Flex gap={10}>
        <Button onClick={() => setOpen(false)}>
          {translate('documentsPage.table.cancel')}
        </Button>
        <Button
          onClick={handleOk}
          type="primary"
          disabled={selectedTransactions.length === 0}
        >
          {translate('documentsPage.table.attach')}
        </Button>
      </Flex>
    </Flex>
  );

  return (
    <S.ModalPaper
      open={open}
      title={translate('documentsPage.form.attachTransactions')}
      centered
      closeIcon={<IconClose />}
      width={1000}
      footer={footer}
      onCancel={() => {
        setOpen(false);
      }}
    >
      <SearchControl onChange={(e) => handleSearch(e.target.value)} />
      <S.TableContainer>
        <Table
          rowKey={({ id }) => id}
          dataSource={data?.records}
          columns={columns}
          loading={loading}
          pagination={false}
        />
      </S.TableContainer>
    </S.ModalPaper>
  );
};

export default AttachTransactionsModal;
