import { Flex } from 'antd';

import * as S from './styled';

type RadioControl<TValue> = {
  value: TValue;
  title: string;
  content?: React.ReactNode;
  rightContent?: React.ReactNode;
};

type OnboardingRadioControlProps<TValue> = {
  controls: RadioControl<TValue>[];
  value: TValue;
  setValue: (value: TValue) => void;
};

const OnboardingRadioControl = <TValue extends string>({
  value,
  controls,
  setValue,
}: OnboardingRadioControlProps<TValue>) => (
  <S.Container>
    {controls.map(({ content, rightContent, ...control }) => (
      <S.Control
        key={control.value}
        onClick={() => setValue(control.value)}
        selected={control.value === value}
        rightContent={!!rightContent}
      >
        <S.ControlIcon selected={control.value === value} />

        <Flex gap={16}>
          <Flex vertical>
            <S.Title>{control.title}</S.Title>

            {content && <S.Content>{content}</S.Content>}
          </Flex>
        </Flex>

        {rightContent && <S.RightContent>{rightContent}</S.RightContent>}
      </S.Control>
    ))}
  </S.Container>
);

export default OnboardingRadioControl;
