import { Flex, type FlexProps } from 'antd';
import { rgba } from 'emotion-rgba';

import styled from '@emotion/styled';

export const Container = styled(Flex)<FlexProps>`
  width: 100%;
  height: calc(100vh - 172px);
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
  flex: 1;
  padding: 30px;

  .ui-tabs-nav {
    &:before {
      border-color: ${({ theme }) => theme.colors.textDisabled};
    }
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.text400};
`;
