import { Typography } from 'antd';

import styled from '@emotion/styled';

export const Title = styled(Typography.Title)`
  &.ui-typography {
    font-size: 24px;
    margin-bottom: 15px;
  }
`;

export const Description = styled.div`
  max-width: 560px;
  margin-bottom: 28px;
  text-align: center;
`;

export const Picture = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;

  img,
  svg {
    height: 100%;
  }
`;

export const Divider = styled.div`
  width: 457px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.strokeDarkGrey};
  margin: 28px 0;
`;
