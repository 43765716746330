import { Button, Flex, type FlexProps } from 'antd';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@theme';
import { mediaWidth } from '@ui-kit/theme/mediaWidth';

import { type Status } from './OnboardingOverview';

type StepProps = {
  active?: boolean;
};

type StepWithStatusProps = {
  status: Status;
} & StepProps;

type CompletedProps = {
  completed: boolean;
};

const getStepColorByStatus = (status: Status, active?: boolean) => {
  if (status === 'completed') {
    return colors.iconLineSuccess;
  }

  if (status === 'inProgress') {
    return colors.strokeGrey;
  }

  if (status === 'errorsFound') {
    return colors.error;
  }

  if (active) {
    return colors.primary;
  }

  return colors.strokeGrey;
};

const getBadgeColorByStatus = (status?: Status, active?: boolean) => {
  if (status === 'completed' || status === 'inProgress' || active) {
    return colors.primary;
  }

  if (status === 'errorsFound') {
    return colors.error;
  }

  return colors.secondary;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Step = styled.div<StepWithStatusProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ status, active }) => getStepColorByStatus(status, active)};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 24px;

  ${mediaWidth.tablet(css`
    padding: 20px;
  `)};
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 18px;

  ${mediaWidth.tablet(css`
    font-size: 16px;
  `)};
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  margin-top: 12px;

  .ui-btn {
    padding-inline: 32px;
  }
`;

export const Divider = styled(Flex)<FlexProps>`
  color: ${({ theme }) => theme.colors.text400};
  font-weight: 600;
  font-size: 12px;

  &:before,
  &:after {
    content: '';
    width: 13px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.text400};
  }
`;

export const StepNumber = styled.div<Partial<StepWithStatusProps>>`
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ active, status }) =>
    getBadgeColorByStatus(status, active)};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
`;

export const Completed = styled.div<CompletedProps>`
  background-color: ${({ theme, completed }) =>
    completed ? theme.colors.badgeSuccess : theme.colors.white};
  border: 1px solid
    ${({ theme, completed }) =>
      completed ? theme.colors.badgeSuccess : theme.colors.strokeGrey};
  border-radius: 12px;
  color: ${({ theme, completed }) =>
    completed ? theme.colors.success : theme.colors.text400};
  cursor: ${(props: CompletedProps) =>
    props.completed ? 'pointer' : 'default'};
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 14px;
`;

export const ButtonReview = styled(Button)`
  &.ui-btn {
    height: 16px;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
  }
`;
