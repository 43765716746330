import { Flex, Modal, type ModalProps, message } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC, useState } from 'react';

import { useFileMappingController_create } from '@api-client/generated/FileMappingController/create';
import { Schemas } from '@api-client/generated/types';
import { AccountsSelect } from '@entities';
import { useAccount } from '@hooks';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type TransactionIngestImportProps = {
  onCancel: () => void;
  onAfterImport: (accountId: string) => void;
} & ModalProps;

const TransactionIngestImport: FC<TransactionIngestImportProps> = ({
  open,
  onCancel,
  onAfterImport,
  ...rest
}) => {
  const { companyId } = useAccount();

  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    null
  );

  const [selectedFile, setSelectedFile] = useState<
    UploadRequestOption['file'] | null
  >(null);

  const { mutate: createFileMappings, isPending: isLoading } =
    useFileMappingController_create();

  const handleSubmit = () => {
    if (!selectedAccountId) {
      return message.error(
        t('csvImports.importForm.errors.notSelectedAccount')()
      );
    }

    if (!selectedFile) {
      return message.error(t('csvImports.importForm.errors.notUploadedFile')());
    }

    createFileMappings(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          accountId: selectedAccountId,
          mapping: {
            topRowsSkipAmount: 0,
            bottomRowsSkipAmount: 0,
          },
          file: selectedFile,
          // TODO: Need to sync with backend and make corrections to types
        } as Schemas.FileMappingDto,
      },
      {
        onSuccess: () => {
          setSelectedAccountId(null);
          setSelectedFile(null);

          onAfterImport(selectedAccountId);
          onCancel();
        },
      }
    );
  };

  return (
    <Modal
      width={610}
      open={open}
      title={t('csvImports.importForm.title')()}
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <S.Card>
        <Flex gap={24} vertical>
          <Flex gap={4} vertical>
            <S.AccountsLabel>
              {t('common.accountsControl.label')()}
            </S.AccountsLabel>

            <AccountsSelect onChange={setSelectedAccountId} />
          </Flex>

          <S.UploadWrap
            multiple={false}
            customRequest={(options: UploadRequestOption) =>
              setSelectedFile(options.file)
            }
            showUploadList={false}
          >
            <Flex align="center" justify="space-between">
              <S.UploadWrapLabel>
                {t('csvImports.importForm.upload.label')()}
              </S.UploadWrapLabel>

              <Button size="small" ghost>
                {t('csvImports.importForm.upload.buttonSelect')()}
              </Button>
            </Flex>
          </S.UploadWrap>
        </Flex>
      </S.Card>

      <S.Submit>
        <Flex justify="flex-end" gap={12}>
          <Button onClick={onCancel}>
            {t('csvImports.importForm.buttonCancel')()}
          </Button>

          <Button type="primary" loading={isLoading} onClick={handleSubmit}>
            {t('csvImports.importForm.buttonImport')()}
          </Button>
        </Flex>
      </S.Submit>
    </Modal>
  );
};

export default TransactionIngestImport;
