import styled from '@emotion/styled';
import { Flex } from 'antd';
import { Amount as AmountBase } from '@entities';

export const Wrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 10px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};

    .ui-btn {
      opacity: 1;
      pointer-events: auto;
    }
    .accountName {
      width: 235px;
    }
  }
`;

export const Container = styled(Flex)`
  width: 100%;
`;

export const Logo = styled.img`
  width: 24px;
  height: 24px;
`;

export const Name = styled.div`
  width: 100%;
  max-width: 280px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Header = styled(Flex)`
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.bgGrey};
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    color: ${({ theme }) => theme.colors.grey500};
    font-size: 12px;
    line-height: 20px;
  }
`;

export const Edit = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Action = styled.span`
  font-weight: 600;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    opacity: 0.7;
  }
`;

export const Amount = styled(AmountBase)`
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
`;

export const ConnectBlock = styled(Flex)`
  min-width: 96px;
  height: 20px;
  align-items: center;
  justify-content: center;
`
