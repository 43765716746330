import { Flex, InputNumber, Typography } from 'antd';

import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .ui-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
`;

export const Inner = styled.div`
  margin-bottom: 24px;
`;

export const FormInner = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 8px;
  padding: 14px 20px;
  margin-bottom: 16px;
`;

export const FormItem = styled.div`
  padding: 14px 0;

  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  }
`;

export const FormLabel = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  padding: 0;
`;

export const ButtonAdd = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;

  .ui-icon {
    font-size: 16px;
  }
`;

export const Total = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
`;

export const ButtonRemove = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  display: inline-flex;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

export const NumberInput = styled(InputNumber)`
  width: 100%;
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.error};
`;

export const ExtraText = styled(Typography.Paragraph)`
  &.ui-typography {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.textBlue400};
  }
`;

export const EmptyContainer = styled(Flex)`
  height: 50px;
  padding: 100px 0;
  background: ${({ theme }) => theme.colors.bgGrey};
`;

export const EmptyTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black};
`;

export const EmptyDescription = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text400};
`;
