import { Button, Form, Input } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type LoginFormProps = {
  isLoading?: boolean;
  error?: string;
  onError?: (error: string) => void;
  onSubmit: (values: Schemas.LoginDto) => void;
};

const LoginForm: FC<LoginFormProps> = ({
  isLoading,
  error,
  onSubmit,
  onError,
}) => (
  <Form
    onFinish={onSubmit}
    onValuesChange={() => onError?.('')}
    layout="vertical"
    requiredMark={false}
  >
    <Form.Item
      label={t('auth.login.email.label')()}
      name="email"
      rules={[{ required: true, message: t('auth.login.email.error')() }]}
    >
      <Input placeholder={t('auth.login.email.placeholder')()} size="large" />
    </Form.Item>

    <Form.Item
      label={t('auth.login.password.label')()}
      name="password"
      rules={[
        {
          required: true,
          message: t('auth.login.password.error')(),
        },
      ]}
      style={{ marginBottom: '16px' }}
    >
      <Input.Password
        placeholder={t('auth.login.password.placeholder')()}
        size="large"
      />
    </Form.Item>

    {error && <S.Error>{t('auth.login.invalidCredentials')()}</S.Error>}

    <S.ForgotPassword to="/forgot-password">
      {t('auth.login.forgotPassword')()}
    </S.ForgotPassword>

    <Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        loading={isLoading}
        block
      >
        {t('auth.login.buttonLogin')()}
      </Button>
    </Form.Item>
  </Form>
);

export default LoginForm;
