import { Flex, Typography } from 'antd';

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import { SubItemType } from '../../../pages/Categories/Categories';

export const EmptyContainer = styled(Flex)`
  padding: 6px;
  margin-left: 8px;
  cursor: default;
`;

export const EmptyText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isempty',
})<SubItemType>`
  color: ${({ theme, isempty }) =>
    isempty ? theme.colors.textDisabled : theme.colors.primary};
`;

export const Wrapper = styled.div<SubItemType>`
  opacity: ${({ isdragging }) => (isdragging ? 0 : 1)};
`;

export const Container = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 6px;

  &:hover {
    background: ${({ theme }) => theme.colors.bgLightBlue200};
  }
`;

export const TitleWrapper = styled(Flex)`
  align-items: center;
`;

export const DotsWrapper = styled.div`
  width: 8px;
  height: 14px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: grab;
`;

export const SubItemActionsContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 76px;
`;

export const PlusIcon = styled(PlusOutlined)`
  color: ${({ theme }) => theme.colors.text400};
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const EditIcon = styled(EditOutlined)`
  color: ${({ theme }) => theme.colors.text400};
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const DeleteIcon = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.error};
  cursor: pointer;
  width: 20px;
  height: 20px;
`;
