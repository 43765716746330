import { List, type ListProps } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { TransactionIngestItem } from '@entities';

type TransactionIngest = Schemas.TransactionIngest;

type TransactionIngestList = {
  selectedId?: string;
  dataSource: TransactionIngest[];
  onSelected: (details: TransactionIngest) => void;
} & ListProps<TransactionIngest>;

const TransactionIngestList: FC<TransactionIngestList> = ({
  selectedId,
  dataSource,
  loading,
  onSelected,
  ...rest
}) => (
  <List
    dataSource={dataSource}
    renderItem={(item) => (
      <TransactionIngestItem
        selectedId={selectedId}
        details={item}
        onSelected={onSelected}
      />
    )}
    locale={{
      emptyText: <div />,
    }}
    loading={loading}
    {...rest}
  />
);

export default TransactionIngestList;
