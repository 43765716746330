import { Button } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconCheck } from '@assets';

import * as S from './styled';

type TaxCardProps = {
  details: Schemas.Tax;
};

const TaxCard: FC<TaxCardProps> = ({ details }) => {
  const navigate = useNavigate();

  return (
    <S.Card>
      <S.Header status={details.paidAt ? 'paid' : 'payment'}>
        {t(`taxes.statuses.${details.paidAt ? 'paid' : 'payment'}`)()}
      </S.Header>

      <S.Body>
        <S.SubTitle>{details.details.reference}</S.SubTitle>
        <S.Amount level={4}>€{details.amount}</S.Amount>

        {details.paidAt ? (
          <S.Check>
            <IconCheck />
          </S.Check>
        ) : (
          <Button size="small" onClick={() => navigate(`/taxes/${details.id}`)}>
            {t('taxes.buttonReview')()}
          </Button>
        )}
      </S.Body>
    </S.Card>
  );
};

export default TaxCard;
