import { Alert, Button, Col, Flex, Form, Input, Row, Typography } from 'antd';
import { FC, useEffect } from 'react';
import { Trans } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import { OnboardingCardHeader } from '@entities';
import { useFormValidateFields, useOnboarding } from '@hooks';

import * as S from './styled';

const { Paragraph } = Typography;

type OnboardingDescribeProps = {
  onSubmit?: (values: Partial<Schemas.Incorporation>) => void;
};

const OnboardingDescribe: FC<OnboardingDescribeProps> = ({ onSubmit }) => {
  const { form, disabledForm } = useFormValidateFields();

  const { incorporationDetails, updateScreen, updateIncorporation } =
    useOnboarding();

  useEffect(() => {
    const companyActivity = incorporationDetails?.companyActivity;

    if (companyActivity) {
      form.setFieldValue('companyActivity', companyActivity);
    }
  }, [form, incorporationDetails?.companyActivity]);

  const handleSubmit = (values: Schemas.Incorporation) => {
    onSubmit?.(values);

    updateIncorporation(values);
    updateScreen('CREATE_ACCOUNT', { active: true });
  };

  return (
    <S.Container>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <S.Inner>
          <OnboardingCardHeader
            title={t('onboarding.companyActivity.title')()}
            description={
              <Row gutter={[16, 0]}>
                <Col sm={13}>
                  <Paragraph>
                    {incorporationDetails?.incorporationType ===
                    'accounting' ? (
                      <Trans
                        i18nKey={t(
                          'onboarding.companyActivity.description_accounting'
                        )()}
                        components={[
                          <a href="https://lbr.lu" target="_blank" />,
                          <Paragraph />,
                        ]}
                      />
                    ) : (
                      <Trans
                        i18nKey={t(
                          'onboarding.companyActivity.description_incorporation'
                        )()}
                        components={[<Paragraph />]}
                      />
                    )}
                  </Paragraph>
                </Col>

                <Col sm={11}>
                  <Alert
                    type="error"
                    description={
                      <Trans
                        i18nKey={translateUntyped(
                          `onboarding.companyActivity.alert_${incorporationDetails?.incorporationType}`
                        )()}
                        components={[
                          <Paragraph strong />,
                          <Paragraph />,
                          <a
                            href="https://easybiz.lu/restrictedactivities"
                            target="_blank"
                          />,
                        ]}
                      />
                    }
                  />
                </Col>
              </Row>
            }
          />

          <S.FormInner>
            <S.FormLabel>
              {t('onboarding.companyActivity.form.label')()}
            </S.FormLabel>

            <Form.Item
              name="companyActivity"
              rules={[
                {
                  required: true,
                  message: t('onboarding.companyActivity.form.error')(),
                },
              ]}
            >
              <Input.TextArea
                placeholder={t('onboarding.companyActivity.form.placeholder')()}
                rows={5}
                size="large"
              />
            </Form.Item>

            <S.ExtraText>
              {t('onboarding.companyActivity.form.extraText')()}
            </S.ExtraText>
          </S.FormInner>
        </S.Inner>

        <Flex justify="flex-end">
          <Button
            type="text"
            onClick={() => updateScreen('COMPANY_NAME', { active: true })}
          >
            {t('onboarding.buttonGoBack')()}
          </Button>

          <Form.Item shouldUpdate noStyle>
            {() => (
              <Button type="primary" htmlType="submit" disabled={disabledForm}>
                {t('onboarding.buttonNext')()}
              </Button>
            )}
          </Form.Item>
        </Flex>
      </Form>
    </S.Container>
  );
};

export default OnboardingDescribe;
