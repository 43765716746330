import { Flex, Typography } from 'antd';

import { IconCircledCheck } from '@assets';
import styled from '@emotion/styled';

const {
  Paragraph: BaseParagraph,
  Text: BaseText,
} = Typography;

export const Wrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 10px;
  padding: 32px;
  max-width: 1000px;
`;

export const Features = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.darkgrey};
  border-radius: 10px;
  padding: 10px 15px;
  align-self: baseline;
`;

export const FeatureIcon = styled(IconCircledCheck)`
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.grey400};
`;

export const Description = styled(BaseParagraph)`
  && {
    margin-bottom: 8px;
    max-width: 505px;
  }
`;

export const Instructions = styled.div`
  display: grid;
  grid-template-columns: 240px 60px 240px 60px 240px;
  column-gap: 25px;
  border-top: 1px solid ${({ theme }) => theme.colors.darkgrey};
  padding-top: 28px;
`;

export const Instruction = styled(Flex)`
  width: 240px;
`;

export const InstructionText = styled(BaseText)`
  font-size: 14px;
  font-weight: 600;
`;
