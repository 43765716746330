import { Flex } from 'antd';

import styled from '@emotion/styled';

export const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  border-radius: 10px;
  padding: 10px 20px;
`;

export const Label = styled.div`
  font-size: 12px;
`;
