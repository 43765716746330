import { Upload, type UploadProps, message } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC, PropsWithChildren } from 'react';

import { useDocumentController_create } from '@api-client/generated/DocumentController/create';
import { Schemas } from '@api-client/generated/types';
import {
  PAYMENT_DOCUMENT_VALID_EXTENSIONS,
  PaymentDocumentUnit,
} from '@constants';
import { useAccount } from '@hooks';

import * as S from './styled';

type PaymentDocumentImportInvoiceProps = {
  documentType: PaymentDocumentUnit;
  onUpdate?: (file: Schemas.Document) => void;
} & UploadProps;

const PaymentDocumentImportInvoice: FC<
  PropsWithChildren<PaymentDocumentImportInvoiceProps>
> = ({ documentType, children, onUpdate, ...props }) => {
  const { companyId } = useAccount();

  const { mutate: uploadDocument } = useDocumentController_create();

  const handleBeforeUpload = (file: File) => {
    const extension = `.${file.name.split('.').pop()}`.toLowerCase();

    if (!PAYMENT_DOCUMENT_VALID_EXTENSIONS.includes(extension)) {
      const translateParams = {
        formats: PAYMENT_DOCUMENT_VALID_EXTENSIONS.join(', '),
        extension,
      };

      message.error(
        documentType === 'income'
          ? t('income.errorExtensionsUpload', translateParams)()
          : t('expenses.errorExtensionsUpload', translateParams)()
      );

      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const handleImportInvoice = (options: UploadRequestOption) => {
    const formData = new FormData();

    formData.append('file', options.file);
    if (typeof options.file === 'object' && 'name' in options.file) {
      formData.append('name', options.file.name);
    }
    formData.append(
      'type',
      documentType === 'income' ? 'income_document' : 'expence_document'
    );

    uploadDocument(
      {
        parameter: {
          companyId: companyId!,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        requestBody: formData as any,
      },
      {
        onSuccess: (response) => {
          onUpdate?.(response);
        },
      }
    );
  };

  return (
    <S.Upload
      accept={PAYMENT_DOCUMENT_VALID_EXTENSIONS.join(',')}
      beforeUpload={handleBeforeUpload}
      customRequest={handleImportInvoice}
      showUploadList={false}
      multiple
      {...props}
    >
      {children}
    </S.Upload>
  );
};

export default PaymentDocumentImportInvoice;
