import { Button, Typography } from 'antd';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { IconClock, IconSuccessCircle } from '@assets';
import { useAccount, useOnboarding } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';
import { colors } from '@theme';

import * as S from './styled';

type OnboardingCompanyTypeAccountingCardProps = {
  price?: number;
  planId: number;
};

const { Text } = Typography;

const OnboardingCompanyTypeAccountingCard: FC<
  OnboardingCompanyTypeAccountingCardProps
> = ({ price, planId }) => {
  const { updateScreen, updateIncorporation } = useOnboarding();
  const { companyId } = useAccount();

  const { mutate: update, isPending: isLoading } = useUpdateIncorporation();

  const listSoftware = t('onboarding.accountingService.listSoftware', {
    returnObjects: true,
  })();

  const listBookkeeping = t(`onboarding.accountingService.listBookkeeping`, {
    returnObjects: true,
  })();

  const listExpert = t(`onboarding.accountingService.listExpert`, {
    returnObjects: true,
  })();

  const listTax = t(`onboarding.accountingService.listTax`, {
    returnObjects: true,
  })();

  const handleContinue = () => {
    const body = {
      accountingPlanId: planId,
    };
    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: body,
      },
      {
        onSuccess: () => {
          updateScreen('SHAREHOLDERS', { active: true });
          updateIncorporation(body);
        },
      }
    );
  };

  return (
    <S.Card>
      <S.Inner>
        <S.Title level={3}>{t(`onboarding.accountingService.title`)()}</S.Title>

        <S.Description>
          {t(`onboarding.accountingService.description`)()}
        </S.Description>
      </S.Inner>

      <S.Price>
        <S.PriceTitle level={4}>
          {price
            ? t(`onboarding.accountingService.price`, {
                value: `€${price}`,
              })()
            : t(`onboarding.accountingService.customPrice`)()}
        </S.PriceTitle>

        <S.PriceSubtitle>
          {t(`onboarding.accountingService.billedMonthly`)()}
        </S.PriceSubtitle>

        <S.PriceDetails>
          <S.PriceDetailsTitle level={4}>
            {t(`onboarding.accountingService.subTitleSoftware`)()}
          </S.PriceDetailsTitle>

          {listSoftware.map((item, index) => (
            <S.PriceDetailsItem key={item}>
              {index < 4 ? (
                <IconSuccessCircle color={colors.bgDarkBlue} />
              ) : (
                <S.Soon>
                  <IconClock />
                  {t(`onboarding.accountingService.soon`)()}
                </S.Soon>
              )}
              {item}
            </S.PriceDetailsItem>
          ))}
        </S.PriceDetails>

        <S.PriceDetails>
          <S.PriceDetailsTitle level={4}>
            {t(`onboarding.accountingService.subTitleBookkeeping`)()}
          </S.PriceDetailsTitle>

          {listBookkeeping.map((item) => (
            <S.PriceDetailsItem key={item}>
              <IconSuccessCircle color={colors.bgDarkBlue} /> {item}
            </S.PriceDetailsItem>
          ))}
        </S.PriceDetails>

        <S.PriceDetails>
          <S.PriceDetailsTitle level={4}>
            {t(`onboarding.accountingService.subTitleExpert`)()}
          </S.PriceDetailsTitle>

          {listExpert.map((item) => (
            <S.PriceDetailsItem key={item}>
              <IconSuccessCircle color={colors.bgDarkBlue} /> {item}
            </S.PriceDetailsItem>
          ))}
        </S.PriceDetails>

        <S.PriceDetails>
          <S.PriceDetailsTitle level={4}>
            {t(`onboarding.accountingService.subTitleTax`)()}
          </S.PriceDetailsTitle>

          {listTax.map((item) => (
            <S.PriceDetailsItem key={item}>
              <IconSuccessCircle color={colors.bgDarkBlue} /> {item}
            </S.PriceDetailsItem>
          ))}
        </S.PriceDetails>

        <Button
          type="primary"
          onClick={handleContinue}
          loading={isLoading}
          block
        >
          {t('onboarding.buttonContinue')()}
        </Button>
      </S.Price>

      <S.Notes>
        <S.NotesTitle level={4}>
          {t('onboarding.accountingService.notes.title')()}
        </S.NotesTitle>

        <S.NotesList>
          <S.NotesItem>
            <Trans
              i18nKey={t('onboarding.accountingService.notes.consultation')()}
              components={[<Text />]}
              tOptions={{
                value: '€110-450',
              }}
            />
          </S.NotesItem>

          <S.NotesItem>
            <Trans
              i18nKey={t('onboarding.accountingService.notes.coSec')()}
              components={[<Text />]}
              tOptions={{
                value: '€200',
              }}
            />
          </S.NotesItem>

          <S.NotesItem>
            <Trans
              i18nKey={t('onboarding.accountingService.notes.shared')()}
              components={[<Text />]}
              tOptions={{
                value: '€650',
              }}
            />
          </S.NotesItem>

          <S.NotesItem>
            <Trans
              i18nKey={t('onboarding.accountingService.notes.director')()}
              components={[<Text />]}
              tOptions={{
                value: '€2500',
              }}
            />
          </S.NotesItem>

          <S.NotesItem>
            <Trans
              i18nKey={t('onboarding.accountingService.notes.payroll')()}
              components={[<Text />]}
              tOptions={{
                value: '€24',
              }}
            />
          </S.NotesItem>

          <S.NotesItem>
            <Trans
              i18nKey={t('onboarding.accountingService.notes.meeting')()}
              components={[<Text />]}
              tOptions={{
                value: '€750',
              }}
            />
          </S.NotesItem>
        </S.NotesList>
      </S.Notes>
    </S.Card>
  );
};

export default OnboardingCompanyTypeAccountingCard;
