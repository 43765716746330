import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import { FC, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import { Card } from '@components';
import { Language } from '@constants';
import { useAccount, useTranslate } from '@hooks';
import { useResendCofirmationEmail, useUpdateUserData } from '@hooks-api';

import * as S from './styled';

type PersonalInfoProps = {
  account: Schemas.User;
};

const PersonalInfo: FC<PersonalInfoProps> = ({ account }) => {
  const { translate } = useTranslate();

  const { setAccount } = useAccount();

  const { mutate: updateUserData, isPending: loading } = useUpdateUserData();
  const { mutate: resendCofirmationEmail } = useResendCofirmationEmail();
  const [emailError, setEmailError] = useState<string | null>();
  const [requestedConfirmationEmail, setRequestedConfirmationEmail] =
    useState(false);

  const onFinish = (value: Schemas.User) => {
    setEmailError(null);
    updateUserData(
      { requestBody: value },
      {
        onSuccess: (response) => {
          setAccount(response);
          message.success(translate('settings.personal.success'));
        },
        onError: (error) => {
          for (const message of error.response?.data?.message || []) {
            if (message.startsWith('email.')) {
              setEmailError(message);
            }
          }
        },
      }
    );
  };

  const resendConfirmationEmail = () => {
    resendCofirmationEmail(
      {},
      {
        onSuccess: () => {
          setRequestedConfirmationEmail(true);
        },
      }
    );
  };

  return (
    <Card title={translate('settings.personal.infoTitle')}>
      <Form
        layout="vertical"
        colon={false}
        onFinish={onFinish}
        initialValues={account}
      >
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label={translate('settings.personal.name.label')}
              rules={[
                {
                  required: true,
                  message: translate('settings.personal.name.error'),
                },
              ]}
            >
              <Input
                placeholder={translate('settings.personal.name.placeholder')}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="lastName"
              label={translate('settings.personal.surname.label')}
              rules={[
                {
                  required: true,
                  message: translate('settings.personal.surname.error'),
                },
              ]}
            >
              <Input
                placeholder={translate('settings.personal.surname.placeholder')}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="email"
              label={translate('settings.personal.email.label')}
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: translate('settings.personal.email.error'),
                },
              ]}
              validateStatus={emailError ? 'error' : ''}
              help={
                emailError && // @ts-expect-error-next-line
                translate(`settings.personal.${emailError}`)
              }
              extra={
                !account.confirmedEmailAt && (
                  <S.Info>
                    {translate('settings.personal.infoEmailConfirm')}

                    {!requestedConfirmationEmail && (
                      <S.ResendLink onClick={() => resendConfirmationEmail()}>
                        {translate('settings.personal.resendConfimationEmail')}
                      </S.ResendLink>
                    )}

                    {requestedConfirmationEmail && (
                      <S.SentEmail>
                        {translate('settings.personal.sentConfirmationEmail')}
                      </S.SentEmail>
                    )}
                  </S.Info>
                )
              }
            >
              <Input
                placeholder={translate('settings.personal.email.placeholder')}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="locale"
              label={translate('settings.personal.locale.label')}
            >
              <Select
                options={Object.keys(Language).map((key) => ({
                  // @ts-expect-error-next-line
                  label: translate(`settings.personal.locale.labels.${key}`),
                  value: Language[key as keyof typeof Language],
                }))}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item noStyle>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            size="small"
          >
            {translate('settings.personal.buttonSave')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default PersonalInfo;
