import { Col, Flex, Form, Input, Row, Typography, message } from 'antd';
import { FC, PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import { Select } from '@components';
import { KYCFiles } from '@entities';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';
import { countries } from '@utils/countries/countries';
import { filterOptionCountries } from '@utils/countries/filterOptionCountries';

type KYCAddressProofProps = {
  person: Schemas.Person;
  selectedType: string | null;
  selectedStepValue: string;
};

const { Paragraph, Title } = Typography;

const KYCAddressProof: FC<PropsWithChildren<KYCAddressProofProps>> = ({
  person,
  selectedType,
  selectedStepValue,
}) => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const { incorporationDetails, updateIncorporation } = useOnboarding();

  const [form] = Form.useForm();

  const { mutate: update } = useUpdateIncorporation();

  const onFinish = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      ...form.getFieldsValue(),
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>
          {translate('onboarding.kyc.natural.address_proof')}
        </Title>

        <Paragraph type="secondary">
          <Trans
            i18nKey={translate('onboarding.kyc.naturalDetails.address_proof')}
            components={[<br />, <strong />]}
          />
        </Paragraph>
      </div>

      <Form
        form={form}
        layout="vertical"
        colon={false}
        initialValues={person.kycData}
      >
        <Form.Item
          name="countryCode"
          label={translate('onboarding.kyc.naturalKycForm.countryCode.label')}
        >
          <Select
            placeholder={translate(
              'onboarding.kyc.naturalKycForm.countryCode.placeholder'
            )}
            options={countries}
            size="large"
            onChange={onFinish}
            showSearch
            virtual
            filterOption={filterOptionCountries}
          />
        </Form.Item>

        <Form.Item
          name="address"
          label={translate('onboarding.kyc.naturalKycForm.address.label')}
        >
          <Input
            size="large"
            onBlur={onFinish}
            placeholder={translate(
              'onboarding.kyc.naturalKycForm.address.placeholder'
            )}
          />
        </Form.Item>

        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item
              name="zip"
              label={translate('onboarding.kyc.naturalKycForm.zip.label')}
            >
              <Input
                size="large"
                placeholder={translate(
                  'onboarding.kyc.naturalKycForm.zip.placeholder'
                )}
                onBlur={onFinish}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="city"
              label={translate('onboarding.kyc.naturalKycForm.city.label')}
            >
              <Input
                size="large"
                placeholder={translate(
                  'onboarding.kyc.naturalKycForm.city.placeholder'
                )}
                onBlur={onFinish}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <KYCFiles
        person={person}
        selectedType={selectedType}
        selectedStepValue={selectedStepValue}
      />
    </Flex>
  );
};

export default KYCAddressProof;
